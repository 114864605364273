.title_container {
  display: flex;
  justify-content: space-between;

  .close_button_wrapper {
    display: flex;
    align-items: flex-start;
    height: 14px;
    cursor: pointer;
  }
}

.content_container {
  text-align: center;
  padding: 0 60px;
  .greeting {
    color: #102f3f;
    font-size: 24px;
    font-weight: bold;
  }

  .description {
    font-size: 16px;
    color: #102f3f;
    padding: 30px 0 0 0;
  }

  .description_bold {
    font-weight: bold;
  }
}

.actions_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .button_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .action_button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 176px;
      height: 32px;
    }

    .start_tour_button {
      margin-left: 10px;
    }
  }

  .steps_count {
    padding: 15px 0;
    font-size: 12px;
    color: #767676;
  }
}
