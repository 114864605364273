.enter {
  opacity: 0;
  transition: all 600ms ease-in-out;
  width: 100%;
}
.enterActive {
  opacity: 1;
  transition: all 600ms ease-in-out;
  animation-duration: 600ms;
  animation-name: slidein-damage;
  width: 100%;
}
.exit {
  opacity: 1;
  transition: all 600ms linear;
}
.exitActive {
  opacity: 0;
  transition: all 600ms linear;
  animation-duration: 600ms;
  animation-name: slideout-damage;
}

@keyframes slidein-damage {
  from {
    opacity: 0;
    width: 100%;
    height: 0px;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    height: 100%;
    width: 100%;
    transform: translateX(0px);
  }
}

@keyframes slideout-damage {
  from {
    opacity: 1;
    margin: 0%;
    height: 100%;
    width: 100%;
  }

  to {
    opacity: 0;
    z-index: 1231;
    margin-top: -100%;
    height: 300%;
    width: 100%;
  }
}
