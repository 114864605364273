.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 5px;
  word-break: break-word;

  .name {
    width: 30%;
    color: #5f6466;
    font-size: 14px;
    font-weight: 400;
  }

  .value {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    padding: 0 10px;
    width: 70%;
  }
}

@media (max-width: 910px) {
  .value {
    font-size: 14px !important;
  }
}

@media (max-width: 550px) {
  .container {
    align-items: flex-start;
    flex-direction: column;

    .name {
      width: 100%;
    }

    .value {
      width: 100%;
      font-size: 14px;
      padding: 0;
      margin-top: 8px;
    }
  }
}

.empty_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 5px;
  .empty_title {
    font-size: 18px;
    font-weight: 600;
    color: #335566;
  }
  .empty_description {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #335566;
    text-align: center;
  }
}
