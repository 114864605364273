.leasing_comparison_container {
  display: flex;

  .titles {
    width: 25%;
    .titles_container {
      margin: 5px;
    }
  }
  .leasing_offers {
    width: 75%;
    display: flex;
    overflow: auto;

    .leasing_offer {
      min-width: 33.33%;
      .leasing_offer_container {
        margin: 5px;
        .standard_cell {
          justify-content: center;
          .standard_text {
            color: #171819;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .section_title {
            text-align: center;
            color: #335566;
          }
        }
      }
    }
  }

  .best_offer {
    background: #FF9700;
    color: #fff;
    font-size: 14px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .standard_cell {
    display: flex;
    justify-content: center;
    height: 80px;
    padding: 10px;
    flex-direction: column;
  }
  .grey_background {
    background: #F0F1F2;
  }
  .active {
    background: #335566;
    .standard_text, .section_title {
      color: #fff !important;
      text-align: center;
    }
  }
  .inactive {
    background: #E6F7FF;
    .standard_text {
      color: #171819;
      text-align: center;
    }
    .section_title {
      color: #335566;
      text-align: center;
    }
  }
  .border_radius {
    border-radius: 5px 5px 0 0;
  }
  .active_border_radius {
    border: 2px solid #FF9700;
    border-bottom: none;
  }
  .active_border_left_right {
    border-left: 2px solid #FF9700;
    border-right: 2px solid #FF9700;
  }
  .active_border_left_right_bottom {
    border: 2px solid #FF9700;
    border-top: none;
    border-radius: 0 0 5px 5px;
  }
  .section_title {
    font-size: 17px;
    color: #171819;
    margin: 0;
    width: 100%;
    word-break: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .standard_text {
    font-size: 14px;
    color: #5F6466;
    width: 100%;
  }
  .leasingrate {
    font-size: 20px;
    color: #102F3F;
    font-weight: bold;
  }
  .large {
    display: none;
    @media (min-width: 1200px) {
      display: inline;
    }
  }
  .small {
    display: inline;
    @media (min-width: 1200px) {
      display: none;
    }
  }
}
.toggle {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  span {
    font-size: 12px;
    color: #335566;
  }
}
