.file_viewer_wrapper {
  position: relative;
  .file_viewer_header {
    background-color: #102f3f;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;

    span {
      font-size: 18px;
      color: #ffffff;
    }
    .close_icon {
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
    .download_container {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 60px;
      position: relative;
      min-width: 160px;
      .download_icon {
        width: 40px;
        height: 20px;
      }
    }
    .file_name_container {
      display: flex;
      align-items: center;
      span {
        margin: 0 50px 0 10px;
      }

      .file_name_with_icon {
        display: flex;
        align-items: center;
        margin-left: 10px;
        word-break: break-all;
      }
    }
  }
  .file_viewer_body {
    background-color: #606466;
    height: calc(100vh - 45px);
  }
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border: none;
    background: #464a4c;
    color: #fff;
    font-size: 30px;
    box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    opacity: 0.5;
    transition: all 0.3s;
    &:hover {
      opacity: 1;
    }
  }
  .left_button {
    position: absolute;
    top: 48%;
    left: 40px;

    svg {
      transform: rotate(180deg);
    }
  }
  .right_button {
    position: absolute;
    top: 48%;
    right: 40px;
  }
  .disabled {
    opacity: 0.5 !important;
    cursor: default;
  }
  .zoom_container {
    width: 100px;
    display: flex;
    position: absolute;
    justify-content: space-between;
    top: 100vh - 10vh;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 715px) {
  .file_viewer_header {
    flex-direction: column-reverse;
  }

  .download_wrapper {
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .file_name_container {
    flex-direction: row;
    justify-content: space-between;
    span {
      font-size: 16px !important;
      margin: 0 0 0 10px !important;
    }
  }

  .file_viewer_body {
    height: calc(100vh - 106px) !important;
  }
}

@media (max-width: 450px) {
  .file_name_container {
    flex-direction: column-reverse;
    align-items: flex-start !important;
  }
}
