.container {
  .price {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .footer {
    display: flex;
    align-items: self-end;
    width: 100%;

    .description {
      color: #abb0b2;
      font-size: 12px;
      display: flex;

      a {
        margin-top: 5px;
        span {
          color: var(--blue, #356);
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.28px;
          margin-right: 10px;
          cursor: pointer;
        }
      }
      margin-top: 5px;
      span {
        color: var(--blue, #356);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.28px;
        margin-right: 10px;
      }

      .year {
        margin-right: 5px;
      }
    }

    .percent_container {
      font-size: 12px;
      border-radius: 2px;
      display: flex;
      display: flex;
      padding: 5px;
      align-items: flex-start;
      gap: 4px;
      align-items: flex-start;
      gap: 4px;

      span {
        font-size: 14px;
        line-height: 80%;
        letter-spacing: 0.28px;
        font-size: 14px;
        line-height: 80%;
        letter-spacing: 0.28px;
        margin-right: 5px;
      }

      &_red {
        color: #bf0040;
        background-color: #ffe6e6;
      }

      &_green {
        color: #30bf30;
        background-color: #e6ffe6;
      }

      .arrow_icon {
        &_bottom {
          transform: rotate(90deg);
        }

        &_red {
          path {
            stroke: #bf0040;
          }
        }

        &_green {
          path {
            stroke: #30bf30;
          }
        }
      }
    }
  }
}

.container + .container {
  margin-top: 20px;
}

.inline_tooltip {
  align-self: end;
  height: 17px;
  margin-left: -5px;
  margin-right: 5px;
  span {
    color: #fff !important;
    font-size: 12px !important;
  }
}

.header_precent {
  display: flex;
  align-items: center;
  .percent_container {
    font-size: 12px;
    border-radius: 2px;
    display: flex;
    padding: 5px;
    align-items: flex-start;
    gap: 4px;
    margin-left: 10px;
    height: 22px;

    span {
      font-size: 14px;
      line-height: 80%;
      letter-spacing: 0.28px;
      margin-right: 5px;
    }

    &_red {
      color: #bf0040;
      background-color: #ffe6e6;
    }

    &_green {
      color: #30bf30;
      background-color: #e6ffe6;
    }

    .arrow_icon {
      &_bottom {
        transform: rotate(90deg);
      }

      &_red {
        path {
          stroke: #bf0040;
        }
      }

      &_green {
        path {
          stroke: #30bf30;
        }
      }
    }
  }
}
