.aufgeklappte_aufgabe {
  position: relative;
  background-color: #fff;
  padding-bottom: 20px;
  margin-bottom: 20px;
  z-index: 0;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.container {
  padding: 0 30px 10px 30px;
}

.img_container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e8f5fe;
}

.img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.list_container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
}

.list {
  display: flex;
  margin: 30px 0;

  @media screen and (max-width: 900px) {
    justify-content: space-between;
    margin: 15px 0;
  }
}

.item {
  align-items: center;
  vertical-align: middle;
  line-height: 20px;
  color: #171819;
  font-size: 13px;
  font-weight: 400;
  margin-left: 25px;

  &:first-child {
    margin-left: 0;
    max-width: none !important;
  }

  @media screen and (max-width: 900px) {
    margin-left: 0;
  }

  @media screen and (max-width: 550px) {
    max-width: none;
  }
}

.item_rows_wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.item_icon_wrapper {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 13px;
}

.item_icon_calendar_wrapper {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 11px;
}

.item_content_bottom {
  margin-top: 10px;
}

.item_content_status {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  border-radius: 20px;
  padding: 5px 10px;
}

.check_icon_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.item_content_description {
  color: #171819;
  font-size: 18px;
  font-weight: 600;
  max-width: 60%;
}

.item_subject {
  font-size: 18px;
  font-weight: 600;
  flex: 1;
  word-break: break-word;
  padding-right: 5px;
}

.item_status_wrapper {
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.item_menu_wrapper {
  margin-left: 25px;
  display: flex;
  justify-content: flex-end;
}

.description {
  color: #191817;
  font-size: 13px;
  line-height: 20px;
  max-width: 600px;
  word-wrap: break-word;
}

.phases_description {
  width: 100%;
}

.green_btn {
  color: #fff;
  font-size: 14px;
  border: 2px solid #335566;
  background-color: #335566;
  padding: 5px;
}

.white_btn {
  color: #335566;
  font-size: 14px;
  border: 2px solid #335566;
  background-color: transparent;
  padding: 5px;
}

.flex {
  display: flex;
  align-items: center;
}

.btn_container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 10px;
  margin-top: 10px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 33%;
  margin-left: 20px;
}

.upload_file {
  margin: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.input {
  color: #777777;
  font-size: 14px;
  padding: 10px 15px;
  border: 1px solid #777777;
  min-height: 43px;
  width: 100%;
}

.uploaded_file_input {
  color: #000;
  font-size: 14px;
  padding: 10px 15px;
  border: 1px solid #777777;
  min-height: 43px;
  width: 100%;
}

.active_input {
  font-size: 14px;
  padding: 10px 15px;
  border: 1px solid #777777;
  min-height: 43px;
  width: 100%;

  &::placeholder {
    color: #000;
  }
}

.prefixe_wrapper {
  background-color: #f5f5f5;
  border: 1px solid #777;
  border-right: none;
  min-width: 42px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input_wrapper {
  position: relative;
  display: flex;
  padding-right: 9px;
}

.erledigen_btn {
  display: flex;
  align-items: center;
  color: #fff;
  background-color: #30bf30;
  border-radius: 2px;
  border: none;
  padding: 6px 16px;
  margin-left: 12px;
}

.erledigen_btn_icon {
  margin-right: 8px;
}

.btn {
  display: flex;
  align-items: center;
  background-color: inherit;
  border: none;
}

.btn_link {
  color: #000 !important;
  background: transparent !important;
  border: none !important;
}

.alignCenter {
  display: flex;
  align-items: center;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.footer_btn_wrapper {
  display: flex;
  align-items: center;
}

.close_icon {
  width: 20px;
  height: 20px;
}

.close_icon_wrapper {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 2px;
  width: 30px;
  height: 30px;
  filter: drop-shadow(0 4px 20px rgba(16, 47, 63, 0.25));
}

.tooltip {
  margin-top: 10px;
}

.tooltip_description {
  bottom: -60px;
  right: 0;
}

.form_tooltip {
  justify-content: flex-end !important;
}

.form_tooltip_description {
  top: -83px;
  right: 0;
}

.button_link {
  font-size: 13px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  cursor: pointer;
  color: #335566;
  margin-left: 2px;
}

.tool_wrapper {
  display: flex;
  align-items: center;

  @media screen and (max-width: 550px) {
    margin: 10px 0;
    justify-content: space-between;
  }
}

.button_container {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  gap: 10px;
}

.title_item {
  display: flex;
  justify-content: space-between;
}

.mobile_data_container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 325px) {
    flex-direction: column;
    gap: 10px;
  }
}

.date_field {
  justify-content: flex-end;

  @media screen and (max-width: 325px) {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 550px) {
  .img,
  .img_container,
  .aufgeklappte_aufgabe {
    border-radius: 5px;
  }

  .img {
    height: 100px;
    border-radius: 0;
  }

  .container {
    padding: 15px;
  }
  .item_subject {
    font-size: 16px;
    margin-top: 2px;
  }
  .list {
    flex-direction: column;
    gap: 10px;

    .item {
      font-size: 12px;
      padding-left: 0;
      margin: 0;
    }
    .item_status_wrapper {
      align-items: flex-start;
      margin: 0;
      padding-left: 0;

      .item_content_status {
        font-size: 12px;
        padding: 3px 8px;

        svg {
          width: 19px;
          height: 20px;
        }
      }
    }
  }

  .footer {
    flex-direction: column;

    .description {
      margin-bottom: 10px;
    }
    .form {
      width: 100%;
      margin: 0;
    }
    .footer_btn_wrapper {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
    .erledigen_btn {
      margin-left: 0;
      margin-right: 10px;
    }
  }

  .button_container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}

.files_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  gap: 30px;
}

.delegating_comment {
  width: 100% !important;
}
