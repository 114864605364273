.dashboard_item,
.dashboard_item_full {
  border-radius: 2px;
  height: 352px;
  max-height: 352px;
  border-color: #fff;
  width: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  .header {
    width: 100%;
    .link {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      padding: 16px;
      width: 100%;
      margin-bottom: 5px;
      h6 {
        margin: 0;
        margin-left: 10px;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        color: #335566;
      }
    }
  }
  .data_block {
    width: 100%;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .data_item {
      margin-bottom: 16px;
      h6 {
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #335566;
        margin: 0;
      }
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #919699;
      }
    }
  }
  .icon_header {
    margin: 40px auto 30px;
    width: 85px;
    height: 74px;
  }
  .header_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    h6 {
      color: #335566;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      margin: 0 0 5px;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #5f6466;
    }
  }
  .expand_item {
    border-bottom: 1px solid #f5f5f5;
    background-color: #fff;

    .expand_item_header {
      display: flex;
      align-items: center;
      padding: 15px 26px;
      cursor: pointer;

      h6 {
        margin: 0;
        padding-left: 20px;
        font-size: 14px;
        line-height: 18px;
        color: #22273a;
      }
      .icon {
        transition: all 0.3s ease-out;
      }
    }
    .expand_item_body {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s;

      .name {
        font-size: 12px;
        line-height: 18px;
        color: #777777;
        margin: 0 25px;
      }
      .value {
        font-size: 14px;
        line-height: 18px;
        color: #22273a;
        margin: 0 25px 15px;
      }
      .choose_vehicle {
        .name {
          margin-bottom: 15px;
          cursor: pointer;
          text-align: right;

          a {
            color: inherit;
            text-decoration: none;
          }

          .icon {
            transform: rotate(270deg);
            margin-right: 10px;
            margin-bottom: 2px;
            width: 8px;
          }
        }
      }
    }
    &.open {
      .expand_item_body {
        max-height: 100vh;
        transition: max-height 1s;
      }
      .expand_item_header {
        .icon {
          transform: rotate(180deg);
        }
        h6 {
          color: #335566;
        }
      }
    }
  }
  .driver_buttons_container {
    padding: 10px 16px 20px;
    width: 100%;
    margin-top: auto;
    .new_config {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #335566;
      }
      img {
        width: 24px;
        height: 24px;
        margin-left: 5px;
      }
    }
  }
}

.dashboard_item_full {
  width: 308px;
}

.configure_img {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 16px 20px;
}

.configure_info {
  display: flex;
  justify-content: center;
  align-items: center;
  h6 {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #335566;
  }
}

@media (max-width: 768px) {
  .dashboard_item,
  .dashboard_item_full {
    width: 100%;
    flex-direction: column;
    min-width: auto;
    max-width: none;
    height: auto;
    margin-bottom: 150px;
    border-radius: 2px;
    margin-top: 20px;
  }
  .driver_buttons_container {
    border-radius: 2px;
  }
}

.tooltip_string {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
  max-width: 120px;
}

.spinner_block {
  width: 100%;
  height: 100%;
  min-height: 200px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
