.chatbot {
  .container {
    padding-bottom: 0 !important;
  }

  .image_container {
    background-color: #e0e3e5;
    display: flex;
    align-items: center;
    padding: 10px;
    img {
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }
    p {
      padding-left: 10px;
      color: #000;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .chatbot_container {
    height: calc(100% - 65px);
  }
  .chatbot_avatar_text {
    color: #102f3f;
    vertical-align: middle;
  }

  li {
    width: 100% !important;
    height: auto !important;
    padding: 0 !important;
    margin-top: 10px !important;
  }

  button[role='menuitem'] {
    color: #335566 !important;
    background-color: #fff !important;
    border: 1px solid #335566 !important;
    border-radius: 2px !important;
  }
}
