.leasing_wrapper {
  display: flex;
  gap: 0 30px;
  padding: 0 10px;

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }

  .leasing_container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .leasing_item {
      background: #ffffff;
      box-shadow: 0px 1px 2.5px 0px rgba(51, 86, 102, 0.07), 0px 3px 9px 0px rgba(51, 86, 102, 0.07),
        0px 24px 40px 0px rgba(51, 86, 102, 0.07);
      border-radius: 3px;
      padding: 15px 10px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      min-height: 80px;
      position: relative;

      @media screen and (max-width: 550px) {
        min-height: 73px;
      }

      .title {
        width: calc(100% - 155px);
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 2;
        span {
          margin-top: 10px;
          line-height: 20px;
          font-size: 12px;
          color: #777777;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      .price {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: right;
        flex-grow: 1;

        @media screen and (max-width: 550px) {
          width: 35%;
        }

        h6 {
          font-weight: normal;
          font-size: 14px;
          color: #000000;
        }
        span {
          margin-top: 10px;
          font-size: 18px;
          color: #335566;
          line-height: 20px;
          font-weight: bold;
          word-break: break-all;
        }
      }

      h5,
      h6 {
        margin: 0;
      }

      .edit_btn {
        display: none;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        background-color: #fff;
        box-shadow: 0px 2.261px 5.32px 0px rgba(95, 100, 102, 0.17), 0px 7.594px 17.869px 0px rgba(171, 176, 178, 0.17);
        border: none;
        position: absolute;
        right: calc(50% - 15px);
        top: calc(50% - 15px);
        margin: 0;
        padding: 0;
      }

      .edit_btn_right_position {
        right: 20px;
      }

      .cancel {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }

      .buttons_container {
        display: flex;
        justify-content: flex-end;
        button:first-child {
          margin-right: 10px;
        }
        button {
          @media (max-width: 550px) {
            width: 50%;
          }
        }
      }

      .form_container {
        display: flex;
        align-items: center;
        flex-basis: 50%;

        @media (max-width: 550px) {
          flex-basis: 200%;
        }

        p {
          margin-bottom: 5px;
        }
      }

      .betrag_form_right {
        display: flex;
        width: 50%;
        padding: 5px 0;
        align-items: center;

        p {
          margin-top: 15px;
        }
      }

      .betrag_form_item {
        display: flex;
        width: 50%;
        align-items: center;

        &:last-child {
          margin-left: 10px;
        }
      }

      .betrag_form {
        display: flex;
        justify-content: space-between;
      }

      &:hover {
        background: rgba(121, 125, 127, 0.1);
        .edit_btn {
          display: flex;
        }
      }
    }
    .no_background {
      background-color: transparent !important;
    }
  }
}

.content_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h3 {
    margin: 30px 0 10px;
    font-size: 18px;
    color: #335566;
  }
  p {
    max-width: 400px;
    font-size: 14px;
    color: #22273a;
    text-align: center;
  }
}

.info_icon {
  margin-left: 5px;
}
