.container {
  width: 100%;
  background-color: #fff;

  &_open_item {
    background-color: #e5f7ff;
  }

  & + .container {
    margin-top: 20px;
  }

  .main_block {
    padding: 30px;

    .header {
      display: flex;
      justify-content: space-between;
      color: #102f3f;
      font-size: 18px;
      font-weight: 600;

      .field {
        display: inline-block;

        & + .field {
          margin-left: 50px;
        }

        .field_name_claim_number {
          display: none;
        }

        &_name {
          color: #335566;
          font-size: 12px;
          font-weight: 400;
          margin-top: 5px;
        }
      }
    }
    .footer {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      color: #335566;
      margin-top: 40px;

      .step_description {
        font-size: 18px;
        font-weight: 600;
        line-height: 1;

        &_name {
          color: #8fafbf;
          font-size: 12px;
          font-weight: 400;
          margin-top: 5px;
        }

        .inactive_value {
          opacity: 0.5;
        }
      }

      .toggle_item_button {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 14px;
        cursor: pointer;

        &_icon {
          transform: rotate(90deg);
          height: 14px;
          margin-left: 8px;

          path {
            stroke: #335566;
          }
          &_active {
            transform: rotate(-90deg);
          }
        }
      }
    }
  }

  .hide_block {
    padding: 30px;
    background-color: #fff;

    .info_list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 40px;

      .info_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        text-decoration: none !important;
      }
    }

    .button_container {
      display: flex;
      justify-content: flex-end;

      .button {
        display: inline-block;
        color: #fff;
        background: #335566;
        padding: 6px 16px;
        margin-top: 20px;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.underline {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #ccc;
  margin: 3px 0;
}

.hide_for_mobile {
  display: block !important;
}

.hide_for_desktop {
  display: none !important;
}

@media (max-width: 550px) {
  .container {
    background-color: #fff;
    border-radius: 5px;

    .main_block {
      padding: 20px;
      background-color: #102f3f;
      border-radius: 5px;

      .header {
        color: #fff;

        .field {
          .field_name {
            display: none;
          }

          .field_value_claim_number {
            font-size: 14px;
            font-weight: 400;
            text-align: right;
          }

          .field_name_claim_number {
            display: block;
            color: #8fafbf;
          }

          .cars_licence_plate {
            display: block;
            font-size: 14px;
            font-weight: 400;
            margin-top: 8px;
          }
        }
      }

      .footer {
        flex-direction: column;
        align-items: flex-start;

        .toggle_item_button {
          &_icon {
            path {
              stroke: #fff;
            }
          }
        }

        .step_description {
          color: #fff;
          font-size: 14px;

          .inactive_value {
            opacity: 1;
          }
        }

        .toggle_item_button {
          color: #fff;
          margin-left: auto;
        }
      }
    }
    .hide_block {
      padding: 0 18px 18px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;

      .button_container {
        display: none;
      }

      .info_list {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        grid-column-gap: 40px;

        .info_item {
          display: block;
          height: 100%;

          &:nth-child(3) {
            grid-row-start: 4;
          }

          &:nth-child(4) {
            grid-row-start: 3;
          }

          &_content {
            display: flex;
            justify-content: space-between;
          }

          .info_item_content {
            display: flex;
            align-items: center;

            &:hover {
              background-color: #e5f7ff;
              border-radius: 5px;
            }
          }

          .icon_container {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 28px;
            height: 28px;
          }
        }
      }
    }
  }

  .hide_for_mobile {
    display: none !important;
  }

  .hide_for_desktop {
    display: block !important;
  }

  .underline {
    position: static;
    margin: 3px 0;
  }
}
