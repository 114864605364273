.enter {
  opacity: 0;
  transition: all 500ms ease-in-out;
}
.enterActive {
  opacity: 1;
  transition: all 500ms ease-in-out;
  animation-duration: 500ms;
  animation-name: slidein-backward;
}

@keyframes slidein-backward {
  from {
    height: 100%;
    max-height: 62px;
    width: 100%;
    transform: translateY(-50%);
  }

  to {
    height: 100%;
    max-height: 62px;
    width: 100%;
    transform: translateY(0px);
  }
}

@keyframes slidein-backward-mobile {
  from {
    height: 100%;
    max-width: 470px;
    width: 100%;
    transform: translateY(-50%);
  }

  to {
    height: 100%;
    max-width: 470px;
    width: 100%;
    transform: translateY(0px);
  }
}

@media (max-width: 900px) {
  .enterActive {
    animation-name: slidein-backward-mobile;
  }
}
