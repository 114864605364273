@import '../../assets/variables';

.car-rating-container {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  float: left;
  width: 100%;
  @media (min-width: 768px) {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow: hidden;
  }
}

.angebot_button_small {
  text-transform: none;
  background-color: #335566;
  float: right;
  font-size: 14px;
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
  margin-top: 15px;
  font-weight: normal;
  &:hover {
    background-color: #335566;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    display: none;
  }
  @media (min-width: 992px) {
    display: none;
  }
}

.product-pricetag.hidden-xs {
  @media (max-width: 991px) {
    left: 65px !important;
    // border-top-right-radius: 5px;
    // text-align: right;

    .angebot_button {
      padding: 10px;
      margin-top: 5px;
      display: block;
    }
  }

  .angebot_button_small {
    display: block !important;
  }

  .product-price {
    text-align: right;
    margin-top: 15px;
  }

  .alles-inklusive {
    margin-top: 5px;

    @media (max-width: 767px) {
      display: none;
    }
  }
}
.alles-inklusive.hidden-lg {
  .alles-inklusive-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: auto;

    .alles-inklusive-close-wrapper {
      padding: 10px;

      .alles-inklusive-close-btn {
        float: right;
        width: 15px;
        height: 15px;
      }
    }
  }

  @media (min-width: 768px) {
    display: none;
  }
}

.angebot_button {
  text-transform: none;
  margin-top: 10px;
  background-color: #335566;
  border-radius: 5px !important;
  padding: 10px;
  width: 225px;
  font-weight: normal;
  transition: box-shadow 0.3s;
  // display: none;

  @media (min-width: 992px) {
    display: block;
    font-size: 14px;
  }

  &:hover {
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.16);
  }
}
.product-price-netto {
  text-align: right;
}
.alles-inklusive {
  color: #999;
  font-weight: 600;
  font-size: 12px;
  margin-top: 10px;
  position: relative;
  display: flex;
  justify-content: flex-end;

  h4 {
    color: #ffab03;
    text-align: center;
    margin: 5px 0 0 0;
  }

  .alles-inklusive-container {
    position: absolute;
    left: 200px;
    top: -40px;
    width: 485px;
    // display: flex;
    box-shadow: 0 0 12px 0 #999;
    background-color: #fff;
    z-index: 1000 !important;

    ul {
      padding: 5px;
    }

    .item {
      padding: 0;
      margin: 0 0 10px;
      list-style: none;

      .sum {
        color: $green;
        font-size: 19px;
        span {
          display: block;
          margin-left: auto;
        }
      }

      li {
        margin: 0;
        padding: 10px 0 5px;
        display: flex;
        justify-content: space-between;

        .label {
          color: #999;
          padding-left: 10px;
          display: flex;
          align-items: center;
          font-size: 12px;
          text-align: left;
          word-break: break-word;

          img {
            //display: block;
            display: inline-block;
            height: 20px;
            margin-right: 10px;
          }
        }

        .label_main {
          font-weight: bold;
          color: #497e8a;

          img {
            //display: block;
            display: inline-block;
            height: 20px;
            margin-right: 10px;
          }
        }
        &:last-child {
          padding-top: 5px;
          font-weight: bold;
          border: none;
        }
      }
    }
    .alles-inklusive-close-wrapper {
      display: none;
    }
  }
  span {
    font-weight: normal;
    color: #373b4d;
  }
}

@media screen and (min-width: 991px) {
  .alles-inklusive {
    .alles-inklusive-container {
      left: -500px;
      top: -220px;
    }
  }
}
@media screen and (max-width: 480px) {
  .alles-inklusive {
    .alles-inklusive-container {
      .alles-inklusive-items-wrapper {
        flex-direction: column;
        .item {
          width: 100%;
          margin: 0;

          li {
            &:last-child {
              padding-top: 5px;
            }
          }
        }
      }

      .alles-inklusive-close-wrapper {
        display: block;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .alles-inklusive {
    position: absolute;
    top: 75px;
    // right: 15px;
    // width: 45%;
    // text-align: right;

    .alles-inklusive-container {
      left: -500px;
      top: -100px;

      .item {
        width: 50%;
      }

      .alles-inklusive-close-wrapper {
        display: block;
      }
    }
  }
}

.product-smalltext {
  width: 100%;

  @media (min-width: 992px) {
    width: calc(100% - 190px);
  }
}
