.container {
  min-width: 700px;
  padding: 0 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #abafb3;
  padding: 20px;
  span {
    flex: 1;
    padding: 0 3px;
    word-break: break-word;
  }
}

.page_container {
  background-color: #f5f5f5;
  height: auto;
  min-height: calc(100% - 36px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  .wrapper {
    display: flex;
    justify-content: center;
    width: 100%;

    .table_container {
      width: 100%;
      max-width: 1600px;
      min-width: 800px;

      .filters {
        margin-top: 3px;
        display: flex;
        justify-content: space-between;

        .inner_wrapper {
          margin-bottom: 8px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.no_items {
  padding-top: 25px;
  h5 {
    text-align: center;
    margin-bottom: 10px;
    color: #335566;
    font-size: 16px;
  }
  p {
    text-align: center;
    color: #22273a;
    font-size: 14px;
  }
}

.indicator {
  &_inactive {
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #bf0040;
  }
  &_active {
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #30bf30;
  }
}

.question_button {
  justify-content: flex-end;
}

.active {
  &::before {
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-right: 5px;
    border-radius: 50%;
    background-color: #30bf30;
  }
}

.inactive {
  &::before {
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-right: 5px;
    border-radius: 50%;
    background-color: #919699;
  }
}
