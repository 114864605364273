.widget_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.canvas_block {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  canvas {
    width: 100%;
    height: 100%;
    max-width: 170px;
    max-height: 170px;
  }
  .text_block {
    display: flex;
    flex-direction: column;
    width: auto;
    max-width: 125px;
    align-self: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: -120px;
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #ffffff;
    }
    span {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      color: #ffbf00;
    }
  }
}

.driver_block {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #ffffff;
  align-items: center;
  span {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    margin-left: auto;
  }
}

.empty_data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 20px;
  span {
    margin-top: 20px;
    width: 60%;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.28px;
  }
}
