.chips {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 2px 10px;
  border: 1px solid #777;
  min-height: 41px;
  input {
    border: none;
    color: #000;
    padding: 5px;
    font-size: 14px;
  }
  .chip {
    padding: 5px 0 5px 5px;
    background-color: #335566;
    color: #fff;
    display: flex;
    margin: 3px;
    .delete_chip {
      width: 22px;
      height: 21px;
      display: block;
      cursor: pointer;
      background-image: url('/assets/images/alert_close.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
