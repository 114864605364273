$red: #ff4c4a;
$white: #ffffff;
//
//.contact_section{
//  //background: linear-gradient(to bottom right, #006997, #008bb1);
//  // color: #fff;
//  background:$white;
//  padding: 20px;
//  font-size: 1.143em;
//  display: block;
//  font-family: 'Roboto_Regular', sans-serif;
//
//
//  .inputs{
//    display: flex;
//    flex-wrap: wrap;
//    justify-content: space-between;
//    max-width: 100%;
//  }
//
//  input[type="text"],
//  input[type="email"],
//  input[type="tel"] {
//    background-color: #fff;
//    padding: 15px;
//    flex-basis: 100%;
//    margin-bottom: 10px;
//    border: 1px solid rgba(0, 0, 0, 0.75);
//    color: black;
//    border-radius:3px !important ;
//
//    @media (min-width: 550px){
//      flex-basis: 49%;
//    }
//  }
//  textarea {
//    flex-basis: 100%;
//    background-color: #fff;
//    padding: 15px;
//    margin-bottom: 10px;
//    height: 110px;
//    color: black;
//    border: 1px solid rgba(0, 0, 0, 0.75);
//    border-radius:3px !important ;
//  }
//
//}
//
//.contact_btn{
//  padding:10px 12px;
//  font-size: 18px;
//  font-weight: 600;
//  margin-top:10px;
//  background-color:$red;
//  &:hover{
//    background-color:$red;
//  }
//}

.message {
  height: 120px;
  width: 100%;
  overflow: hidden;
}

.has_error {
  border: none !important;
  -webkit-appearance: none;
  box-shadow: 0 0 4px 3px $red;
  -webkit-box-shadow: 0 0 4px 3px $red;
  -moz-box-shadow: 0 0 4px 3px $red;
  color: $red !important;

  &::placeholder {
    color: $red !important;
  }
}

.close_icon {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  height: 21px;
  width: 22px;
}

.body {
  position: relative;
  opacity: 1;
  margin: 20px 10px 20px 10px;
  max-width: 1169px;
  background: #eff0f5;
  border-radius: 10px;
  font-family: "Open Sans", sans-serif;
}

.form_header {
  font-weight: bold;
  font-size: 16px;
  line-height: 34px;
  padding-top: 9px;
  margin: 0;
  text-align: center;
  letter-spacing: -0.02em;

  color: #22273A;
}

.form_wrap {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
}

.main_info {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.link{
  font-weight: bold;
}

.btn {
  background: #ff4c4a;
  border-radius: 5px;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
  border: none;
  margin: 20px auto 30px auto;
  display: block;

  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  cursor: pointer;
}

.btn:disabled {
  opacity: 0.7;
  cursor: no-drop;
}

.form_group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form_group > label {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 5px;
  color: #2e3e50;
  display: inline;
}

.form_group > textarea {
  max-height: 120px;
}

.form_control {
  background: #ffffff;
  border: 1px solid #dee0e1;
  border-radius: 4px;
  padding: 10px 15px;
  width: 100%;
}

.form_control::placeholder {
  font-size: 14px;
  line-height: 150%;
  color: #2e3e50;
  opacity: 0.5;
}

.data {
  max-width: 360px;
  width: 100%;
}

.avatar {
  display: block;
  border-radius: 50%;
  width: 46px;
  height: 46px;
}

.contact {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.data {
  display: none;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  color: #22273a;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;

  p {
    font-size: 16px;
    margin-bottom: 5px;
  }
}

.info > .phone {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #818589;
  text-decoration: none;
}

.info > .name {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 10px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #22273a;
}

.info > .phone > img {
  width: 14px;
  height: 14px;
  display: inline-block;
  margin-right: 5px;
}

.divider {
  width: 100%;
  border-bottom: 1px solid #dee0e1;
}

.right_column {
  margin-top: 30px;
}

.data_protection {
  font-size: 14px;
  line-height: 24px;
  color: #22273a;
}

.data_protection > .more {
  cursor: pointer;
  color: #F9A703;
  font-weight: 600;
  font-size: 16px;
}

.data_protection > .text {
  height: 48px;
  overflow: hidden;
}

.data_protection.open > .more {
  display: none;
}

.data_protection.open > .text {
  height: auto;
}

.thanks_header{
  text-align: center;
}
.checkbox_label {
  font-size: 15px;
}

@media (min-width: 768px) {
  .avatar {
    width: 97px;
    height: 97px;
  }

  .form > .main_info {
    flex-direction: row;
  }

  .contact > .info {
    padding-left: 41px;
  }

  .contact > .info > .name {
    font-size: 22px;
    line-height: 36px;
  }



  .contact > .info > .phone {
    font-size: 18px;
    line-height: 36px;
  }

  .contact > .info > .phone > img {
    height: 17px;
    width: 20px;
  }
  .form_group {
    width: 50%;
  }

  .form_group:first-child {
    padding-right: 15px;
  }

  .form_group:last-child {
    padding-left: 15px;
  }

  .btn {
    width: 400px;
  }
}

@media (min-width: 992px) {
  .thanks_header{
    text-align: left;
  }
  .body {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .close_icon {
    right: 25px;
    top: 25px;
  }

  .form_header {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.02em;
    padding-top: 21px;
  }

  .form_wrap {
    padding: 22px 17px;
    display: flex;
    flex-direction: column;
  }
  .right_column {
    margin-top: 0;
  }

  .form {
    padding: 41px 40px 30px 40px;
    background: #eff0f5;

    /* 1 */
    border-radius: 10px;
  }

  .divider {
    display: none;
  }

  .form_wrap {
    flex-direction: row;
  }

  .data {
    display: block;
  }

  .left_column {
    width: 44%;
    padding-left: 25px;
  }

  .right_column {
    width: 56%;
  }

  .data_protection {
    display: none;
  }

  .btn {
    margin: 40px auto 30px auto;
  }
}

@media (min-width: 1200px) {
  .left_column {
    width: 44%;
    padding-left: 60px;
  }

  .form_header {
    padding-left: 25px;
  }
}
