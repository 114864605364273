.dashboard {
  background-color: #f5f5f5;
  padding: 30px 0;
  height: auto;
  min-height: 100%;

  .panel {
    display: flex;
    flex-direction: column;
    align-items: start;
    background-color: #f5f5f5;

    .setup_assistant_container {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      .initial_setup_assistant {
        background: #fff;
        box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
        padding: 30px;
        margin-bottom: 30px;
        display: flex;
        width: 100%;
        max-width: 270px;
        flex-direction: column;
        align-items: center;

        h3 {
          font-size: 20px;
          line-height: 27px;
          text-align: center;
          color: #335566;
          margin-top: 30px;
        }
        h5 {
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          letter-spacing: 0.02em;
          color: #5F6466;
          margin: 20px 0 30px;
        }
      }
      @media (min-width: 992px) {
        flex-direction: row;
        .setup_assistant_container {
          .initial_setup_assistant {
            order: 0;
          }
        }
      }
    }
  }
}

// @media (min-width: 400px) {
//   .dashboard {
//     .panel {
//       padding: 40px;
//     }
//   }
// }

@media (min-width: 600px) {
  .dashboard {
    .panel {
      flex-direction: row;
      .setup_assistant_container {
        order: 1;
        margin-left: 30px;
      }
    }
  }
}
