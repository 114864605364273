.table_img,
.table_text {
  margin-right: 25px;
  word-break: break-word;
  line-height: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.table_img {
  position: relative;
  overflow: visible;
  div {
    height: 60px;
    min-width: 20px;
    background-position: center center;
    background-size: cover;
  }
}
.table_text {
  color: #22273a;
  text-decoration: none;
  margin-top: auto;
  margin-bottom: auto;
  .extended_text {
    position: fixed;
    padding: 5px 10px;
    max-width: 250px;
    font-size: 12px;
    color: #fff;
    background-color: #777777;
    border-radius: 3px;
    z-index: 1000;
    line-height: 18px;
    white-space: normal;
  }
}
.table_menu,
.table_button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  button {
    width: 100%;
  }
}

.table_button {
  margin-right: 5px;
}

.table_chip {
  margin-right: 5px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  .chip {
    background-color: #30bf30;
    border-radius: 20px;
    padding: 5px 10px;
    color: #fff;
    font-style: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.show_img {
  display: flex;
  img {
    margin-right: 5px;
  }
}

.cell:last-child {
  margin-right: 0 !important;
}
