.container {
  & + .container {
    margin-top: 10px;
  }
  background-color: #fff;
  border-radius: 5px;
  padding: 23px 16px 12px !important;
  width: 100%;
  margin-bottom: 0;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #102f3f;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
  }
  .main {
    margin-top: 30px;
    margin-bottom: 20px;
    .title {
      color: #000;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    .status {
      color: #30bf30;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .footer {
    display: flex;
    .column {
      flex: 1;
      margin-right: 5px;
      .column_value {
        color: #000;
        font-size: 14px;
        font-weight: 400;
      }
      .column_name {
        color: #8fafbf;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}
