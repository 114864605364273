.download_exel_container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.chevron_container {
  cursor: pointer;
}

.options_container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;

  .option {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 15px;
    gap: 15px;

    &:hover {
      background-color: #e5f7ff;
    }
  }
}
