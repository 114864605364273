.span{
  width: 100%;
  // margin-left:2px;
  // margin-left: 2px;
  margin-right: 0;
  flex-basis: 48%;
  justify-content: space-evenly;

  @media (min-width: 992px) {
    flex-wrap: nowrap;
    margin-right: 2px;

  }

  &:last-child{
    // margin-right: 0;
    @media (min-width: 992px) {
      margin-right: 0;
    }
  }
}

.icon{

  &.active{
    box-shadow: #ff4c4a 1px 1px 3px 1px;
    border-color: transparent;;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3px;
  border-color: rgba(0, 0, 0, 0.75);
  color:rgba(0, 0, 0, 0.75);

  i{
    width: 60px;
    font-size: 20px;
    color: #555;
  }
}
