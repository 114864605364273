$grey-color: #5f6466;

.autocomplete {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #777;
  border-radius: 3px;
  min-height: 43px;
  input {
    border: none;
    border-radius: 3px;
    color: #000;
    padding: 10px 30px 10px 15px;
    width: 100%;
    height: 100%;
    font-size: 14px;
    &:focus ~ .autocomplete_list {
      display: block;
    }
  }

  .delete {
    position: absolute;
    right: 5px;
    top: calc(50% - 10px);
    width: 22px;
    height: 21px;
    border-radius: 50%;
    display: block;
    background-color: #eaeaea;
    cursor: pointer;
    background-image: url('/assets/images/alert_close.svg');
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      background-color: #f1f1f1;
    }
  }
  .loader_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    position: relative;
    margin-left: 5px;
  }
  .autocomplete_list {
    display: none;
    background-color: #fff;
    position: absolute;
    z-index: 1001;
    top: 100%;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5);
    left: 0;
    width: 100%;
    .description {
      color: #777c7f;
      font-size: 10px;
      margin-top: 2px;
    }
    &:active {
      display: block;
    }
    .create_new {
      padding: 7px;
      border-top: 1px solid #777;
      color: #000;
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        margin-right: 5px;
        opacity: 0.7;
        width: 20px;
      }
    }
    ul {
      max-height: 250px;
      overflow: auto;
      li {
        padding: 7px;
        color: #000;
        cursor: pointer;
        word-wrap: break-word;
        &.disable {
          opacity: 0.3;
          cursor: default;
        }
        &.active {
          background-color: #f1f1f1;
        }
      }
      .load_more_btn {
        display: block;
        padding: 7px;
        color: #000;
        cursor: pointer;
        z-index: 1001;

        &:hover {
          background-color: #f1f1f1;
        }
      }
    }
  }
}
.error {
  border: 1px solid #bf0040 !important;
}
.disabled {
  background-color: #f0f1f2;
  input {
    color: $grey-color;
  }
}

.warning {
  border: 1px solid #ff9700 !important;
}

.list_item {
  padding: 10px 5px;
  word-wrap: break-word;
  cursor: pointer;
  .list_label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #102f3f;
  }
  .list_value {
    margin-top: 3px;
    color: $grey-color;
  }
}

.list_item_with_status{
  display:flex;
  justify-content:flex-start;
  align-items:center;
  gap:5px;
}

.modal_title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #22273a;
}

@media (max-width: 600px) {
  .autocomplete {
    input {
      font-size: 16px;
    }
  }
}
