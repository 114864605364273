.good_quality{
    color: #2E3133;
}

.bad_quality{
    color: #BF0040;
}

.averege_quality{
    color: #FF9700;
}