.container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;

  .canvas_block {
    width: 45px;
    height: 100%;
    max-height: 260px;
    canvas {
      height: 100% !important;
      max-height: 260px;
    }
  }

  .data_block {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &_item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      cursor: pointer;

      &_count {
        display: flex;
        svg {
          align-self: center;
        }
        span {
          color: var(--blue, #356);
          font-size: 24px;
          font-weight: 600;
          line-height: normal;
          margin-left: 10px;
        }
      }

      p {
        color: var(--blue, #356);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.28px;
      }
    }
  }

  .empty_data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    span {
      text-align: center;
      margin-top: 10px;
    }
  }
}
