.buttons_menu{
    position:fixed;
    right: 50px;
    bottom: 55px;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 108px;

    .button_view{
        display:flex;
        justify-content: center;
        align-items: center;
        height:44px;
        width:44px;
        background-color: #335566;
        border-radius: 25px;
        outline:none;
        border: none;
        transition: box-shadow 0.2s ease-in-out;

        &:hover,
        &:active{
            box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.16);
        }
    }
}