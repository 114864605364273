$red: #ff4c4a;
$white: #ffffff;

.close_icon {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  height: 21px;
  width: 22px;
}

.body {
  position: relative;
  opacity: 1;
  margin: 20px 10px 20px 10px;
  max-width: 1300px;
  background: #ffffff;
  border-radius: 10px;
  font-family: 'Open Sans', sans-serif;
  padding-top: 10px;
  padding-bottom: 40px;

  .title {
    text-align: center;
    margin-bottom: 15px;
  }
  .cars_container {
    display: flex;
    width: 200%;
    position: relative;
    transition: all 0.5s ease;
  }
  .header {
    position: sticky;
    top: 0;
    overflow: hidden;
    background-color: #f7f7f7;
    padding-top: 10px;
    padding-bottom: 15px;
    z-index: 1;
    box-shadow: 0 2px 3px -2px rgba(0, 0, 0, 0.75);

    .arrows {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 0;
      width: 100%;

      p {
        margin-bottom: 10px;
        line-height: 30px;
      }
    }
  }
  .section_title {
    text-align: center;
    margin: 10px 0;
  }
  .value_title {
    text-align: center;
    margin: 0;
    padding: 5px 0;
    background-color: #f7f7f7;
  }
  .value_container {
    display: flex;
    justify-content: space-between;

    & > p {
      text-align: center;
      width: 25%;
      border-right: 1px solid #ccc;
      margin: 5px 0;

      &:last-child {
        border-right: none;
      }
    }
    .finanzrate_container {
      display: flex;
      flex-direction: column;
      border-right: 1px solid #ccc;
      align-items: center;
      width: 25%;
      margin: 5px 0;

      & > div {
        width: 100%;
        padding: 0 5px;

        select {
          font-size: 14px;
          color: #444;
        }
      }

      & > p {
        margin: 10px 0;
        text-align: center;

        span {
          color: #335566;
          font-size: 22px;
        }
      }

      .flatrate_list {
        li {
          display: flex;
          justify-content: space-between;
          padding: 3px 5px;
          flex-wrap: wrap;
          word-break: break-word;
          flex-direction: column;
          align-items: flex-start;

          & > span:first-child {
            display: inline-flex;
            align-items: center;

            img {
              height: 25px;
              margin-right: 5px;
            }
          }
          & > span:last-child {
            align-self: flex-end;
          }
        }
      }
    }
  }
}

.subitem_arrow {
  width: 16px;
  height: 16px;
  position: relative;
  left: 10px;
  top: 2px;
  transition: all 0.5s ease;
}
.subitem_arrow_expand {
  transform: rotate(180deg);
}

@media (min-width: 500px) {
  .body {
    .value_container {
      .finanzrate_container {
        .flatrate_list {
          li {
            flex-direction: row;
            align-items: center;

            & > span:first-child {
              width: 55%;
            }
            & > span:last-child {
              flex-direction: row;
              align-items: start;
              align-self: center;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 600px) {
  .body {
    .value_container {
      .finanzrate_container {
        & > div {
          width: 50%;
        }
      }
    }
  }
}

@media (min-width: 1000px) {
  .body {
    .cars_container {
      width: 100%;
    }
    .header {
      .arrows {
        display: none;
      }
    }
  }
}

@media (min-width: 1200px) {
  .body {
    .value_container {
      .finanzrate_container {
        .flatrate_list {
          li {
            & > span:first-child {
              width: auto;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1300px) {
  .body {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .close_icon {
    right: 25px;
    top: 25px;
  }
}
