.leasing_driver {
  flex: 1.2 !important;
}

.leasing {
  flex: 1.2 !important;
}

@media screen and (max-width: 1687px) {
  .leasing {
    flex: 1.2 !important;
  }
}
@media screen and (max-width: 1595px) {
  .leasing {
    flex: 1.4 !important;
  }
  .history {
    flex: 1.4 !important;
  }
}
@media screen and (max-width: 1539px) {
  .leasing {
    flex: 1.6 !important;
  }
  .history {
    flex: 1.6 !important;
  }
}

@media screen and (max-width: 1482px) {
  .leasing {
    flex: 2 !important;
  }
}

@media screen and (max-width: 1415px) {
  .leasing {
    flex: 2.5 !important;
  }
  .history {
    flex: 2.3 !important;
  }
}
