$green: #95c11f;

@-webkit-keyframes loader-before {
  0% {
    left: 0;
    width: 0;
  }
  50% {
    left: 25%;
    width: 75%;
  }
  75% {
    left: 100%;
    width: 0;
  }
}
@keyframes loader-before {
  0% {
    left: 0;
    width: 0;
  }
  50% {
    left: 25%;
    width: 75%;
  }
  75% {
    left: 100%;
    width: 0;
  }
}
@-webkit-keyframes loader-after {
  0%,
  50% {
    left: 0;
    width: 0;
  }
  75% {
    left: 0;
    width: 25%;
  }
  100% {
    left: 100%;
    width: 0;
  }
}
@keyframes loader-after {
  0%,
  50% {
    left: 0;
    width: 0;
  }
  75% {
    left: 0;
    width: 25%;
  }
  100% {
    left: 100%;
    width: 0;
  }
}

.linear_loader {
  z-index: 1000;
  display: block;
  position: relative;
  height: 4px;
  width: 100%;
  background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      from(rgba(255, 255, 255, 0.7)),
      to(rgba(255, 255, 255, 0.7))
    ),
    -webkit-gradient(linear, left top, right top, from(#009383), to(#009383));
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)),
    linear-gradient(to right, #009383, #009383);

  &:after,
  &:before {
    z-index: 1000;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0;
    background-color: #009383;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

  &:before {
    left: 0;
    -webkit-animation-name: loader-before;
    animation-name: loader-before;
  }

  &:after {
    right: 0;
    -webkit-animation-name: loader-after;
    animation-name: loader-after;
  }
}
