.full_content {
  padding: 45px 0;
  height: calc(100% - 65px);
  background-color: #fff;
  overflow: auto;
}

.container_style {
  min-height: calc(100vh - 150px);
}
