.tooltip_container {
  position: relative;
  font-weight: normal;
  min-width: 21px;

  .tooltip {
    position: fixed;
    padding: 5px 10px;
    max-width: 250px;
    font-size: 12px;
    color: #fff;
    background-color: #777777;
    border-radius: 3px;
    z-index: 1000;
    letter-spacing: 0.04em;
    opacity: 0;
    transition: all 0.1s ease-in;
    line-height: 18px;
    word-break: break-word;
  }
}

.tooltip {
  position: fixed;
  padding: 5px 10px;
  max-width: 250px;
  font-size: 12px;
  color: #fff;
  background-color: #777777;
  border-radius: 3px;
  z-index: 1000;
  letter-spacing: 0.04em;
  opacity: 0;
  transition: all 0.1s ease-in;
  line-height: 18px;
  word-break: break-word;
}
