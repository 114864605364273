.main_container {
  display: flex;
  margin-top: 15px;
}

.img_ctr {
  min-height: 185px;

  //@media (min-width:695px) {
  //  min-height: auto;
  //}
}
.header_title {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  color: #22273a;
  padding: 0;
}
.environment_container {
  width: 50%;

  .environment_value {
    padding: 25px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 89px;
  }
  .environment_value_container {
    width: 50%;
    p {
      font-weight: 600;
      font-size: 16px;
      color: #000;
    }
    span {
      font-size: 14px;
      color: #5F6466;
    }
    h5 {
      font-size: 18px;
      color: #5F6466;
    }
  }
}
.environment_container_no_CO2_diagram {
  width: 100%;
  display: flex;
}
.divider {
  border-top: 1px solid #b1b1b1;
}

@media (max-width: 600px) {
  .main_container {
    flex-direction: column;
  }

  .environment_container {
    width: 100%;
  }
  .environment_container_no_CO2_diagram {
    display: block;
  }
}
