.container {
  cursor: pointer;
  margin-top: 10px;

  &_delete {
    padding: 12px;
    border: 1px solid #e0e3e5;
  }

  .file {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .file_description_container {
      display: flex;
      align-items: center;
      min-width: 0;

      .file_description {
        word-break: break-all;
        margin-left: 10px;
        min-width: 0;

        .file_name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          min-width: 0;
        }

        .file_modify_date {
          font-size: 10px;
          color: rgb(119, 124, 127);
        }
      }
    }

    .delete_button {
      cursor: pointer;
      margin-left: 15px;
    }
  }
}

.icon {
  min-width: max-content;
}
