.enter {
  opacity: 0;
  transition: all 800ms ease-in-out;
}
.enterActive {
  opacity: 1;
  transition: all 800ms ease-in-out;
  animation-duration: 800ms;
  animation-name: slidein;
}
.exit {
  opacity: 1;
  transition: all 800ms linear;
}
.exitActive {
  opacity: 0;
  transition: all 800ms linear;
  animation-duration: 800ms;
  animation-name: slideout;
}

@keyframes slidein {
  from {
    margin-top: 100%;
    height: 200%;
  }

  to {
    margin-top: 35px;
    height: 100%;
  }
}

@keyframes slideout {
  from {
    margin: 0%;
    width: 100%;
    height: 100%;
    transform: translateY(0px);
  }

  to {
    width: 100%;
    height: 200%;
    transform: translateY(-100%);
  }
}

@keyframes slidein-mobile {
  from {
    width: 100%;
    height: 200%;
    transform: translateY(100%);
  }

  to {
    width: 100%;
    height: 100%;
    transform: translateY(0%);
  }
}

@keyframes slideout-mobile {
  from {
    width: 100%;
    height: 100%;
    transform: translateY(0px);
  }

  to {
    width: 100%;
    height: 100%;
    transform: translateY(-100%);
  }
}

@media (max-width: 900px) {
  .enterActive {
    animation-name: slidein-mobile;
  }
  .exitActive {
    animation-name: slideout-mobile;
  }
}
