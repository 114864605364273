.find_out_container {
  background-color: #fff;
  max-width: 450px;
  padding: 20px;
  display: flex;

  @media screen and (max-width: 76px) {
    width: 500px;
  }

  .title {
    font-size: 24px;
    color: #102f3f;
    margin-bottom: 15px;
  }

  .content_wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .photo_wrapper {
      overflow: hidden;
      border-radius: 50%;
      width: 70px;
      height: 70px;
    }

    .content_container {
      display: flex;
      width: 70%;

      .content {
        padding: 0;
        max-width: 80%;
        text-align: left;
        font-size: 14px;
        color: #102f3f;
      }
    }
  }

  .actions_wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .buttons_wrapper {
      width: 70%;
      display: flex;
    }
  }

  .cross_icon_wrapper {
    padding-left: 5px;
    height: 14px;
    cursor: pointer;
  }
}
