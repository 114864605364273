.offer {
  display: flex;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  align-items: flex-start;
  color: #171819 !important;
}

.offer_warning {
  background: #FFF9E5;
}

.offer_success {
  background: #e6ffe6;
}

.offer_danger {
  background: #ffe6e6;
}

.offer_info {
  background: #E5F7FF;
}

.icon {
  margin-top: 2px;
  margin-right: 12px;
  width: 15px;
  min-width: 15px;
  height: 15px;
}

.children {
  text-align: start;
}
