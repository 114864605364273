.button {
  position: fixed;
  right: 30px;
  z-index: 97;
  margin-top: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #335566;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
  &:hover {
    box-shadow: 0.5px 3px 15px rgba(0, 0, 0, 0.25);
  }
}
