.container {
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
  border-left: 6px solid transparent;
  padding: 15px 20px;

  .value {
    font-size: 14px;
    &_secondary {
      color: #abafb3;
      font-size: 12px;
      margin-top: 3px;
    }
  }

  .list_column {
    display: none;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 20px;

    .item_column {
      padding-left: 0;
      .field_name {
        color: #8fafbf;
        font-size: 12px;
        font-weight: 400;
      }

      .field_value {
        color: #000;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.container + .container {
  margin-top: 10px;
}

@media screen and (max-width: 550px) {
  .list_column {
    display: grid !important;
  }
}
