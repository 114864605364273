.wrapper {
  color: #102f3f;

  .download_button {
    display: block;
    margin: 20px 0 20px auto;
  }

  .download_archive {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;

    span {
      font-weight: 600;
      font-size: 18px;

      margin-left: 20px;
    }
    .download_icon {
      height: 22px;
      width: 18px;
    }
    .pointer {
      cursor: pointer;
    }
  }
  .groupe_item {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .groupe_item_checkbox {
      visibility: hidden;
    }

    &:hover {
      background-color: #f5f5f5;
      .groupe_item_checkbox {
        visibility: visible;
      }
    }

    span {
      font-weight: bold;
      font-size: 14px;
      margin-left: 15px;
    }
    .rotate {
      transform: rotate(180deg);
    }
  }
  .file {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .file_checkbox {
      visibility: hidden;
    }

    &:hover {
      background-color: #f5f5f5;
      .file_checkbox {
        visibility: visible;
      }
    }

    .file_description_container {
      margin-left: 35px;
      display: flex;
      align-items: center;
      .file_description {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        font-size: 14px;
        word-break: break-word;
        .file_modify_date {
          font-size: 10px;
          color: #777c7f;
        }
      }
      svg {
        min-width: 24px;
      }
    }
  }
  .mehr_anzeigen_btn {
    height: 35px;
    line-height: 35px;
    color: #22273a;
    font-size: 14px;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.25);
    }
  }
  .empty_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    h5 {
      color: #335566;
      font-size: 18px;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
    }
  }
}

.hr_widget,
.hr_widget_small {
  opacity: 80;
  margin: 0;
}

.hr_widget_small {
  margin: 0 4%;
}

.checkbox_all_state {
  margin-left: auto;
}

.group_item_block {
  padding-bottom: 10px;
}

.kosten_info {
  display: flex;
  flex-direction: column;
  p {
    margin-left: 15px;
    font-size: 12px;
    font-weight: 400;
    color: #777c7f;
  }
}

.empty_message {
  text-align: center;
}
