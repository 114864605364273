.configuration_from_pdf {
  min-height: 100px;
  .configuration_from_pdf_title {
    font-size: 14px;
    color: #2e3133;
    margin-bottom: 20px;
  }
  .form {
    margin: 0;
    section {
      padding: 0;
      margin-bottom: 10px;
    }
  }
  .datepicker {
    text-align: center;
  }
  .field_title {
    font-size: 14px;
    color: #102f3f;
    margin-bottom: 5px;
    .required_mark {
      color: #bf0040;
    }
  }
  .error_message {
    padding-top: 5px;
    font-size: 12px;
    color: #bf0040;
  }
}

.months_control {
  display: flex;
  margin: 0 -2px;
  .value_container {
    margin: 0 2px;
    padding: 10px 0;
    width: 100%;
    background-color: #f0f1f2;
    display: flex;
    justify-content: center;
    border-radius: 2px;
  }
  .active {
    background-color: #335566;
    color: #fff;
  }
  .disabled {
    background-color: #abb0b2;
    color: #fff;
  }
}

.controlled_input {
  border: 1px solid #abb0b2;
  border-radius: 3px;
  padding: 5px;
  display: flex;
  justify-content: space-between;

  .button {
    color: #102f3f;
    font-size: 28px;
    line-height: 20px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .subtraction {
    border-right: 1px solid #abb0b2;
    padding-right: 5px;
    padding-bottom: 3px;
  }
  .addition {
    border-left: 1px solid #abb0b2;
    padding-left: 5px;
  }
}

.custom_input {
  background-color: transparent;
  height: 28px;
  width: 100%;
  border: none;
  font-size: 14px !important;
  text-align: center;
  color: #102f3f;
  font-weight: bold;

  &::placeholder {
    font-weight: normal;
    color: #777C7F;
  }
}

.error {
  border: 1px solid #bf0040 !important;
}
