.wrapper {
  display: flex;
  flex-direction: column;
  .steps_wrapper {
    display: flex;

    .step_item {
      display: flex;
      align-items: center;

      svg {
        min-height: 24px;
      }

      .circle {
        min-width: 24px;
        min-height: 24px;
        border: 2px solid;
        border-radius: 50%;

        &_inactive {
          cursor: not-allowed;
        }
      }

      .underline_filled {
        width: 110px;
        height: 2px;
        border-radius: 4px;
        margin: 0 12px;

        &_inactive {
          opacity: 0.5;
        }
      }
    }
  }

  .description_wrapper {
    display: flex;
    margin-top: 20px;

    div {
      width: 150px;
      margin-right: 10px;
    }
  }
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: row;

    .steps_wrapper {
      flex-direction: column;
      align-items: flex-start;

      .step_item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .underline_filled {
          width: 2px;
          height: 34px;
          margin: 6px 0;
        }
      }
    }
    .description_wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 0;
      margin-left: 20px;

      div {
        width: 100%;
      }
    }
  }
}

@media (max-width: 550px) {
  .step_item {
    .underline_filled {
      width: 2px;
      height: 34px;
      margin-top: 6px 0;
    }
  }
  .description_wrapper {
    margin-left: 10px !important;
  }
}

.wrapper_vertical {
  flex-direction: row;

  .steps_wrapper {
    flex-direction: column;
    align-items: flex-start;

    .step_item {
      display: flex;
      flex-direction: column;
      align-items: center;

      .underline_filled {
        width: 2px;
        height: 34px;
        margin-top: 10px;
      }
    }
  }

  .description_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    margin-left: 20px;

    div {
      width: 100%;
    }
  }
}
