.wrapper {
  color: #102f3f;

  .download_archive {
    background-color: #f5f5f5;
    display: flex;
    padding: 0 20px 20px;
    align-items: center;
    justify-content: space-between;

    span {
      font-weight: 600;
      font-size: 18px;

      margin-left: 25px;
    }
    .download_icon {
      height: 22px;
      width: 18px;
    }
    .pointer {
      cursor: pointer;
    }
  }
  .groupe_item {
    padding: 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #777c7f;
    cursor: pointer;

    .groupe_item_checkbox {
      visibility: hidden;
    }

    &:hover {
      background-color: #f5f5f5;
      .groupe_item_checkbox {
        visibility: visible;
      }
    }

    span {
      font-weight: bold;
      font-size: 14px;
      margin-left: 20px;
    }
    .rotate {
      transform: rotate(180deg);
    }
  }
  .file {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .file_checkbox {
      visibility: hidden;
    }

    &:hover {
      background-color: #f5f5f5;
      .file_checkbox {
        visibility: visible;
      }
    }

    .file_description_container {
      margin-left: 35px;
      display: flex;
      align-items: center;
      .file_description {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        font-size: 14px;
        word-break: break-word;
        .file_modify_date {
          font-size: 10px;
          color: #777c7f;
        }
      }
      svg {
        min-width: 24px;
      }
    }
  }
  .mehr_anzeigen_btn {
    height: 35px;
    line-height: 35px;
    background-color: #f5f5f5;
    color: #22273a;
    font-size: 14px;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.25);
    }
  }
  .empty_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    h5 {
      color: #335566;
      font-size: 18px;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
    }
  }
}

.file {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  border: 1px solid #e0e3e5;
  margin-bottom: 10px;
  word-break: break-word;

  .icon_container {
    display: flex;
    align-items: center;
    .description {
      display: flex;
      flex-direction: column;
      .file_name {
        padding: 0;
        font-size: 14px;
        color: #102f3f;
      }
      .file_size {
        padding: 0px;
        font-size: 10px;
        color: #777c7f;
      }
    }
  }
  .icon {
    min-width: 40px;
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  i {
    cursor: pointer;
    margin-left: 30px;
  }
}
