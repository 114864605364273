@mixin font {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.02em;
}

.filter {
  @include font;
  position: relative;
  display: flex;
  align-items: center;
  max-width: 175px;

  @media screen and (max-width: 550px) {
    position: static;
  }

  &.active {
    background-color: #335566;
    color: #ffffff;
  }

  margin-right: 10px;
  margin-bottom: 10px;

  @media screen and (max-width: 550px) {
    margin-right: 8px;
  }
}

.filter_button {
  display: flex;
  align-items: center;
  padding: 3px 10px 3px 10px;
  color: #335566;
  white-space: nowrap;
  background: #ffffff;
  cursor: pointer;
  width: 100%;
  border: 1px solid #335566;
  box-sizing: border-box;
  border-radius: 20px;

  @media screen and (max-width: 550px) {
    border-color: #acd2e5;
  }

  div {
    margin-left: 5px;
    display: inline;

    .icon {
      display: flex;
      padding: 2px 3px;

      @media screen and (max-width: 550px) {
        display: none;
      }
    }
  }

  &.active {
    background-color: #335566;
    color: #ffffff;

    @media screen and (max-width: 550px) {
      background-color: #acd2e5;
      color: #102f3f;
    }
  }
}

.content {
  z-index: 3;
  position: absolute;
  top: -1px;
  left: -1px;

  @media screen and (max-width: 550px) {
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.filter_cut_content {
  left: auto;
  right: -1px;
}
