.container {
  &:hover {
    fill: #e5f7ff;
  }
  cursor: pointer;
}

.container_empty {
  cursor: pointer;
}
