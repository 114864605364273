.right_sidebar_video_tutorials {
  padding-bottom: 0;

  .container {
    padding: 15px 20px;
    overflow: auto;
    height: 93%;
    display: flex;
    flex-direction: column;
    .subtitle_container {
      padding: 0 0 10px 0;

      .subtitle {
        font-size: 20px;
        color: #335566;
        font-weight: bold;
      }
    }
  }
}

.tool_conttainer {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  height: 100%;
  .support_box {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 16px 26px;
    box-shadow: 0px 7.59435px 17.869px rgba(171, 176, 178, 0.17), 0px 2.26103px 5.32008px rgba(95, 100, 102, 0.17);
    border-radius: 8px;
    margin-top: 10px;

    .image_container {
      width: 70px;
      height: 70px;
      overflow: hidden;
      border-radius: 50%;
    }
  }

  .restart_tour_button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 7.59435px 17.869px rgba(171, 176, 178, 0.17), 0px 2.26103px 5.32008px rgba(95, 100, 102, 0.17);
    border: none;
    height: 45px;
    border-radius: 8px;
    min-width: 100%;

    &:hover {
      opacity: 0.8;
    }

    .button_icon {
      margin-right: 5px;
    }
  }
}

.tool_conttainer_wothout_support_box {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  height: 100%;
}
