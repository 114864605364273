.panel {
  background: #ffffff;
  box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
  padding-bottom: 66px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 586px;
  max-width: 1270px;
  margin: 0 auto;
  .title {
    font-size: 18px;
    color: #005e6e;
    margin-bottom: 20px;
  }
  .correct {
    font-size: 14px;
    color: #102f3f;
    font-weight: 400;
    margin-bottom: 20px;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    a {
      text-decoration: none;
    }
    .button_all {
      width: 200px;
      height: 32px;
      padding: 6px 16px;
      border-radius: 3px;
      transition: box-shadow 0.3s;
      cursor: pointer;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-color: #335566;
      border: none;
      margin-left: 10px;
      &:hover {
        box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
      }
    }
    .button_new {
      width: 176px;
      height: 32px;
      border: none;
      padding: 6px 16px;
      border-radius: 3px;
      transition: box-shadow 0.3s;
      cursor: pointer;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      color: #335566;
      margin-right: 10px;
      text-decoration-line: underline;
      &:hover {
        box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
      }
    }
  }
}
