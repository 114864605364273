p,
h3 {
  margin: 0;
}

.offer {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;

  &__wrapper {
    padding: 10px;
    border: 1px solid #335566;
    border-radius: 5px;
    color: #335566;
  }

  &__greeting {
    font-size: 14px;
    font-weight: normal;
  }

  &__description {
    color: #335566;
  }

  &__warning {
    margin-top: 10px;
  }
}
