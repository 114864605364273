.container {
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  min-height: 250px;
  padding: 20px;

  .spinner_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: #fff;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 15px 0 0;

    .title_container {
      display: flex;
      align-items: center;
      .link {
        display: flex;
        align-items: center;
      }
      .title {
        font-size: 18px;
        font-weight: 600;
        margin-left: 10px;
        color: #335566;
      }
    }

    .title {
      font-size: 18px;
      font-weight: 600;
      margin-left: 10px;
      color: #335566;
    }
  }

  .switch_list {
    display: flex;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 5;

    .switch_circle {
      width: 12px;
      height: 12px;
      border: 1px solid #c6cacc;
      border-radius: 50%;
      cursor: pointer;
      margin-bottom: 0;
    }

    .switch_circle + .switch_circle {
      margin-left: 5px;
    }

    .switch_circle_active {
      border: none;
      background: #335566;
    }
  }

  .children {
    width: 100%;
    height: 100%;
  }

  .children_with_title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 30px);
    ul {
      height: 100%;
    }
  }
}

@media screen and (max-width: 550px) {
  .container {
    .header {
      margin-bottom: 10px;

      .title_container {
        .title {
          color: #335566;
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }
}
