$base-color: #5f6466;
$circle-size: 25px;

.title {
  font-size: 18px;
  color: $base-color;
  font-weight: bold;
}
.description {
  font-size: 14px;
  color: $base-color;
}

.list_container {
  display: flex;
  flex-direction: column;

  .title_item {
    position: relative;
    margin-bottom: 20px;
    min-height: 50px;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      left: -40px;
      width: $circle-size;
      height: $circle-size;
      border: 2px solid $base-color;
      border-radius: 50%;
    }
    .line {
      position: absolute;
      height: 45%;
      width: 1px;
      top: 38px;
      opacity: 0.7;
      left: -$circle-size - 3px;
      background-color: $base-color;
    }
  }
}

.history_item_title:hover {
  text-decoration: underline;
}
