.normal_type_container {
  max-width: 460px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-width: 300px;

  .head {
    padding: 20px;
    background: #f5f5f5;
    display: flex;

    .icon {
      margin-right: 23px;
    }

    div {
      display: flex;
      flex-direction: column;

      h3 {
        line-height: 1;
        font-weight: 600;
        font-size: 18px;
        color: #356;
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
        line-height: 18px;
        font-weight: normal;
        font-size: 14px;
        color: #777777;
        max-width: 260px;
      }
    }
  }
}

.simple_type_container {
  padding: 25px 0 0;
  background-color: #fff;
  border-top: 5px solid #335566;
  .head {
    padding: 0px 30px;
    margin-bottom: 25px;
    .title {
      font-size: 16px;
      font-weight: 600;
    }
    .description {
      display: none;
    }
    .icon {
      display: none;
    }
  }
}

.description {
  color: #777c7f;
}

.new_type_container {
  padding: 25px 0 0;
  background-color: #fff;
  border-top: 5px solid #335566;
  margin-bottom: 20px;
  .head {
    padding: 0 20px 30px 20px;
    display: flex;
    border-bottom: 1px solid #e0e3e5;
    .title {
      font-size: 20px;
      font-weight: 600;
      color: #335566;
      margin-bottom: 3px;
    }
    .icon {
      margin-right: 10px;
      margin-top: 5px;
    }
  }
}
