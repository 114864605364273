.file_download_type {
  display: flex;
  justify-content: space-between;

  .file_description_container {
    margin-left: 0 !important;
  }

  .download_icon {
    display: block;
    width: 14px;
    height: 20px;
    margin-left: 15px;
  }
}

.file {
  padding: 10px 20px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    .download_button {
      visibility: visible;
    }
  }

  .download_button {
    display: flex;
    visibility: hidden;
  }

  .visible {
    visibility: visible !important;
  }

  .file_checkbox {
    visibility: hidden;
  }

  &:hover {
    background-color: #f5f5f5;
    .file_checkbox {
      visibility: visible;
    }
  }

  .close_icon_container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 25px;
  }

  .file_description_download_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .file_description_container {
      margin-left: 15px;
      display: flex;
      align-items: center;
      .file_description {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        font-size: 14px;
        word-break: break-word;
        .file_modify_date {
          font-size: 10px;
          color: #777c7f;
        }
      }
      svg {
        min-width: 24px;
      }
    }
  }
}
