.page_container {
  background-color: #f5f5f5;
  padding: 30px;
  height: auto;
  min-height: calc(100% - 36px);
  max-width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 550px) {
    padding: 15px;
  }

  .customize_columns_button_container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    transform: translateY(-60px);
  }

  .driver_status {
    @media screen and (max-width: 550px) {
      font-size: 14px;
      text-align: right;
      width: 45%;
    }
  }

  .content_wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .vehicles_container {
    width: 100%;
    max-width: 1600px;
    min-width: 800px;
  }

  .filters_container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  .new_vehicles {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    height: 100%;
    width: 20%;

    figure {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      border-radius: 30px;
      margin-right: 20px;
      cursor: pointer;
    }

    figure:hover {
      background: #fff;
      box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
    }
  }

  .visible {
    visibility: visible;
  }

  .hide {
    visibility: hidden;
  }
}

@media screen and (max-width: 550px) {
  .new_vehicles {
    display: none !important;
  }
  .page_container {
    .vehicles_container {
      min-width: auto;
    }
  }
}
