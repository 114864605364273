.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 50px;
  h5 {
    color: #335566;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: center;
  }
  .header {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #102f3f;
  }
}

.icons,
.icons_full {
  min-width: 1040px;
  display: flex;
  flex-direction: row;
  margin: 40px 0 20px;
  flex-wrap: wrap;
  justify-content: center;
  .icon {
    margin: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 210px;
    width: 275px;
    border-radius: 10px;
    padding: 20px 30px 20px 30px;
    border: 1px solid #8fafbf;
    div {
      margin-top: 10px;
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-align: left;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-align: center;
      color: #102f3f;
      width: 190px;
      margin: auto auto 0;
    }
  }
}

.icons_full {
  min-width: 1600px;
}

.success_name {
  color: #335566;
}

.info_block {
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 100px;
  .info_block_left,
  .info_block_right {
    display: flex;
    flex-direction: column;
    width: 512px;
  }
  .info_block_left {
    justify-content: flex-start;
    margin-right: 25px;
    h4 {
      font-size: 24px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.02em;
      margin-bottom: 30px;
    }
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.02em;
      text-align: left;
      color: #102f3f;
    }
    button {
      margin-top: 40px;
      height: 42px;
      width: 238px;
      left: 0px;
      top: 138px;
      border-radius: 2px;
      padding: 10px 20px 10px 20px;
      background-color: #335566;
      color: white;
      border: none;
      border-radius: 2px;
      font-size: 16px;
    }
  }
  .info_block_right {
    margin-left: 25px;
  }
}

@media (max-width: 900px) {
  .container {
    margin: 0 auto;
    max-width: 440px;
    h5 {
      margin-top: 40px;
    }
    .icons {
      min-width: auto;
      justify-content: center;
      .icon {
        margin: 10px;
        width: 43%;
        text-align: center;
        max-height: auto;
        height: auto;
        padding: 0;
        .success_name {
          font-size: 13px;
          text-align: center;
        }
        svg {
          margin-top: 10px;
          width: 60px;
          height: 60px;
        }
        p {
          padding: 10px;
          font-size: 10px;
          width: auto;
        }
      }
    }
  }
  .info_block {
    margin-top: 40px;
    max-width: 100%;
    flex-direction: column;
    .info_block_left {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0;
      h4 {
        display: inline;
        font-size: 20px;
        text-align: center;
      }
      span {
        text-align: center;
      }
      button {
        margin-top: 25px;
      }
    }
    .info_block_right {
      width: 100%;
      height: 100%;
      margin: 40px 0 0;
    }
  }
}

@media (max-width: 500px) {
  .icons {
    justify-content: flex-start;
  }
}

@media (max-width: 350px) {
  .container {
    .icons {
      flex-direction: column;
      justify-content: center;
      .icon {
        width: 100% !important;
        margin: 0 0 10px;
      }
    }
  }
}
