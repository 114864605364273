.filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.cancelContainer {
  color: #005e6e;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.02em;
  margin-left: 20px;

  span {
    margin-left: 13px;
  }
}

.cancelContainer:hover {
  cursor: pointer;
}

.filter_buttons_wrapper {
  display: flex;
  justify-content: space-between;
}
