.tooltip {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  color: #102f3f;
  font-size: 12px;
  margin-right: 10px;
}

.header_wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.tooltip_icon_wrapper {
  display: flex;
}

.description {
  position: absolute;
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  background-color: #5f6466;
  border-radius: 2px;
  padding: 3px 8px;
  display: block;
  transition: 0.3s;
  width: 214px;
  letter-spacing: 0.04em;
  z-index: 10000;
}

.description_on_click {
  position: absolute;
  font-size: 14px;
  color: #000;
  background-color: #fff;
  box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding: 20px;
  z-index: 10000;
}

.close_icon {
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
