.insurance_dialog {
  margin: 30vh auto;
  background: #F0F1F2;
  box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
  width: 400px;
  border-radius: 3px;
  padding: 10px;
  top: 50%;
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_title {
      font-size: 12px;
      color: #5F6466;
    }
    img {
      cursor: pointer;
    }
  }

  &_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .reset {
      font-size: 12px;
      text-decoration-line: underline;
      color: #777C7F;
      cursor: pointer;
    }
    .buttons {
      display: flex;
      .transparent {
        background-color: transparent;
      }
    }
  }
}
