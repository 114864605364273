.enter {
  opacity: 0;
  transition: all 800ms ease-in-out;
  width: 100%;
}
.enterActive {
  opacity: 1;
  transition: all 800ms ease-in-out;
  animation-duration: 800ms;
  animation-name: slidein-damage;
  width: 100%;
}
.exit {
  opacity: 1;
  transition: all 800ms linear;
}
.exitActive {
  opacity: 0;
  transition: all 800ms linear;
  animation-duration: 800ms;
  animation-name: slideout;
}

@keyframes slidein-damage {
  from {
    margin: 0 auto;
    margin-top: 100%;
    height: 300%;
    width: 980px;
  }

  to {
    margin: 0 auto;
    margin-top: 0%;
    height: 100%;
    width: 980px;
  }
}

@keyframes slideout {
  from {
    opacity: 1;
    margin: 0%;
    max-width: 980px;
    width: 100%;
    transform: translateY(0px);
  }

  to {
    opacity: 0;
    z-index: 1231;
    max-width: 980px;
    width: 100%;
    transform: translateY(100%);
  }
}

@keyframes slidein-damage-mobile {
  from {
    margin: 0 auto;
    margin-top: 100%;
    height: 300%;
    width: 100%;
    transform: translateY(100%);
  }

  to {
    margin: 0 auto;
    margin-top: 0%;
    height: 100%;
    width: 100%;
    transform: translateY(0%);
  }
}

@keyframes slideout-mobile {
  from {
    opacity: 1;
    margin: 0%;
    max-width: auto;
    width: 100%;
    transform: translateY(0px);
  }

  to {
    opacity: 0;
    z-index: 1231;
    max-width: 100%;
    width: 100%;
    transform: translateY(100%);
  }
}

@media (max-width: 900px) {
  .enterActive {
    animation-name: slidein-damage-mobile;
  }
  .exitActive {
    animation-name: slideout-mobile;
  }
}
