.field {
  height: 50px !important;

  &_icon {
    height: 50px !important;

    &:first-child {
      height: auto !important;
    }
  }
}
