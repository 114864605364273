.container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  max-width: 490px;
  input {
    &:focus::-webkit-input-placeholder {
      color: transparent;
    }
  }
}

.item_block {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
}

.row_columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.half_input,
.item_name {
  width: 47.5%;
  align-self: center;
  position: relative;
}

.item_name {
  color: #102f3f;
  font-size: 14px;
  font-weight: 400;
  .req {
    margin-left: 2px;
    color: #bf0040;
  }
}

.delete_item {
  margin-left: 20px;
  cursor: pointer;
}

.dropdown {
  position: absolute;
  width: 206px;
  overflow-x: hidden;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
  visibility: hidden;
  z-index: 10;
  cursor: default;
  &.v {
    visibility: visible;
    padding: 0;
  }
}

.dropdown_li {
  font-size: 14px;
  margin: 0;
  cursor: default;
  display: flex;
  height: 48px;
  &:hover {
    background-color: #e5f7ff;
    cursor: pointer;
  }
}

.item_text1 {
  padding: 5px;
  padding-left: 16px;
  align-self: center;
}

.input__icons__drop,
.input_icons_drop_err {
  width: 100%;
  height: 42px;
  border: 1px solid #acd2e5;
  border-radius: 2px;
  -webkit-appearance: none;
  appearance: none;
  background-image: url(/assets/images/drop-down-blue-icon.svg);
  background-repeat: no-repeat;
  background-position-x: 5px;
  background-position-y: 50%;
  padding-left: 25px !important;
  font-size: 14px !important;
  cursor: pointer;
}

.input_icons_drop_err {
  border-color: #bf0040 !important;
}

@media (max-width: 900px) {
  .item_block {
    width: 100%;
    .row_columns {
      display: flex;
      flex-direction: column;
      min-width: 90%;
      width: 100%;
      .item_name {
        align-self: baseline;
        margin-bottom: 10px;
        width: 100%;
      }
      .half_input {
        width: 97%;
        align-self: baseline;
      }
    }
    .delete_item {
      margin: 0;
      display: flex;
      align-self: end;
      padding-bottom: 12px;
    }
  }
  .dropdown {
    width: 91%;
    margin-left: 1px;
    max-width: 405px;
  }
}

@media (max-width: 900px) {
  .dropdown {
    width: 88%;
    margin-left: 1px;
    max-width: 405px;
  }
}
