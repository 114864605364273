.user_icon {
  width: 32px;
  height: 32px;
  background-color: #E5F7FF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-position: center;
  background-size: cover;

  .initials {
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #8FAFBF;
  }
}
