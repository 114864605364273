.container {
  &:hover {
    fill: #e5f7ff;
  }
  cursor: pointer;
}

.container_empty {
  cursor: pointer;
}

.damage_container {
  margin-left: 90px;
  margin-top: 22px;
}

.damage_container_small {
  margin-top: 22px;
  align-self: center;
}

@media (max-width: 350px) {
  .damage_container_small {
    width: 100%;
    height: 100%;
  }
}
