.no_scroll_bar {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 1px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.no_scroll_bar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
