.enter {
  opacity: 0;
  transition: all 800ms ease-in-out;
  width: 100%;
}
.enterActive {
  opacity: 1;
  transition: all 800ms ease-in-out;
  animation-duration: 800ms;
  animation-name: slidein-damage;
  width: 100%;
}
.exit {
  opacity: 1;
  transition: all 800ms linear;
}
.exitActive {
  opacity: 0;
  transition: all 800ms linear;
  animation-duration: 800ms;
  animation-name: slideout-damage;
}

@keyframes slidein-damage {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

@keyframes slideout-damage {
  from {
    transform: translateY(0);
  }

  to {
    z-index: 1231;
    transform: translateY(-100%);
  }
}
