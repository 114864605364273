/**
 * Material Modal CSS
 */

//$modal-delay:500;
.modal {
  will-change: visibility, opacity;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1001;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  //-webkit-transition-delay: $modal-delay;
  //transition-delay: $modal-delay;
}
.modal__active {
  visibility: visible;
  opacity: 1;
}
.modal__align_top {
  -webkit-box-align: start;
  align-items: flex-start;
}
.modal__bg {
  background: rgba(#000000, 0.5);
}
.modal__dialog {
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal__content {
  will-change: opacity;
  position: relative;
  background-clip: padding-box;
  opacity: 0;
  -webkit-transition: all 0.25s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.25s cubic-bezier(0.23, 1, 0.32, 1);
}
.modal__content__active {
  opacity: 1;
  max-height: 100%;
  width: 100%;
}
.modal__close {
  z-index: 1100;
  cursor: pointer;
}
.modal__trigger {
  position: relative;
  display: inline-block;
  padding: 1.2rem 2.4rem;
  color: rgba(0, 0, 0, 0.7);
  line-height: 1;
  cursor: pointer;

  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}
//.modal__trigger__active {
//  z-index: 10;
//}
//.modal__trigger:hover {
//  background: #e5d3d6;
//}
//#modal__temp {
//  will-change: transform, opacity;
//  position: absolute;
//  top: 0;
//  left: 0;
//  right: 0;
//  bottom: 0;
//  background: #ffebee;
//  -webkit-transform: none;
//  transform: none;
//  opacity: 1;
//  -webkit-transition: opacity 0.1s ease-out, -webkit-transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
//  transition: opacity 0.1s ease-out, -webkit-transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
//  transition: opacity 0.1s ease-out, transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
//  transition: opacity 0.1s ease-out, transform 0.5s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
//}
