.driver_widgets {
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  .top_block,
  .bottom_block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    .upcoming_task {
      flex: 1;
      min-width: 560px;
      min-height: 350px;
      border-radius: 2px;
      @media screen and (max-width: 550px) {
        min-width: 0;
      }
    }

    div {
      max-height: 350px;
    }
  }
}

@media screen and (max-width: 1687px) {
  .driver_widgets {
    .top_block {
      .upcoming_task {
        flex: 0.5;
      }
    }
  }
}

@media (max-width: 768px) {
  .driver_widgets {
    margin: 10px;
    width: calc(100% - 20px);
    .top_block,
    .bottom_block {
      flex-direction: column;
      width: 100%;
    }
  }
}
