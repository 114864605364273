.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  align-items: center;

  svg {
    width: 180px;
    height: 50px;
  }
}

.loading_text {
  font-size: 18px;
  background: linear-gradient(to right, #dbdbdb 0%, #002633 40%, #002633 60%, #dbdbdb 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  background-size: 200% auto;
  animation: textShine 1s ease-in-out infinite alternate;
}

@keyframes textShine {
  to {
    background-position: 200%;
  }
}
