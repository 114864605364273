$mainGuideColor: #102f3f;

.tooltip {
  background-color: #d8e5ec;
  max-width: 375px;
  padding: 20px;
  display: flex;
  .guide_title_wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    .guide_title {
      font-size: 24px;
      color: $mainGuideColor;
      margin-bottom: 15px;
    }
  }

  .guide_content_wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    text-align: left;

    .guide_content {
      color: $mainGuideColor;
      font-size: 14px;
    }
  }

  .cross_icon_wrapper {
    padding-left: 5px;
    height: 14px;
    cursor: pointer;
  }

  .action_wrapper {
    display: flex;
    width: 100%;
    margin-top: 20px;
    justify-content: space-between;
    align-items: center;

    .steps_count {
      font-size: 12px;
      color: #767676;
    }

    .next_button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 32px;
      text-align: center;
    }
  }
}
