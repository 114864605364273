$blue: #335566;

.title {
  color: $blue;
  margin: 0 0 5px;
  font-size: 14px;
}
.select {
  border: 1px solid $blue;
  height: 40px;
  border-radius: 3px;
  width: 100%;
  font-size: 14px;
}
