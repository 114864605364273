/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  // border: 1px solid #ccc;
}

/* Style the buttons that are used to open and close the accordion panel */
.mobexo__accordion {
  //background-color: #eee;
  position: relative;
  color: #444;
  cursor: pointer;
  //padding: 18px;
  display: flex;
  align-items: center;
  padding: 10px 0;
  outline: none;
  transition: background-color 0.6s ease;
  justify-content: space-between;

  .chevron {
    z-index: 1;
    display: block;
    //position: absolute;
    //top:50%;
    // transform: translateY(-50%);
    //top: 0;
    //left: 0;
    //width: 16px;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
  }
}

//

/* Style the accordion content title */
.accordion__title {
  display: block;
  padding: 0 15px 0 36px;
  background-color: #eee;
  //border-radius: 2px;

  font-size: 16px;
  font-family: "'Open Sans'", sans-serif;
  font-weight: 600;
  line-height: 44px;
  color: #444;
  width: 100%;
  cursor: pointer;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: inherit;
  // background-color: white;
  overflow: hidden;
}
.expand_icon {
  transition: transform 0.6s ease;
  margin-left: 10px;
}
.expand_icon_active {
  transform: rotate(180deg);
}
