.upload_file {
  margin: 0;
  cursor: pointer;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='2' stroke-dasharray='10%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  span {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #777777;
  }
  .upload_link {
    font-size: 12px;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    cursor: pointer;
    color: #335566;
  }
  .upload_link_unfallreparatur {
    font-size: 12px;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    cursor: pointer;
    color: #005e6e !important;
  }
}
.upload_link_container {
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
  .hint_container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .accepted_formats_unfallreparature {
      display: flex;
      align-items: center;
      position: relative;
      cursor: default;
      font-size: 12px;
      color: #777777;
    }
    .accepted_formats {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      .hint {
        position: absolute;
        padding: 5px 10px;
        width: 240px;
        font-size: 12px;
        color: #fff;
        background-color: #777777;
        border-radius: 3px;
        z-index: 1000;
        text-align: left;
        bottom: 30px;
        line-height: 18px;
        right: 0;
        .accept_string {
          font-weight: bold;
          color: #fff;
        }
        .number_of_files {
          color: #fff;
        }
      }
    }
  }
}
.files {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}
.file {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  border: 1px solid #e0e3e5;
  margin-bottom: 10px;
  word-break: break-word;

  .icon_container {
    display: flex;
    align-items: center;
    .description {
      display: flex;
      flex-direction: column;
      .file_name {
        font-size: 14px;
        color: #102f3f;
      }
      .file_size {
        font-size: 10px;
        color: #777c7f;
      }
    }
  }
  .icon {
    min-width: 40px;
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  i {
    cursor: pointer;
    margin-left: 30px;
  }
}

.error_message {
  display: flex;
  align-self: flex-start;
  p {
    margin-left: 5px;
    font-size: 12px;
    color: #bf0040;
  }
}
