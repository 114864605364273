$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

.fahrzeuge_car_policy_page {
  background-color: #f5f5f5;
  height: auto;
  min-height: calc(100% - 36px);
  padding: 30px 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;

  @media only screen and (min-width: $md) {
    max-width: 780px;
  }

  @media only screen and (min-width: $lg) {
    max-width: 1100px;
  }

  @media only screen and (min-width: $xl) {
    max-width: 1100px;
  }
}

.filter_wrapper {
  padding: 0 30px;

  @media (max-width: 450px) {
    display: none;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 20px 0;
}

.sort {
  padding: 0 0 10px 40px;
  justify-content: flex-end;
  display: flex;
  .sort_container {
    width: 300px;
  }
}
