.widget_header {
  display: flex;
  align-items: flex-start;
  margin: 0 15px 25px;
  margin-top: -35px;
  :first-child {
    margin-top: 3px;
  }
  h5 {
    color: #335566;
    margin: 0 0 0 10px;
    font-size: 20px;
  }
}
