.header {
  background-color: #102F3F;
  border-bottom: 1px solid transparent;
  min-height: 80px;
  padding: 10px 40px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    height: 50px;
    width: 133px;
  }
  button {
    background-color: transparent;
    border: none;
    color: #fff;
    text-transform: uppercase;
    transition: all .3s;
    i {
      margin-right: 10px;
    }
    &:hover {
      color: #ffbf00;
    }
    @media (min-width: 1024px) {
      display: none;
    }
  }
  nav {
    position: absolute;
    top: 100%;
    background-color: #102F3F;
    left: 0;
    right: 0;
    .list {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 15px;
      li {
        position: relative;
        display: flex;
        width: 100%;
        flex-direction: column;
        a {
          font-size: 15px;
          padding: 18px 10px;
          width: 100%;
          color: #fff;
          text-decoration: none;
          transition: all .3s;
          cursor: pointer;
          &:hover {
            color: #ffbf00;
          }
          &:hover ~ .dropdown_menu {
            display: flex;
          }
        }
        .dropdown_menu {
          min-width: 150px;
          display: flex;
          flex-direction: column;
          position: static;
          top: 45px;
          border-radius: 3px;
          left: 20px;
          transition: all .3s;
          padding: 0 15px;
          a {
            font-size: 13px;
            padding: 12px 5px;
            color: #fff;
            width: 100%;
            &:hover {
              color: #ffbf00;
            }
          }
          &:hover {
            display: flex;
          }
        }
        .submenu {
          top: 0;
          left: 100%;
          &:hover ~ .dropdown_menu {
            display: flex;
          }
        }
      }
    }
    @media (min-width: 1024px) {
      display: block !important;
      position: static;
      top: auto;
      background-color: transparent;
      .list {
        flex-direction: row;
        padding: 0;
        li {
          width: auto;
          flex-direction: row;
          a {
            padding: 18px 20px;
          }
          .dropdown_menu {
            position: absolute;
            background: #fff;
            box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.3);
            display: none;
            padding: 0;
            a {
              color: #000;
              padding: 12px 15px;
            }
          }
        }
      }
    }
  }
  .contact {
    border-radius: 5px;
    background-color: #FFBF00;
    color: #335666 !important;
    &:hover {
      color: #fff !important;
    }
  }
  .login {
    color: #ffbf00 !important;
  }
  .hidden {
    display: none;
  }
}
