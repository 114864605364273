.container {
  padding: 0 10px 10px;
  height: 305px;
}

.top_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(100% - 15px);

  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .cost_list_item {
    margin-top: 40px;
  }
}

.bottom_container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px 20px;
  &_item {
    display: flex;
    padding: 0px 5px;
    align-items: center;
    justify-content: center;
    gap: 8px;
    span {
      width: 6px;
      height: 6px;
      border-radius: 6px;
      flex-shrink: 0;
    }
    p {
      width: min-content;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.24px;
      color: #777c7f;
    }
  }
}

.canvas_block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 300px;
  height: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 2;
  margin-top: -20px;
  .empty_data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 20px;
    span {
      margin-top: 20px;
      width: 60%;
      text-align: center;
      color: var(--grey-800, #2e3133);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.28px;
    }
  }
  canvas {
    width: 300px;
    max-width: 300px;
    max-height: 300px;
  }
  .text_block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    span {
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      line-height: 33px;
      color: #335566;
    }
    p {
      text-align: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #5f6466;
    }
  }
}
