.container {
  flex: 1;
}

.container,
.full_container,
.full_container_mobile,
.container_mobile {
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  border-radius: 2px;
  .main_block {
    border-radius: 2px;
    background-color: #fff;
  }
  .additional_block {
    width: 100%;
  }
  .additional_block,
  .additional_block_mobile {
    border-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .main_block,
  .additional_block,
  .additional_block_mobile {
    padding: 16px;
  }
  .additional_block {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    .additional_info {
      max-width: 200px;
    }
  }
  .additional_block,
  .additional_block_mobile {
    background-color: #335566;
    width: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    box-shadow: inset 15px 0 15px -15px rgba(0, 0, 0, 0.4);
    .additional_header {
      display: flex;
      flex-direction: row;
      margin: 0;
      h6 {
        margin: 0 0 20px 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
      }
    }
    .additional_info {
      margin-bottom: 15px;
      span,
      .tooltip_string {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #fff;
        .link {
          color: #ffbf00;
          text-decoration: underline !important;
          cursor: pointer;
        }
      }
      span {
        flex: 1;
        word-break: break-word;
      }
      p {
        flex: 1;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #acd2e6;
        word-break: break-word;
      }
    }
  }
}

.container_mobile {
  .main_block {
    width: 100%;
  }
}

.full_container {
  .main_block {
    width: 410px;
    min-width: 400px;
  }
}

.full_container_mobile,
.container_mobile {
  .main_block {
    min-width: auto;
  }
}

.full_container {
  .main_block {
    box-shadow: 4px 0 4px 2px rgba(0, 0, 0, 0.25);
  }
}

.image_block {
  height: 220px;
  width: 100%;
  position: relative;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .upload_photo_img {
    position: absolute;
    bottom: 5px;
    right: 10px;
    margin: 0;
    z-index: 15;
    cursor: pointer;
  }
}

.car_name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0 0;
  .show_cars {
    width: 100%;
    max-width: 300px;
    min-width: 150px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    h6 {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      color: #335566;
      margin: 0;
      min-width: 120px;
    }
  }
}

.car_model {
  margin: 10px 0 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #335566;
}

.car_number {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #777c7f;
}

.container_mobile,
.full_container_mobile {
  width: 100%;
  flex-direction: column;
  min-width: auto;
  max-width: none;
  height: auto;
  .additional_block_mobile {
    min-width: auto;
    max-width: none;
    border-radius: 2px;
    box-shadow: none;
    background-color: #102f3f;
    box-shadow: 0px 9px 19px rgba(0, 0, 0, 0.21);
    border-radius: 2px;
    height: auto;
    max-height: none !important;
    .additional_header {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      margin-bottom: 20px;
      h6 {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #ffbf00;
        margin: 0;
      }
      span {
        margin-left: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: #ffffff;
      }
    }
    .additional_info,
    .additional_info_blue {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 0;
      gap: 0 5px;
      p {
        max-width: 50%;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;
      }
      span {
        max-width: 50% !important;
      }
      span,
      .tooltip_string {
        width: 100%;
        max-width: 100%;
        text-align: start;
      }
    }
    .additional_info_blue {
      p,
      span {
        flex: 1;
        color: #acd2e6;
      }
    }
  }
}

.container {
  max-width: 413px;
}

@media (max-width: 768px) {
  .container,
  .full_container {
    width: 100%;
    flex-direction: column;
    min-width: auto;
    max-width: none;
    height: auto;
    .main_block,
    .additional_block {
      border-radius: 2px;
      box-shadow: none;
    }
    .additional_block {
      background-color: #102f3f;
      box-shadow: 0px 9px 19px rgba(0, 0, 0, 0.21);
      border-radius: 2px;
      height: auto;
      .additional_header {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        margin-bottom: 20px;
        h6 {
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          color: #ffbf00;
          margin: 0;
        }
        span {
          margin-left: 10px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.02em;
          color: #ffffff;
        }
      }
      .additional_info,
      .additional_info_blue {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 0;
        p {
          max-width: 50%;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #ffffff;
        }
        span {
          max-width: 50% !important;
        }
        span,
        .tooltip_string {
          width: 100%;
          max-width: 100%;
          text-align: start;
          max-width: 130px;
        }
      }
      .additional_info_blue {
        p,
        span {
          color: #acd2e6;
        }
      }
    }
  }
}

.empty_block {
  height: 20px;
  box-shadow: none;
  background-color: #f5f5f5;
  display: block;
}

.tooltip_string {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tooltip_string_long {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.spinner_block {
  width: 400px;
  border-radius: 2px;
  min-height: 200px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown {
  position: absolute;
  width: 100%;
  min-width: fit-content;
  max-height: 200px;
  overflow-x: hidden;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
  visibility: hidden;
  z-index: 10;
  left: 0;
  cursor: default;
  &.v {
    visibility: visible;
    padding: 5px 0;
  }
}

.dropdown_li {
  color: var(--Blue, #356);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  cursor: pointer;
}

.item_text1 {
  padding: 5px;
  white-space: nowrap;
  &:hover {
    background-color: #e5f7ff;
    cursor: pointer;
  }
}

.expand_bottom,
.expand_top {
  display: block;
  margin-left: 10px;
  height: 14px;
  width: 14px;
}

.expand_bottom {
  transform: rotate(0deg);
}

.expand_top {
  transform: rotate(180deg);
}

.star_tooltip {
  cursor: 'pointer';
  height: '29px';
}

@media (max-width: 450px) {
  .spinner_block {
    width: 100%;
  }
}
