.footnote {
  font-size: 10px;
  padding-top: 45px;

  &:last-child {
    padding-bottom: 15px;
  }
  @media screen and (min-width: 992px) {
    padding-top: 10px;
  }
}
