.container {
  margin: 18px 15px 20px 15px;
  display: flex;
  flex-direction: column;
}

.file_block {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
  .file_name {
    font-size: 14px;
    font-weight: 600;
    color: #22273a;
    margin-bottom: 15px;
  }
  .file_show {
    flex-direction: row;
  }
}

.download_links {
  width: 100%;
  word-break: break-all;
  flex-direction: row;
}

.link__row {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.download_img {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.file_description {
  display: flex;
  flex-direction: column;
  p {
    font-size: 10px;
    font-weight: 400;
    color: #777c7f;
  }
}