.open_left_widget {
  display: none;
  position: absolute;
  top: 20px;
  right: 23px;
  cursor: pointer;
  z-index: 4;
}

@media screen and (max-width: 1700px) {
  .open_left_widget {
    display: block;
  }
}
