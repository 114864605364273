.breadcrumbs {
  background: linear-gradient(180deg, #003644 0%, #00606D 35.42%, #00606D 63.54%, #003644 100%);

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 25px;

    .links {
      padding: 5px 0;

      a {
        color: #2BAAB1;
        text-transform: uppercase;
      }
      img {
        margin: 0 10px;
      }
    }
    .willkommen {
      padding: 5px 0;

      h3 {
        color: #fff;
        margin: 0;
        text-transform: capitalize;
      }
    }
  }
}
