.main_container{
  height: auto;
  margin: 0px -10px;

  &.main_container_scroll{
    overflow:hidden;
    overflow-x: auto;
  }
}


.table_container {
  min-width: 850px;
  padding: 0 10px;

  .table_header {
    display: flex;
    font-size: 14px;
    color: #abafb3;
    padding: 19px 25px;
    .text {
      word-break: break-word;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      .sort_arrow {
        margin-right: 5px;
        height: 12px;
        transform: rotate(180deg);
        transition: transform 0.3s;
      }
      .reverse {
        transform: rotate(0);
      }
    }
  }
}

@media (max-width: 700px) {
  .table_container {
    .table_header {
      display: none;
    }
  }
}

@media screen and (max-width: 550px) {
  .table_container {
    min-width: 200px !important;
    padding: 0 10px;
  }
  .table_row {
    box-shadow: none !important;
    padding: 0 !important;
    border-radius: 5px !important;
  }
}
