@import "../../assets/variables";


.row{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media (min-width:925px){
    flex-direction: row;
  }
}
.container {
  display: flex;
  flex-direction: column;

  @media (min-width:650px){
    flex-direction: row;
    justify-content: space-between;
  }
}
.footer {
  background: url("/assets/images/main-bg-min.jpg") no-repeat;
  background-size: cover;
  border-top: 7px solid #de9b00;
  padding: 70px 0 0;

  a {
    color: #fff;
    text-decoration: underline;
    font-size: 22px;
    line-height: 36px;
  }
  h4, h6 {
    font-weight: 400;
    color: #fff;
  }
  h4 {
    font-size: 22px;
    margin-bottom: 30px;
  }
}

.col_form{
  order:-1;
  width: 100%;
  @media (min-width: 925px){
    order: 1;
    width: 50%;
  }
}
