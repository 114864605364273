$mainColor: #2e3133;

.description_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  .description {
    max-width: 60%;
    color: $mainColor;
  }
  .image_container {
    width: 55px;
    height: 55px;
    overflow: hidden;
    border-radius: 50%;
  }
}

.form_container {
  margin-bottom: 30px;
  .form_title {
    color: $mainColor;
    font-size: 14px;
  }
}

.action_container {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;

  .action_button {
    min-width: 80px;
    border-radius: 2px;
    padding: 5px 15px;
  }

  .booking_button {
    margin-left: 10px;
  }

  .disabled_button {
    opacity: 0.6;
    background: #c6cacc;
    pointer-events: none;
  }
}

.title_container {
  margin-bottom: 25px;
}

.meeting_info_container {
  margin: 25px 0;
  display: flex;
  flex-direction: column;

  .meeting_date {
    font-weight: bold;
    margin-left: 5px;
  }
}
