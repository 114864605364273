.file_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.input {
  color: #777777;
  font-size: 14px;
  padding: 10px 15px;
  border: 1px solid #777777;
  min-height: 43px;
  width: 100%;
}

.uploaded_file_input {
  color: #000;
  font-size: 14px;
  padding: 10px 15px;
  border: 1px solid #777777;
  min-height: 43px;
  width: 100%;
}

.active_input {
  font-size: 14px;
  padding: 10px 15px;
  border: 1px solid #777777;
  min-height: 43px;
  width: 100%;

  &::placeholder {
    color: #000;
  }
}

.error {
  color: #ff4700;
  border: 1px solid #ff4700;
  box-shadow: 0 0 3px 0 #ff4700;
}
