#pdf-viever-container {
  overflow: auto;
  width: 100%;

  canvas {
    margin-bottom: 10px;
    max-width: 100vw;
  }
}

.photo-viewer-container {
  min-height: auto !important;
  overflow: auto;
}

.react-grid-Grid {
  min-height: 90vh !important;
}

.pg-viewer-wrapper .document-container {
  width: 100vw;
}

.pg-viewer-wrapper .pg-viewer {
  //overflow-y: hidden !important;
}

@media screen and (max-width: 550px) {
  .photo-viewer-container > img {
    min-width: 90vw;
    min-height: auto;
  }
}
