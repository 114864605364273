.container,
.container_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
  min-height: 600px;
  height: 100%;
  h5 {
    font-weight: 600;
    font-size: 18px;
    color: #335566;
    margin-bottom: 5px;
    text-align: center;
  }
  span {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: #335566;
  }
  .inputs_block {
    display: flex;
    flex-direction: row;
    margin-top: 60px;
    width: 220px;
    height: 48px;
    border: 1px solid #acd2e5;
    border-radius: 2px;
    padding: auto;
    background-color: #fff;
    justify-content: space-between;
    font-size: 16px;
    align-items: center;
    vertical-align: middle;
    input {
      &:focus::-webkit-input-placeholder {
        color: transparent;
      }
    }
  }
}

.container_center {
  margin-top: 0;
}

.mobexo_link {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #335566;
  display: flex;
  justify-self: flex-end;
  margin: 50px;
  margin-top: auto;
  a {
    color: #335566;
    text-decoration: underline !important;
  }
}

.input_first,
.input_second,
.input_third {
  border: none;
  height: auto;
  min-height: 20px;
  letter-spacing: -1px;
  width: auto;
  text-align: center;
}

.input_first {
  max-width: 54px;
}

.input_second {
  max-width: 43px;
}

.input_third {
  max-width: 80px;
}

.search_icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 46px;
  padding-right: 15px;
}

.data_fields {
  display: flex;
  flex-direction: column;
  width: 440px;
  h6 {
    font-weight: 600;
    font-size: 14px;
    color: #102f3f;
    margin-bottom: 20px;
  }
  input {
    &:focus::-webkit-input-placeholder {
      color: transparent;
    }
  }
}

.form_name {
  span {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.02em;
    padding: 0;
    color: #102f3f;
    .req {
      color: #bf0040;
    }
  }
}

.input_standart {
  width: 100%;
  border: 1px solid #acd2e5;
  border-radius: 2px;
  height: 38px;
  padding: 10px;
  margin-top: 5px;
  font-size: 14px !important;
}

.double_field {
  display: flex;
  flex-direction: column;
  width: 58%;
  margin-right: 2%;
}

.one_field {
  display: flex;
  flex-direction: column;
  width: 38%;
  margin-left: 2%;
}

.full_field {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row_columns {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 440px;
  justify-content: space-between;
}

.input__icons {
  width: 100%;
  margin-top: 5px;
  height: 38px;
  border: 1px solid #acd2e5;
  border-radius: 2px;
  padding-left: 35px !important;
  background-image: url(/assets/images/date-blue-icon.svg) !important;
  background-repeat: no-repeat;
  background-position-x: 5px;
  background-position-y: 50%;
  padding-left: 0;
  font-size: 14px !important;
  &:focus {
    border: 1px solid #acd2e5;
  }
}

.optional {
  font-weight: 400;
  font-size: 14px;
  color: #919699 !important;
}

.button_block {
  display: flex;
  margin-top: 30px;
  margin-bottom: 50px;
  .button_active,
  .button_wait {
    width: 440px;
    height: 42px;
    border: none;
    border-radius: 2px;
    font-size: 16px;
    color: #fff;
  }
  .button_wait {
    background-color: #c6cacc;
  }
  .button_active {
    background-color: #335566;
    &:hover {
      box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.16);
    }
  }
}

.input__block {
  position: relative;
  font-size: 14px;
  .input__icons {
    font-size: 14px;
    &:default {
      color: #777c7f;
    }
    option {
      font-size: 12px;
      &:disabled {
        color: #777c7f;
      }
    }
  }
  .input__block__img {
    position: absolute;
    top: 16px;
    left: 8px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  .input__icons {
    padding-left: 5px;
  }
  .radio_err {
    color: #bf0040;
    border: 1px solid #bf0040;
  }
  input {
    &:focus::-webkit-input-placeholder {
      color: transparent;
    }
  }
}

.dropdown {
  width: 100%;
  min-width: 200px;
  height: 100%;
  max-height: 250px;
  overflow-x: hidden;
  border: 1px solid #b3b3b3;
  background-color: #fff;
  visibility: hidden;
  z-index: 10;
  cursor: default;
  &.v {
    visibility: visible;
  }
}

.dropdown_li,
.dropdown_li_always {
  font-size: 14px;
  margin: 0;
  cursor: default;
  display: flex;
  height: 48px;
  &:hover {
    background-color: #e5f7ff;
    cursor: pointer;
  }
}

.dropdown_li_always {
  z-index: 12;
}

.item_text1 {
  padding: 5px;
  padding-left: 16px;
  align-self: center;
  color: #102f3f;
}

.always_item_text {
  padding: 5px;
  padding-left: 16px;
  align-self: center;
  font-weight: 600;
  color: #102f3f;
}

.input__icons__drop,
.input__icons__drop_err {
  width: 100%;
  height: 42px;
  border: 1px solid #acd2e5;
  border-radius: 2px;
  -webkit-appearance: none;
  appearance: none;
  background-image: url(/assets/images/drop-down-blue-icon.svg);
  background-repeat: no-repeat;
  background-position-x: 5px;
  background-position-y: 50%;
  padding-left: 25px;
  font-size: 14px !important;
  margin-top: 5px;
  cursor: pointer;
  .input__icons__drop_err {
    border: 0.5px solid #bf0040;
    &:focus {
      padding-left: 25px;
      border: 1px solid #bf0040;
    }
  }
}

.err__row__auto {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  img {
    margin-top: 3px;
    margin-right: 3px;
  }
  p {
    margin-top: 2px;
    color: #bf0040;
  }
}

.err__row {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  img {
    margin-top: 3px;
    margin-right: 3px;
  }
  p {
    margin-top: 2px;
    color: #bf0040;
  }
}

.kennzeichen_block {
  display: flex;
  flex-direction: column;
  width: 220px;
  margin-bottom: 60px;
}

.container_wrapper_enter {
  opacity: 0;
}
.container_wrapper_enter_active {
  opacity: 1;
  transition: opacity 1500ms ease-in-out;
}
.container_wrapper_exit {
  opacity: 1;
}
.container_wrapper_exit_active {
  opacity: 0;
  transition: opacity 1500ms ease-in-out;
}

.new_car_info {
  width: 440px;
  height: 110px;
  background: #e6f7ff;
  border-radius: 3px;
  margin-bottom: 60px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  .icon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(23, 24, 25, 1);
  }
}

.input__icons_err__text {
  width: 100%;
  max-width: 440px;
  margin-top: 5px;
  height: 38px;
  border: 1px solid #bf0040;
  border-radius: 2px;
  padding-left: 10px !important;
  background-repeat: no-repeat;
  background-position-x: 5px;
  background-position-y: 50%;
  padding-left: 0;
  font-size: 14px !important;
  &:focus {
    padding-left: 40px;
    font-size: 14px !important;
    border: 1px solid #bf0040;
  }
}

@media (max-width: 900px) {
  .container,
  .container_center {
    max-width: 440px;
    width: 100%;
    h5,
    span {
      padding: 0 45px;
    }
    h5 {
      margin-bottom: 10px;
    }
  }
  .container_center {
    h5 {
      margin-top: 60px;
    }
  }
  .new_car_info {
    width: 80%;
    height: auto;
  }
  .data_fields {
    width: 100%;
    span {
      padding: 0;
    }
  }
  .button_block {
    margin-bottom: -80px;
    width: 100%;
  }
  .mobexo_link {
    margin-top: 140px;
  }
  .kennzeichen_block {
    width: 220px;
    .inputs_block {
      width: 220px;
      input {
        height: 46px;
        line-height: 46px;
        text-align: center;
        vertical-align: middle;
      }
      input::placeholder {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 300px) {
  .container,
  .container_center {
    width: 100%;
  }
}
