.cancelContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #335566;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.02em;
  padding: 4px 12px;
  margin-bottom: 10px;

  &:hover {
    background-color: #e0e3e5;
    border-radius: 2px;
  }

  span {
    display: flex;
    align-items: center;
    margin-right: 9px;
  }
}
