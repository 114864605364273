.item {
  display: grid;
  grid-template-columns: 30% 23% 27% 20%;
  align-items: center;
  color: #5f6466;
  padding: 10px;
  margin: 0;
  min-height: 53px;

  .task_value {
    display: flex;
    align-items: center;
    color: #102f3f;

    .task_icon {
      display: flex;
      align-items: center;
      width: 20px;
    }

    span {
      margin-left: 5px;
    }
  }

  .column {
    display: flex;
  }

  .value {
    display: block !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    margin-right: 25px;
  }

  .status {
    width: 12px;
    height: 12px;
    background-color: #000;
    border-radius: 50%;
  }
}

.empty_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .empty_placeholder {
    color: #373b4d;
    text-align: center;
  }

  .empty_image {
    width: 72px;
    align-self: center;
    margin-bottom: 20px;
  }
}

.item + .item {
  border-top: 1px solid #f0f1f2;
}

.to_task_button {
  display: none;
  @media (min-width: 1700px) {
    display: block;
  }
}

.to_task_icon {
  cursor: pointer;
  @media (min-width: 1700px) {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .list {
    min-width: auto;
  }

  .item {
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
    min-height: 57px;

    .column {
      display: none;

      div {
        font-size: 14px;
      }

      &:first-child {
        display: flex;
        width: 60%;

        div {
          margin-left: 5px;
          margin-right: 0;
        }

        span {
          margin-left: 0;
        }
      }

      &:nth-child(3) {
        display: flex;

        div {
          margin-right: 0;
        }
      }
    }
  }

  .empty_container {
    padding: 100px 20px;

    .empty_placeholder {
      color: #2e3133;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
    }
  }
}
