.comparison_panel {
  width: 100%;
  height: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 101;
  transition: transform 0.3s ease-out;
  box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.75);

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 0;
    cursor: pointer;

    h5 {
      margin: 0;
    }
    img {
      margin-right: 15px;
      transform: rotate(180deg);
      transition: transform 0.3s ease-out;
    }
    .rotate {
      transform: rotate(0deg);
    }
  }
  .cars_wrapper {
    display: flex;
    flex-direction: column;

    .cars_container {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }
    .buttons_container {
      display: flex;
      flex-direction: column;
      padding: 10px;

      button {
        width: 100%;
        border-radius: 3px;
        background-color: #335566;
        color: #fff;
        border: none;
        margin-bottom: 10px;
        padding: 8px;

        &:hover {
          background-color: #008091;
        }
      }
      .delete_all {
        color: #335566;
        background-color: #fff;
        border: 1px solid #335566;

        &:hover {
          background-color: #fff;
        }
      }
    }
  }
}

.open {
  transform: translateY(0);
}

.close {
  transform: translateY(calc(100% - 36px));
}

@media (min-width: 800px) {
  .comparison_panel {
    .cars_wrapper {
      flex-direction: row;
      padding-top: 10px;
    }
  }
}
