.container,
.details_container {
  display: flex;
  flex-direction: column;
  h5 {
    font-weight: 600;
    font-size: 18px;
    color: #335566;
  }
  h6 {
    align-self: flex-start;
    color: #102f3f;
  }
  .inputs_block {
    display: flex;
    flex-direction: row;
    margin-top: 60px;
    margin-bottom: 60px;
    width: 206px;
    height: 48px;
    border: 1px solid #acd2e5;
    border-radius: 2px;
    padding: 10px 10px 10px 10px;
    background-color: #fff;
    justify-content: space-between;
    font-size: 16px;
    align-items: center;
  }
  textarea {
    width: 100%;
    font-size: 14px;
    padding-left: 5px;
    resize: none;
    border: 1px solid #acd2e5;
    height: 76px;
    &:-webkit-autofill {
      background-color: #fff !important;
      -webkit-box-shadow: 0 0 0 1000px white inset !important;
      box-shadow: 0 0 0 1000px white inset;
    }
  }
  textarea,
  input {
    &:focus::-webkit-input-placeholder {
      color: transparent;
    }
  }
}

.files_container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-left: 80px;
  width: 670px;
  h5 {
    font-weight: 600;
    font-size: 18px;
    color: #335566;
  }
  h6 {
    align-self: flex-start;
    color: #102f3f;
  }
  .form_name_center {
    max-width: 500px;
  }
}

.details_container {
  max-width: 575px;
}

.mobexo_link {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #335566;
  position: absolute;
  bottom: -70px;
  a {
    color: #335566;
    text-decoration: underline !important;
  }
}

.input_first,
.input_second,
.input_third {
  border: none;
  height: 20px;
  letter-spacing: -1px;
  width: auto;
  text-align: center;
}

.input_first {
  max-width: 44px;
}

.input_second {
  max-width: 31px;
}

.input_third {
  max-width: 71px;
}

.search_icon {
  cursor: pointer;
}

.data_fields {
  display: flex;
  flex-direction: column;
  width: 440px;
  h6 {
    font-weight: 600;
    font-size: 14px;
    color: #102f3f;
    margin-bottom: 20px;
  }
}

.err__row {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  max-width: 440px;
  img {
    margin-top: 3px;
    margin-right: 3px;
  }
  p {
    margin-top: 2px;
    color: #bf0040;
  }
}

.form_name,
.form_name_center,
.upload_name {
  margin-right: auto;
  margin-bottom: 5px;
  max-width: 440px;
  span {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.02em;
    padding: 0;
    color: #102f3f;
    .req {
      color: #bf0040;
    }
  }
  .final_data {
    font-weight: 600;
    word-wrap: break-word;
  }
  h5 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #102f3f;
  }
}

.form_name_center {
  margin-right: 0;
}

.form_name_final,
.form_name_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  width: 100%;
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    padding: 0;
    color: #5f6466;
    max-width: 85%;
  }
  .fix {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.form_name_row {
  flex-direction: column;
}

.input_standart {
  width: 100%;
  border: 1px solid #acd2e5;
  border-radius: 2px;
  height: 38px;
  padding: 10px;
  font-size: 14px !important;
}

.double_field {
  display: flex;
  flex-direction: column;
  width: 58%;
  margin-right: 2%;
}

.one_field {
  display: flex;
  flex-direction: column;
  width: 38%;
  margin-left: 2%;
}

.row_columns,
.row_columns_files,
.row_columns_files_buttons,
.row_columns_inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 440px;
  width: 575px;
}

.row_columns_files,
.row_columns_files_buttons {
  min-width: 600px;
  width: 100%;
}

.row_columns,
.row_columns_inputs {
  width: 100%;
  max-width: 440px;
}

.input__icons,
.input__icons_date {
  width: 100%;
  height: 38px;
  border: 1px solid #acd2e5;
  border-radius: 2px;
  padding-left: 48px !important;
  background-repeat: no-repeat;
  background-position-y: 50%;
  padding-left: 0;
  font-size: 14px !important;
  &:focus {
    border: 1px solid #acd2e5;
  }
}

.input__icons_date {
  border-radius: 2px;
  background-image: url(/assets/images/date-blue-icon.svg);
  background-repeat: no-repeat;
  background-position-x: 5px;
  background-position-y: 50%;
  padding-left: 28px !important;
}

.optional {
  font-weight: 400;
  font-size: 14px;
  color: #919699 !important;
}

.button_block {
  display: flex;
  margin-top: 30px;
  margin-bottom: 50px;
  .button_active,
  .button_wait {
    width: 440px;
    height: 42px;
    border: none;
    border-radius: 2px;
    font-size: 16px;
  }
  .button_wait {
    color: #fff;
    background-color: #c6cacc;
  }
}

.button_option_false,
.button_option_true {
  margin-top: 5px;
  width: 440px;
  height: 42px;
  border-radius: 2px;
  font-size: 16px;
  color: #102f3f;
}

.button_option_false {
  background-color: #fff;
  border: 1px solid #acd2e5;
  &:hover {
    box-shadow: 2px 4px 5px 0px rgba(172, 210, 229, 0.3);
  }
}

.button_option_true {
  background-color: #e5f7ff;
  border: none;
}

.half_input,
.half_buttons {
  width: 47.5%;
}

.button_back {
  width: 100%;
  color: #102f3f;
  background-color: #fff;
  border: 1px solid #acd2e5;
  height: 42px;
  border-radius: 2px;
  font-size: 16px;
  &:hover {
    box-shadow: 2px 4px 5px rgba(172, 210, 229, 0.3);
  }
}

.button_active,
.button_wait {
  width: 100%;
  height: 42px;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  color: #fff;
  margin-bottom: 50px;
}
.button_wait {
  background-color: #c6cacc;
}
.button_active {
  background-color: #335566;
  &:hover {
    box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.16);
  }
}

.button_half,
.button_half_choosen {
  width: 100%;
  color: #102f3f;
  background-color: #fff;
  border: 1px solid #acd2e5;
  height: 42px;
  border-radius: 2px;
  font-size: 16px;
}

.button_half {
  &:hover {
    box-shadow: 2px 4px 5px rgba(172, 210, 229, 0.3);
  }
}

.button_half_choosen {
  background-color: #e5f7ff;
  border: none;
}

.damage_part,
.damage_part_choosen {
  display: flex;
  flex-direction: column;
  height: 150px;
  border: 1px solid #acd2e5;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  padding: 20px;
  p {
    margin-top: auto;
    color: #335566;
    font-size: 16px;
    font-weight: 400;
  }
}

.damage_part {
  &:hover {
    box-shadow: 2px 4px 5px rgba(172, 210, 229, 0.3);
  }
}

.damage_part_choosen {
  background-color: #e5f7ff;
  border: none;
  padding: 21px;
}

.damage_block {
  display: flex;
}

.buttons_damage_block {
  display: flex;
  flex-direction: column;
  max-width: 440px;
}

.songstige_block,
.songstige_block_mobile,
.songstige_block_item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #335566;
}

.songstige_block_mobile {
  justify-content: space-between;
}

.songstige_block_item {
  margin-top: 0;
  margin-right: 0;
  cursor: pointer;
}

.songstige_block_small {
  display: flex;
  flex-direction: column;
  max-width: 440px;
}

.input__block {
  position: relative;
  width: 100%;
  .select__code {
    border: 0 solid black;
    background-image: none;
    border-right: 1px solid #abb0b2;
    border-radius: 0;
    width: 35px;
    font-size: 14px;
    -webkit-appearance: none;
    appearance: none;
    background-image: none;
  }
  .input__icons__drop {
    padding-left: 20px;
    border: 0.5px solid rgba(119, 124, 127, 0.5);
    border-radius: 2px;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(/assets/images/drop-down-blue-icon.svg);
    background-repeat: no-repeat;
    background-position-x: 5px;
    background-position-y: 50%;
    font-size: 14px;
  }
  .input__block__img {
    position: absolute;
    top: 9px;
    left: 8px;
    width: 35px;
    border-right: 1px solid #abb0b2;
  }
  .input__block__drop {
    position: absolute;
    top: 16px;
    left: 8px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  .input__icons {
    padding-left: 48px;
    font-size: 14px;
  }
  .input__icons__drop {
    padding-left: 26px;
    font-size: 14px;
  }
}

.checkbox {
  display: flex;
  width: 100%;
  label {
    margin: 0px;
    width: 94%;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #102f3f;
  }
  input[type='checkbox'] {
    height: 18px;
    width: 18px;
    margin: 0;
    margin-right: 10px;
    vertical-align: middle;
    border-radius: 2px;
    accent-color: #335566;
  }
}

.hide__block {
  display: none;
}

.hide__block__show {
  cursor: pointer;
  opacity: 0.7;
  font-size: 14px;
}

.damage_choose_block {
  margin-top: 28px;
}

.close_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h6 {
    font-weight: 600;
    font-size: 14px;
    color: #102f3f;
    margin-bottom: 20px;
  }
  .close_block {
    cursor: pointer;
    margin-left: auto;
    height: 15px;
  }
}

.button_hide {
  display: none;
}

.full_input {
  width: 100%;
}

.tooltip {
  margin: 1px auto -7px 5px;
}

.form_name_err {
  color: #bf0040 !important;
}

.back_item {
  display: flex;
  align-items: center;
  cursor: pointer;
  .back_text {
    color: rgba(51, 85, 102, 1);
  }
  svg {
    width: 20px;
    margin-right: 15px;
  }
}

@media (max-width: 900px) {
  .container {
    width: 100%;
    justify-content: center;
    max-width: 440px;
    input,
    textarea,
    select {
      font-size: 16px !important;
    }
  }
  .row_columns,
  .row_columns_inputs {
    min-width: auto;
    width: 100%;
  }
  .form_name_center {
    text-align: center;
    span {
      display: initial;
    }
  }
  .files_container {
    padding: 0;
    width: 100%;
    max-width: 440px;
  }
  .row_columns_files {
    width: 100%;
    min-width: auto;
    flex-direction: column;
    .half_input {
      width: 100%;
    }
  }
  .row_columns_files_buttons {
    flex-direction: row;
    min-width: auto;
  }
  .row_columns_inputs {
    flex-direction: column;
  }
  .buttons_damage_block {
    width: 100%;
  }
  .songstige_block,
  .songstige_block_mobile {
    margin-right: 0;
  }
}

@media (max-width: 350px) {
  .damage_part,
  .damage_part_choosen {
    svg {
      width: 110px;
    }
  }
  .container,
  .details_container {
    // overflow: hidden;
  }
}

.driver_name {
  width: 100%;
  margin: 0;
  h6 {
    font-weight: 600;
    font-size: 14px;
    color: #102f3f;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.28px;
    color: #102f3f;
  }
}
