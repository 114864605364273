.checkbox {
  display: flex;
  align-items: center;
  .mouseout {
    display: block;
  }
  .mouseover {
    display: none;
  }
  &:hover {
    .mouseout {
      display: none;
    }
    .mouseover {
      display: block;
    }
  }
}
