.widget_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  flex: 1;
  background-color: #102f3f;
  border-radius: 5px;
  z-index: 2;
  padding: 15px;
}

.canvas_block {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  canvas {
    width: 100%;
    max-width: 190px;
    max-height: 190px;
  }

  .text_block {
    position: relative;
    display: flex;
    flex-direction: column;
    width: auto;
    max-width: 115px;
    align-self: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: -130px;

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #ffffff;
    }
    span {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      color: #ffbf00;
    }
  }
}

.cars_block {
  width: 100%;
  box-shadow: inset 13px 0 15px rgba(0, 0, 0, 0.25);
  background-color: #356;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  border-radius: 5px;
  margin-left: -8px;

  li {
    width: 50%;
    cursor: pointer;
    display: flex;
    text-align: center;
    flex-direction: column;

    align-self: center;
    justify-content: center;
    margin: 20px 0;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;

      &_count {
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        color: #ffffff;
        margin-left: 10px;
      }
    }

    p {
      margin-top: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #ffffff;
    }
  }
}

@media screen and (max-width: 1700px) {
  .canvas_block {
    width: 100%;
    background-color: #102f3f;
    z-index: 2;
  }
  .cars_block {
    position: absolute;
    top: 0;
    right: -5px;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: 0.2s;
  }
}
