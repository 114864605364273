.upload_file {
  margin: 0;
  cursor: pointer;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='2' stroke-dasharray='10%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

  &.error {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23BF0040' stroke-width='2' stroke-dasharray='10%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }

  span {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #777777;
  }

  .upload_link {
    font-size: 12px;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    cursor: pointer;
    color: #335566;
  }
}

.formats_text {
  display: contents;
  color: #777c7f;
  font-size: 12px;
  margin-top: 5px;
}
