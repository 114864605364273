.section {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  @media (min-width: 600px) {
    padding: 30px;
  }
  @media (max-width: 600px) {
    padding: 15px;
  }
}

.support_container {
  display: flex;
  flex-direction: column;
  padding-bottom: 120px;
  .widgets {
    display: flex;
    flex-direction: column;
    .widget {
      margin-bottom: 15px;
    }
  }
  .content {
    .content_title {
      font-size: 18px;
      color: #fff;
      margin: 20px 0 10px;
    }
    .cards_title {
      display: none;
      font-size: 20px;
      color: #22273a;
      margin-bottom: 20px;
    }
  }

  @media (min-width: 1400px) {
    flex-direction: row;

    .widgets {
      order: 2;
      width: 30%;
      min-width: 463px;
      .widget {
        margin-bottom: 25px;
      }
      .widget_quick_actions {
        order: 0;
      }
      .widget_download {
        order: 1;

        @media screen and (max-width: 551px) {
          min-width: 400px;
        }
      }
    }
    .content {
      order: 1;
      width: 100%;
      margin-right: 30px;
      .cards_container {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}

.quick_actions {
  background-color: #fff;
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  gap:20px;
  margin-top:-20px;

  & .action_button{
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #335566;
    flex-grow: 1;
    width:150px;
    padding: 15px 5px;
    box-sizing: border-box;
    height: 125px;
    transition: 0.2s;

    &:hover,
    &:active,
    &:focus{
      box-shadow: 2px 4px 5px 0px #00000029;
    }

    & .btn {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        background-color: #fff;
        cursor:pointer;

        & p{
          text-align: center;
          font-size: 13px;
          font-weight: 600;
        }
    }

  }
}

.download_header {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 10px;
  margin-left: 20px;
}
.files_header {
  font-size: 14px;
  font-weight: 600;
  color: #335566;
}
.link__row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  margin-left: 0;
}

.download_block {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

   .files_block{
     width: 100%;
   }

   .files_group_box{
     width:100%;
     height: auto;

    .files_group_title{
      display:flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      cursor:pointer;

      &:hover{
        background-color: #f5f5f5;
      }

      span {
        font-weight: 600;
        font-size: 14px;
        color: #22273A;
      }
    }

    .files_list{
      display: grid;
      width: 100%;
      transition: 300ms grid-template-rows ease-in;

      .files_list_wrapper{
        display:flex;
        flex-direction: column;
        overflow: hidden;
        width: 100%;

         .download_links {
          display: flex;
          color: #102f3f;
          width: 100%;
          padding: 13px 25px;
          cursor: pointer;

          &:hover{
            background-color: #E5F7FF;
          }
        }
      }

      &.visible{
        grid-template-rows: 1fr;
      }

      &.invisible{
        grid-template-rows: 0fr;
      }
    }

   }

  h6 {
    margin-bottom: 10px;
    margin-left: 20px;
  }

  button {
    font-size: 14px;
  }
}

.button_form_block {
  width: 100%;
}

.budmeldung_link {
  width: 100%;
  height: 35px;
  color: #fff;
  background-color: #335566;
  border: none;
  border-radius: 3px;
  font-size: 14px;
}

.content_widget {
  background-color: #fff;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
}

.contact_section {
  .photo {
    margin-right: 20px;
    img {
      min-width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  }
  .title {
    font-size: 18px;
    color: #22273a;
    margin-bottom: 8px;
  }
  .description {
    font-size: 14px;
    color: #777c7f;
    margin-bottom: 8px;
  }
  .phone_email {
    font-size: 14px;

    font-weight: bold;
    word-break: break-word;
    margin-top: 10px;
    display: flex;
    a {
      text-decoration: none;
      color: #22273a;
    }
  }
}

.employee_card {
  display: flex;
  align-items: center;
  margin: 20px 0;
  img {
    width: 30%;
    min-width: 90px;
    max-width: 230px;
    margin-right: 20px;
  }
  h6 {
    font-size: 18px;
    color: #000000;
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
    color: #000000;
    word-break: break-word;
  }

  @media (min-width: 1400px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px;
    img {
      min-width: 230px;
      margin-right: 30px;
    }
    h6 {
      margin-top: 10px;
    }
  }
}
.hide_on_desktop {
  width: 100%;
  @media (min-width: 1400px) {
    display: none;
  }
}

.hr_widget {
  opacity: 80;
}

.files_block {
  padding: 0 20px 20px;
}

.empty_files {
  margin-top: 15px;
}

.contact_buttons_wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  @media (max-width: 600px) {
    flex-direction: column;
  }

  .contact_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    border: 1px solid #335566;
    border-radius: 10px;
    padding: 15px 0;
    transition: 0.2s;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus{
      box-shadow: 2px 4px 5px 0px #00000029;
    }

    @media (max-width: 600px) {
      width: 100%;
      margin: 5px 0;
    }
  }
}

.section[blinking='1'] {
  animation: focused 1s 1.5;
}

@keyframes focused {
  0%,
  50%,
  100% {
    box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
  }

  25%,
  75% {
    box-shadow: none;
  }
}

.employee_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;

  @media (max-width: 1400px) {
    width: 100%;
  }
  .image_wrapper {
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 50%;
  }
}
