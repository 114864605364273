.post {
  width: 100%;
}
.post .line {
  // float: left;
  width: 100%;
  height: 16px;
  margin-top: 12px;
  border-radius: 7px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  animation: shine-lines 1.6s infinite linear;
}
.post .avatar + .line {
  margin-top: 11px;
  width: 100px;
}
.post .line ~ .line {
  background-color: #ddd;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 160px;
  }
}


.proposal{
  color:#f9a703;
  font-size:20px;
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-wrap: wrap;
  flex-grow: 1;
  margin-left: 0;;

  span{
    flex-shrink: 0;
    font-size: 15px;
    line-height: 30px;
  }

  @media (min-width:556px) {
    justify-content: left;

    span{
      font-size: 18px;
    }
  }
}
