.button {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;

  span {
    width: max-content;
    color: #335566;
    margin-left: 10px;
  }

  svg {
    transform: rotate(90deg);
  }
}
