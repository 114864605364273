.control_status_wrapper {
  display: flex;
  align-items: center;
}

.status {
  color: #5f6466;
  margin-left: 8px;
}

.status_success {
  color: #30bf30;
  margin-left: 8px;
}

.status_warning {
  color: #ff9700;
  margin-left: 8px;
}

.status_invalid {
  color: #bf0040;
  margin-left: 8px;
}
