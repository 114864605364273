.service_card {
  width: 100%;
  max-width: 350px;
  padding: 15px 20px;
  background-color: #052E3A;
  box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
  margin-bottom: 30px;

  .title {
    font-weight: 600;
    font-size: 15px;
    color: #FEC200;
    width: 50%;
  }
  .subtitle {
    font-size: 10px;
    color: #95BCD5;
    width: 50%;
    font-weight: 600;
  }
  .text {
    font-weight: 600;
    font-size: 10px;
    color: #FFFFFF;
    width: 50%;
  }

  @media (min-width: 992px) {
    order: 1;
    margin-left: 30px;
  }
}
