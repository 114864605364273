.enter {
  opacity: 0;
  transition: all 800ms ease-in-out;
}
.enterActive {
  opacity: 1;
  transition: all 800ms ease-in-out;
  animation-duration: 800ms;
  animation-name: slideout;
}
.exit {
  opacity: 1;
  transition: all 800ms linear;
}
.exitActive {
  opacity: 0;
  transition: all 800ms linear;
  animation-duration: 800ms;
  animation-name: slideout;
}

@keyframes slidein {
  from {
    margin-bottom: 100%;
    height: 300%;
    min-height: 620px;
    transform: translateY(-100%);
  }

  to {
    margin-bottom: 0;
    height: 100%;
    min-height: 620px;
    transform: translateY(0%);
  }
}

@keyframes slideout {
  from {
    opacity: 1;
    margin: 0%;
    min-height: 620px;
    transform: translateY(0%);
  }

  to {
    opacity: 0;
    z-index: 1231;
    min-height: 620px;
    transform: translateY(100%);
  }
}
