.breadcrumbs {
  background: #002633;
  z-index: 10;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;

    .willkommen {
      flex-grow: 1;
      color: #335566;
      display: flex;
      align-items: center;
      width: calc(100% - 40px);

      &__menu {
        border: none;
        background: transparent;
        padding: 0;
        display: flex;
        align-items: center;
        margin-right: 30px;
        margin-left: -10px;
        min-width: 48px;
      }
      &__text {
        margin: 0;
        padding-right: 36px;
        display: flex;
        align-items: center;
        width: calc(100% - 68px);

        &__title {
          font-weight: 400;
          font-size: 20px;
          line-height: 36px;
          letter-spacing: 0.02em;
          color: #fff;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          @media (max-width: 992px) {
            font-size: 16px;
          }
        }
      }
    }
    .roles_switcher_container {
      display: none;

      @media (min-width: 1200px) {
        display: flex;
        align-items: center;
      }
    }
  }
}

.help_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  cursor: pointer;
}
