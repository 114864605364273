.dashboard_item {
  border-color: #fff;
  box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
  width: 270px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  &:first-child {
    margin-top: 0;
  }

  .header {
    display: flex;
    background-color: #f5f5f5;
    border-radius: 5px 5px 0 0;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    .text {
      margin-left: 20px;
    }
    h5 {
      color: #335566;
      font-size: 20px;
      line-height: 20px;
    }
    p {
      font-size: 14px;
      line-height: 15px;
      color: #777777;
      margin: 0 5px 0;
    }
  }
  .expand_item {
    border-bottom: 1px solid #f5f5f5;
    background-color: #fff;

    .expand_item_header {
      display: flex;
      align-items: center;
      padding: 15px 26px;
      cursor: pointer;

      h6 {
        margin: 0;
        padding-left: 20px;
        font-size: 14px;
        line-height: 18px;
        color: #22273a;
      }
      .icon {
        transition: all 0.3s ease-out;
      }
    }
    .expand_item_body {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s;

      .name {
        font-size: 12px;
        line-height: 18px;
        color: #777777;
        margin: 0 25px;
      }
      .value {
        font-size: 14px;
        line-height: 18px;
        color: #22273a;
        margin: 0 25px 15px;
      }
      .choose_vehicle {
        .name {
          margin-bottom: 15px;
          cursor: pointer;
          text-align: right;

          a {
            color: inherit;
            text-decoration: none;
          }

          .icon {
            transform: rotate(270deg);
            margin-right: 10px;
            margin-bottom: 2px;
            width: 8px;
          }
        }
      }
    }
    &.open {
      .expand_item_body {
        max-height: 100vh;
        transition: max-height 1s;
      }
      .expand_item_header {
        .icon {
          transform: rotate(180deg);
        }
        h6 {
          color: #335566;
        }
      }
    }
  }
  .new_configuration {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;

    span {
      margin: 0 0 0 20px;
      color: #22273a;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .driver_buttons_container {
    padding: 20px;
    background-color: #fff;
  }
}
