.item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .circle {
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #000;
  }

  .name_container {
    display: flex;
    align-items: center;

    .name {
      color: #919699;
      font-size: 12px;
      font-weight: 600;
      margin-left: 5px;
    }
  }

  .price {
    color: #000;
    font-size: 14px;
    font-weight: 600;
  }
}

.item + .item {
  margin-top: 10px;
}

.inline_tooltip {
  margin-left: 5px;
  height: 16px;
}
