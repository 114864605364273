$line-opacity: 0.4;

.info_chip {
  color: rgb(255, 255, 255);
  background-color: rgb(145, 150, 153);
  margin: 0 8px;
  padding: 0 8px;
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  border-radius: 8px;
  cursor: default;
  vertical-align: baseline;
}

.menu_relative {
  position: relative;
  max-width: 1170px;
}

.menu_row {
  display: flex;

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
}

.menu_block {
  display: flex;
  flex-direction: column;
  width: 660px;
  height: 375px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  margin-bottom: 10px;
}

.menu_block_container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -10;
  max-width: 1170px;
  pointer-events: all;
  opacity: 0;
  transition: 0.5s;
  font-size: 14px;

  @media (max-width: 550px) {
    top: 10px;
  }

  li {
    text-decoration: none;
    margin: 0;
    width: 320px;
    min-height: 40px;
    padding: 5px 25px 5px 42px;
    a {
      display: flex;
      font-size: 14px;
      font-weight: 400;
      color: #abb0b2;
      line-height: 14px;
      text-decoration: none;
      cursor: default;
      align-items: center;
    }
  }
  .menu_flex {
    display: flex;
    align-items: center;
  }

  .menu_hidden {
    display: none;
  }

  .vertical__line2 {
    margin: 10px 0;
    height: 250px;
    border-left: 0.5px solid #acd2e5;
    opacity: 0.4;
  }
  .vertical__line {
    margin: 10px 0;
    height: 40px;
    border-left: 0.5px solid #acd2e5;
    opacity: $line-opacity;
  }
  .vertical_line_big {
    margin: 10px 0;
    height: 240px;
    border-left: 0.5px solid #acd2e5;
    opacity: $line-opacity;

    @media screen and (max-width: 550px) {
      display: none;
    }
  }
  .horizontal_line {
    width: 640px;
    margin: 0;
    border-top: 0.5px solid #acd2e5;
    opacity: $line-opacity;
  }
  .menu_left {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    ul {
      display: flex;
      flex-direction: column;
      margin: 0 0 0 2px;

      a {
        text-decoration: none;
      }
    }
  }
  .menu_enabled {
    position: relative;
    a {
      color: #335566 !important;
      cursor: pointer;
    }
    &:not(.menu_disabled):hover {
      background-color: #e6f7ff;
    }
  }

  .menu_disabled {
    &:hover {
      background-color: #fff;
    }
  }

  .menu_right {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    ul {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin: 0 2px 0 0;

      a {
        text-decoration: none;
      }
      li {
        text-decoration: none;
        margin-right: 10px;
        a {
          text-decoration: none;
          color: #abb0b2;
        }
      }
    }
  }
}

.menu_block_container_active {
  right: 18px;
  z-index: 10;
  opacity: 1;

  @media (max-width: 550px) {
    right: 0;
  }
}

.menu_close_icon_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-right: 5px;
  margin-bottom: 10px;
  img {
    cursor: pointer;
  }
}

.menu_disabled_panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.5;
  cursor: not-allowed;
}

.menu_enabled {
  display: flex;
  text-decoration: none;
  color: #335566 !important;
  cursor: pointer;

  @media (max-width: 700px) {
    padding: 0 40px;
  }

  a {
    color: #335566 !important;
    cursor: pointer;
  }
  &:hover {
    background-color: #e6f7ff;
  }
}

@media (max-width: 700px) {
  .menu_block_container_active {
    display: flex;
    justify-content: end;
    align-items: end;
    flex-direction: column;
    font-size: 14px;
  }
  .menu_block {
    width: 100%;
    height: 100%;
    min-width: 310px;
    flex-direction: column;
  }
  .menu_block_container {
    width: 90%;
    min-width: 90vw;
    flex-direction: column;

    @media (max-width: 550px) {
      min-width: 80vw;
    }

    .menu_header {
      flex-direction: column;
    }

    li {
      padding: 5px 0 5px 23px;
      width: 285px;
    }

    .menu_right {
      li {
        margin-left: 0 !important;
        margin-bottom: 5px;
      }
    }
  }

  .vertical__line2,
  .vertical__line,
  .horizontal__line {
    display: none;
  }

  li {
    text-decoration: none;
    margin-left: 0;
  }
}

@media (max-width: 350px) {
  .menu_block_container {
    width: 75%;
    li {
      text-decoration: none;
      a {
        font-size: 10px;
      }
    }

    .menu_header {
      li {
        width: 280px;
      }
    }
  }
}

@media (min-width: 990px) and (max-width: 1430px) {
  .menu_block_container_active {
    display: flex;
    justify-content: end;
    align-items: end;
    flex-direction: column;
    font-size: 14px;
  }
  .menu_block_container {
    width: 90%;
    flex-direction: column;

    .menu_header {
      flex-direction: column;
    }

    li {
      padding: 5px 0 5px 23px;
      width: 285px;
    }

    .menu_right {
      li {
        margin-left: 0 !important;
        margin-bottom: 5px;
      }
    }
  }

  .vertical_line2,
  .vertical_line,
  .horizontal_line {
    display: none;
  }

  li {
    text-decoration: none;
    margin-left: 0;
  }
}

@media (min-width: 701px) and (max-width: 755px) {
  .menu_block_container_active {
    display: flex;
    justify-content: end;
    align-items: end;
    flex-direction: column;
    font-size: 14px;
  }
  .menu_block_container {
    width: 90%;
    flex-direction: column;
    .menu_header {
      flex-direction: column;
    }
    li {
      padding: 5px 0 5px 23px;
      width: 285px;
    }
    .menu_right {
      li {
        margin-left: 0 !important;
        margin-bottom: 5px;
      }
    }
  }
  .vertical_line2,
  .vertical_line,
  .horizontal_line {
    display: none;
  }
  li {
    text-decoration: none;
    margin-left: 0;
  }
}
