$blue: #335566;
$lightblue: #007787;

.body {
  position: relative;
  opacity: 1;
  margin: 20px auto 0;
  background: #ffffff;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
  height: auto;
  width: 95vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;

  .header {
    background-color: $blue;
    border-radius: 5px 5px 0 0;
    padding: 13px 15px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    h5 {
      color: #fff;
      margin: 0;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
    }
    img {
      cursor: pointer;
    }
  }
  .content {
    padding: 20px 20px;
  }
  .content_padding_bottom_disabled{
    padding: 20px 20px;
    padding-bottom: 0px;
  }
  .buttons {
    padding: 0 20px 20px 20px;
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 10px;
    }
  }
}

@media (min-width: 992px) {
  .body {
    width: 600px;
  }
}

.loading_message {
  width: 335px;
}

@media (max-width: 600px) {
  .body {
    margin-bottom: 200px;
  }
  .header {
    h5 {
      max-width: 90%;
    }
    img {
      min-width: 20px;
    }
  }
}
