.container {
  position: relative;
  font-size: 14px;
  height: 100%;
  padding: 10px 0;

  .field_title {
    display: flex;
    justify-content: space-between;
    .name {
      color: #22273A;
    }
    .cancel {
      position: absolute;
      top: 10px;
      right: 0;
      cursor: pointer;
    }
  }
  .value {
    color: #777777;
    margin-left: 20px;
    word-break: break-word;
  }
  .edit_btn {
    display: none;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    background: #fff;
    box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
    border: none;
    position: absolute;
    right: 15px;
    top: 15px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    .edit_btn {
      display: block;
    }
  }

  .value_container {
    padding-left: 5px;
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 40px;
  }
}
.no_background {
  background-color: transparent !important;
}
