.expand_row {
  position: relative;
  background-color: #fff;
  margin-bottom: 10px;

  .expand_row_header {
    padding: 11px 25px;
    display: flex;
    font-size: 14px;
    color: #22273a;
    box-shadow: none;
    margin-bottom: 0;
  }
  .expand_row_content {
    overflow: hidden;
    background-color: #fff;
    cursor: default;
  }

  .expanded {
    background-color: #e5f7ff;
  }
}
