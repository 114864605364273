.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.left_side {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  height: 100%;
  width: 55%;

  .cost_list {
    margin-top: 55px;
    max-width: 240px;
  }
}

.right_side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-width: 40%;
  .link {
    display: flex;
    margin-left: auto;
    color: var(--blue, #356);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.28px;
    cursor: pointer;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }
}

.empty_data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-width: 220px;
  margin-top: 20px;
  span {
    margin-top: 20px;
    width: 60%;
    text-align: center;
    color: var(--grey-800, #2e3133);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.28px;
  }
}
