.container {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 5px;
  border-left: 5px solid #5f6466;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
  padding: 15px 20px;

  .value {
    font-size: 14px;
    &_secondary {
      color: #abafb3;
      font-size: 12px;
      margin-top: 3px;
    }
  }

  .menu_button_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background: #357;
    border: none;
  }

  .menu_button {
    display: flex;
    background-color: transparent;
    border: none;
  }

  .menu_button_container + .menu_button_container {
    margin-left: 10px;
  }

  .inactive {
    opacity: 0.5;
  }
}

.container + .container {
  margin-top: 10px;
}
