.footer {
  background-color: #102F3F;
  padding: 40px 20px;
  .wrapper {
    max-width: 1400px;
    margin: 0 auto;
    .container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      section {
        flex-basis: 25%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        img {
          height: 50px;
          width: 133px;
        }
        span {
          color: #ffffff;
          font-size: 16px;
          line-height: 30px;
        }
        a {
          font-size: 15px;
          width: 100%;
          color: #fff;
          text-decoration: none;
          transition: all .3s;
          cursor: pointer;
          &:hover {
            color: #ffbf00;
          }
        }
      }
      .list {
        margin-bottom: 30px;
        h6 {
          font-size: 16px;
          font-weight: 700;
          color: #fff;
          margin-bottom: 10px;
          letter-spacing: 1px;
        }
        .link_wrapper {
          margin-bottom: 20px;
          &:before {
            content: '→ ';
            color: #8FAFBF;
          }
        }
      }
      @media (min-width: 650px) {
        flex-direction: row;
      }
    }
    .copyright {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 50px;
      }
      p {
        font-size: 13px;
        color: #fff;
      }
      .impressum {
        margin-top: 15px;
        a {
          font-size: 12px;
          text-transform: uppercase;
          &:hover {
            text-decoration: underline;
          }
        }
        span {
          color: #666;
        }
      }
    }
    a {
      font-size: 15px;
      color: #fff;
      text-decoration: none;
      transition: all .3s;
      cursor: pointer;
      &:hover {
        color: #ffbf00;
      }
    }
  }
}
