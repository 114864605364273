$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

.product_header {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  font-weight: 600;
  display: inline-block;
  font-size: 20px;
  color: #000;

  @media only screen and (min-width: $lg) {
    width: 70%;
  }

  @media only screen and (min-width: $xl) {
    width: 80%;
  }
}
.comparison_btn {
  background-color: transparent !important;
  color: #335566;
  border: 2px solid #335566;
  border-radius: unset;

  &:hover,
  &:active,
  &:active:hover,
  &:active:focus {
    background-color: #335566 !important;
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.16);
  }
}
.buttons_container {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.product_feat {
  display: flex;
  padding: 8px 0;

  span {
    width: 50%;

    @media only screen and (min-width: 992px) {
      width: 40%;
    }
  }
  .product_feat_name {
    font-weight: normal;
  }
}
.product_smalltext {
  padding-top: 10px;
}
.product {
  padding: 10px;
  @media only screen and (min-width: 992px) {
    padding: 25px;
  }
}
.more {
  button {
    position: relative;
    @media (min-width: 992px) {
      transform: translateY(-15px);
    }
  }
}
.status {
  color: #333;
  margin: 15px 0 0;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  &:hover {
    text-overflow: unset;
    white-space: unset;
  }
}
.product_pricetag {
  max-width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 992px) {
    height: 330px;
  }
}
.buttons_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.eco_badge {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 2;
}

.new_uds_product {
  background-color: #fff;
  margin-bottom: 20px;
  padding: 25px;
  position: relative;

  .new_uds_content {
    display: flex;
    flex-direction: column;

    .new_uds_image_container {
      min-height: 100px;
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      img {
        align-self: center;
      }
    }
    .new_uds_description {
      width: 100%;
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h4 {
        font-size: 20px;
        color: #102f3f;
        margin-bottom: 30px;
      }
      @media (min-width: $lg) {
        padding: 0;
      }
    }
    .new_uds_price_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      min-height: 320px;

      .new_uds_price_section {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 20px;
        .new_uds_netto_price {
          font-weight: 600;
          font-size: 18px;
          color: #102f3f;
          margin-bottom: 5px;
        }
        .new_uds_brutto_price {
          font-size: 12px;
          color: #5f6466;
        }
      }

      .new_uds_info_section {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 30px;
      }

      .new_uds_buttons_wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .new_uds_buttons_container {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          width: 250px;
          .new_uds_offer_offer {
            width: 100%;
            max-width: 250px;
          }
        }
        .new_uds_dates {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }

    @media (min-width: $lg) {
      flex-direction: row;
      justify-content: space-between;
      .new_uds_image_container {
        width: 35%;
      }
      .new_uds_description {
        width: 35%;
        padding: 0 10px;
      }
      .new_uds_price_container {
        width: 30%;
      }
    }
  }
}

.new_uds_ordered {
  border-left: 5px solid #ff9700;
  border-radius: 5px 0 0 5px;
}

.new_uds_canceled {
  border-left: 5px solid #bf0040;
  border-radius: 5px 0 0 5px;
}

.new_uds_product_info {
  width: 100%;
  max-width: 400px;
  display: flex;
  font-size: 14px;
  margin-bottom: 15px;
  .new_uds_label {
    width: 50%;
    color: #171819;
  }
  .new_uds_value {
    width: 50%;
    color: #102f3f;
    font-weight: 600;
  }
}

.new_uds_info_item {
  display: flex;
  align-items: center;
  padding: 0 5px;
  background-color: #009383;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: #ffffff;
}

.down_arrow {
  transform: rotate(270deg);
  margin-left: 10px;
}

.date_info {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  .title,
  .date {
    font-size: 12px;
    color: #5f6466;
    margin-left: 10px;
  }
  .title {
    display: block;
    white-space: nowrap;
  }
}
