$tile-padding: 20px;
$green: #30bf30;
$red: #bf0040;

.tile_container {
  display: grid;
  grid-template-columns: repeat(24, minmax(20px, 1fr));
  grid-template-areas:
    'driver driver driver driver car car car car car car car car improve_data_quality improve_data_quality improve_data_quality improve_data_quality damage damage damage damage damage damage damage damage'
    'upcoming_tasks upcoming_tasks upcoming_tasks upcoming_tasks upcoming_tasks upcoming_tasks upcoming_tasks upcoming_tasks upcoming_tasks upcoming_tasks upcoming_tasks upcoming_tasks vehicle_costs vehicle_costs vehicle_costs vehicle_costs vehicle_costs vehicle_costs vehicle_costs vehicle_costs vehicle_costs vehicle_costs vehicle_costs vehicle_costs'
    'driver_license driver_license driver_license driver_license emissions emissions emissions emissions emissions emissions emissions emissions emissions consumtion consumtion consumtion consumtion consumtion consumtion consumtion consumtion consumtion consumtion consumtion';
  gap: 10px;
  padding: 10px 20px 20px;

  .card {
    background-color: #fff;
    border-radius: 5px;
  }
}

.row {
  display: flex;
  gap: 10px;
}

.row + .row {
  margin-bottom: 10px;
}

.description {
  margin-top: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #335566;
}

.icon {
  &_green {
    path {
      fill: $green;
    }
  }
  &_red {
    path {
      fill: $red;
    }
  }
}

.company_form {
  background-color: #fff;
  border-radius: 5px;
  padding: 5px 20px;
  margin: 10px 0 0;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tile {
  &_driver {
    grid-area: driver;
    cursor: pointer;
    color: #fff;
    background-color: #8fafbf !important;
    min-height: 250px;
  }
  &_car {
    grid-area: car;
    color: #8fafbf;
    padding: 0 !important;

    .children {
      width: 100%;
    }

    .multiply_continer {
      box-shadow: inset 8px 0px 15px rgba(0, 0, 0, 0.25);
    }

    .records_number_container {
      cursor: pointer;
      padding: $tile-padding;
      background-color: #102f3f;
    }

    .arrowClassName {
      path {
        fill: #8fafbf;
      }
    }
  }
  &_improve_data_quality {
    grid-area: improve_data_quality;
    min-height: 250px !important;
  }
  &_damage {
    grid-area: damage;
  }
  &_upcoming_tasks {
    grid-area: upcoming_tasks;
  }
  &_vehicle_costs {
    grid-area: vehicle_costs;
  }
  &_driver_license {
    grid-area: driver_license;
  }
  &_emissions {
    grid-area: emissions;
  }
  &_consumtion {
    grid-area: consumtion;
  }
}

.icon_with_pointer {
  cursor: pointer;
}

@media screen and (max-width: 1700px) {
  .tile_container {
    grid-template-areas:
      'driver driver driver driver driver car car car car car improve_data_quality improve_data_quality improve_data_quality improve_data_quality improve_data_quality damage damage damage damage damage damage damage damage damage'
      'upcoming_tasks upcoming_tasks upcoming_tasks upcoming_tasks upcoming_tasks upcoming_tasks upcoming_tasks upcoming_tasks upcoming_tasks upcoming_tasks upcoming_tasks upcoming_tasks vehicle_costs vehicle_costs vehicle_costs vehicle_costs vehicle_costs vehicle_costs vehicle_costs vehicle_costs vehicle_costs vehicle_costs vehicle_costs vehicle_costs'
      'driver_license driver_license driver_license driver_license emissions emissions emissions emissions emissions emissions emissions emissions emissions consumtion consumtion consumtion consumtion consumtion consumtion consumtion consumtion consumtion consumtion consumtion';
  }
}
