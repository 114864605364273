.block_view {
  // overflow: hidden;
  width: 440px;
  display: flex;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  // overflow: hidden;
  h5 {
    font-weight: 600;
    font-size: 18px;
    color: #335566;
  }
  .inputs_block {
    display: flex;
    flex-direction: row;
    margin-top: 60px;
    margin-bottom: 60px;
    width: 206px;
    height: 48px;
    border: 1px solid #acd2e5;
    border-radius: 2px;
    padding: 10px 10px 10px 10px;
    background-color: #fff;
    justify-content: space-between;
    font-size: 16px;
    align-items: center;
  }
  input {
    &:focus::-webkit-input-placeholder {
      color: transparent;
    }
  }
}

.mobexo_link {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #335566;
  position: absolute;
  bottom: -70px;
  a {
    color: #335566;
    text-decoration: underline !important;
  }
}

.input_first,
.input_second,
.input_third {
  border: none;
  height: 20px;
  letter-spacing: -1px;
  width: auto;
  text-align: center;
}

.input_first {
  max-width: 44px;
}

.input_second {
  max-width: 31px;
}

.input_third {
  max-width: 71px;
}

.search_icon {
  cursor: pointer;
}

.data_fields {
  display: flex;
  flex-direction: column;
  width: 440px;
  h6 {
    font-weight: 600;
    font-size: 14px;
    color: #102f3f;
    margin-bottom: 20px;
  }
  .driver_name {
    width: 100%;
    margin: 0;
    h6 {
      font-weight: 600;
      font-size: 14px;
      color: #102f3f;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.28px;
      color: #102f3f;
    }
  }
}

.form_name,
.form_name_err {
  margin-bottom: 5px;
  span {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.02em;
    padding: 0;
    color: #102f3f;
    .req {
      color: #bf0040;
    }
  }
}
.form_name_err {
  span {
    color: #bf0040 !important;
  }
}

.input_standart {
  width: 100%;
  border: 1px solid #acd2e5;
  border-radius: 2px;
  height: 38px;
  padding: 10px;
  font-size: 14px !important;
}

.double_field {
  display: flex;
  flex-direction: column;
  width: 58%;
  margin-right: 2%;
}

.one_field {
  display: flex;
  flex-direction: column;
  width: 38%;
  margin-left: 2%;
}

.row_columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 440px;
}

.input__icons,
.input__icons_date {
  width: 100%;
  height: 38px;
  border: 1px solid #acd2e5;
  border-radius: 2px;
  padding-left: 48px !important;
  background-repeat: no-repeat;
  background-position-x: 5px;
  background-position-y: 50%;
  padding-left: 0;
  font-size: 14px !important;
  &:focus {
    border: 1px solid #acd2e5;
  }
}

.input__icons_date {
  border-radius: 2px;
  background-image: url(/assets/images/date-blue-icon.svg);
  background-repeat: no-repeat;
  background-position-x: 5px;
  background-position-y: 50%;
  padding-left: 28px !important;
}

.optional {
  font-weight: 400;
  font-size: 14px;
  color: #919699 !important;
}

.button_block {
  display: flex;
  margin-top: 30px;
  .button_active,
  .button_wait {
    width: 440px;
    height: 42px;
    border: none;
    border-radius: 2px;
    font-size: 16px;
  }
  .button_wait {
    color: #fff;
    background-color: #c6cacc;
  }
}

.button_option_false,
.button_option_true {
  margin-top: 5px;
  width: 440px;
  height: 42px;
  border-radius: 2px;
  font-size: 16px;
}

.button_option_false {
  color: #102f3f;
  background-color: #fff;
  border: 1px solid #acd2e5;
  &:hover {
    box-shadow: 2px 4px 5px 0px rgba(172, 210, 229, 0.3);
  }
}

.button_option_true {
  color: #102f3f;
  background-color: #e5f7ff;
  border: none;
}

.half_input {
  width: 47.5%;
}

.button_back {
  width: 100%;
  color: #102f3f;
  background-color: #fff;
  border: 1px solid #acd2e5;
  height: 42px;
  border-radius: 2px;
  font-size: 16px;
  &:hover {
    box-shadow: 2px 4px 5px 0px rgba(172, 210, 229, 0.3);
  }
}

.button_active,
.button_wait {
  width: 100%;
  height: 42px;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  color: #fff;
}
.button_wait {
  background-color: #c6cacc;
}
.button_active {
  background-color: #335566;
  &:hover {
    box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.16);
  }
}

.err__row {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  max-width: 440px;
  img {
    margin-top: 3px;
    margin-right: 3px;
  }
  p {
    margin-top: 2px;
    color: #bf0040;
  }
}

.input__icons_err__tel {
  padding-left: 45px !important;
  font-size: 14px !important;
  border-color: #bf0040 !important;
  &:focus {
    padding-left: 40px;
    font-size: 14px !important;
    border: 1px solid #bf0040;
  }
}

.input__block {
  position: relative;
  .select__code {
    border: 0 solid black;
    background-image: none;
    border-right: 1px solid #abb0b2;
    border-radius: 0;
    width: 35px;
    font-size: 14px;
    -webkit-appearance: none;
    appearance: none;
    background-image: none;
  }
  .input__icons__drop {
    padding-left: 20px;
    border: 0.5px solid rgba(119, 124, 127, 0.5);
    border-radius: 2px;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(/assets/images/drop-down-blue-icon.svg);
    background-repeat: no-repeat;
    background-position-x: 5px;
    background-position-y: 50%;
    font-size: 14px;
  }
  .input__block__img {
    position: absolute;
    top: 15px;
    left: 8px;
    width: 35px;
    border-right: 1px solid #abb0b2;
  }
  .input__block__drop {
    position: absolute;
    top: 16px;
    left: 8px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  .input__icons {
    padding-left: 48px;
    font-size: 14px;
  }
  .input__icons__drop {
    padding-left: 26px;
    font-size: 14px;
  }
}

.hide__block {
  display: none;
}

.hide__block__show {
  cursor: pointer;
  opacity: 0.7;
  font-size: 14px;
}

.container_wrapper_enter {
  opacity: 0;
}
.container_wrapper_enter_active {
  opacity: 1;
  transition: opacity 1500ms ease-in-out;
}
.container_wrapper_exit {
  opacity: 1;
}
.container_wrapper_exit_active {
  opacity: 0;
  transition: opacity 1500ms ease-in-out;
}

.button_hide {
  display: none;
}

.full_input {
  width: 100%;
}

.mobile_column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 900px) {
  .container {
    margin-top: 35px;
    // min-height: 100vh;
  }
  .block_view {
    width: auto;
    max-width: 440px;
  }
  .row_columns,
  .data_fields,
  .button_option_false,
  .button_option_true {
    width: 100%;
    min-width: 100%;
  }
}
