.container {
  .placeholder_container {
    position: relative;
    width: 100%;
    height: 182px;
    cursor: pointer;

    &:hover .play_img {
      opacity: 1;
    }

    .title {
      position: absolute;
      top: 4px;
      left: 8px;
      z-index: 10;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
    }
    .play_img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 20;
      opacity: 0;
      transition: 0.3s;
    }
  }
}

.container + .container {
  margin-top: 10px;
}
