.dropdown_li {
  font-size: 14px;
  margin: 0;
  cursor: default;
  display: flex;
  height: 48px;
  &:hover {
    background-color: #e5f7ff;
    cursor: pointer;
  }
}

.item_text {
  padding: 5px;
  padding-left: 16px;
  align-self: center;
  color: #102f3f;
}
