.fahrzeugdokumente_card {
  padding: 15px 30px 15px 30px;
  border-bottom: 0.5px solid #eae9e9;
}

.fahrzeugdokumente_card__open {
  padding: 15px 30px 30px 30px;
  border-bottom: 0.5px solid #eae9e9;
}

.header {
  color: #335566;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.02em;
  padding-left: 33px;
}

.fahrzeugdokumente_card_content {
  div {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding-left: 10px;
  }

  .item_header {
    font-size: 12px;
    color: #777777;
  }

  .item_content {
    font-size: 14px;
    color: #22273a;
    word-break: break-all;
  }

  button {
    padding: 6px 20px;
    font-size: 14px;

    .button_img {
      margin-right: 14px;
    }
  }
}
.mehr_anzeigen_btn {
  height: 35px;
  line-height: 35px;
  background-color: #f5f5f5;
  color: #22273a;
  font-size: 14px;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.25);
  }
}
