.standard_equipment {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5px;

  .standard_equipment_container {
    padding: 25px 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .standard_equipment_item {
      font-size: 16px;
      color: #000;
      font-weight: bold;
      word-break: break-word;
    }
  }

  .subitem_arrow {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 10px;
    transition: all .6s ease;
  }

  .subitem_arrow_expand {
    transform: rotate(180deg);
  }
}

.price {
  width: 100%;
  max-width: 100px;
  white-space: nowrap;
  text-align: right;
}

.code {
  width: 100%;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.divider {
  border-top: 1px solid #b1b1b1;
  width: 100%;
}

@media (max-width: 600px) {
  .standard_equipment {
    width: 50%;
  }
}

@media (max-width: 470px) {
  .standard_equipment {
    width: 100%;
  }
}
