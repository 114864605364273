.form {
  display: flex;
  margin: 0 -5px;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  .form_column,
  .form_column_double {
    padding: 5px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    .form_field {
      position: relative;
      display: flex;
      flex-direction: column;
      margin: 15px 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .field_prefix {
        position: relative;
        padding: 10px;
        min-width: 42px;
        text-align: center;
        background-color: #f5f5f5;
        border: 1px solid #777;
        border-radius: 2px;
        border-right: none;
        display: flex;
        align-items: center;
        img {
          position: absolute;
          right: 11px;
        }
      }

      .info_note {
        font-size: 8px;
        color: #777c7f;
      }

      .error_message {
        display: flex;
        align-items: flex-start;
        color: #bf0040;
        padding-top: 5px;
        font-size: 12px;
        img {
          margin-top: 1.5px;
          margin-right: 3px;
        }
      }
      .warning_message {
        display: flex;
        align-items: flex-start;
        color: #ff9700;
        padding-top: 5px;
        font-size: 12px;
        img {
          margin-top: 1.5px;
          margin-right: 3px;
        }
      }
      .form_input {
        min-height: 43px;
        width: 100%;
        padding: 10px 15px;
        border: 1px solid #777;
        border-radius: 2px;
        color: #000;
        font-size: 14px;
        &::placeholder {
          color: #777;
        }
        &::-moz-placeholder {
          color: #777;
          opacity: 1;
        }
        &::-ms-input-placeholder {
          color: #000;
        }
      }
      select.form_input {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-image: url('../../assets/images/dashboard-expand.svg');
        background-repeat: no-repeat;
        background-position-x: calc(100% - 10px);
        background-position-y: 50%;
        padding-right: 25px;

        option {
          color: #000
        }
      }
      .disabled_select {
        color: #797d7f;
      }
      .input_container {
        display: flex;
        min-height: 45px;
      }
      .label {
        font-size: 14px;
        color: #102f3f;
        margin-bottom: 5px;
        word-break: break-word;
        min-height: 20px;
        display: flex;
        align-items: center;
      }
    }
    .range_field {
      display: block;
      h4 {
        margin: 0 0 10px;
        color: #22273a;
        text-align: right;
        font-size: 16px;
      }
      p {
        font-size: 14px;
        color: #000;
      }
      .form_input {
        padding: 0;
        -webkit-appearance: none;
        border: none;
      }
      .form_input::-webkit-slider-runnable-track {
        height: 6px;
        cursor: pointer;
        border-radius: 2px;
        background: #335566;
      }
      .form_input::-webkit-slider-thumb {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #f7ad00;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -6px;
      }
      .form_input:focus::-webkit-slider-runnable-track {
        background: #335566;
      }
      .form_input::-moz-range-track {
        height: 6px;
        cursor: pointer;
        border-radius: 2px;
        background: #335566;
      }
      .form_input::-moz-range-thumb {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #f7ad00;
        cursor: pointer;
        margin-top: -6px;
      }
      .form_input::-ms-track {
        height: 6px;
        cursor: pointer;
        border-radius: 2px;
        background: #335566;
      }
      .form_input::-ms-thumb {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #f7ad00;
        margin-top: -2px;
        cursor: pointer;
      }
    }
    .form_textarea {
      padding: 10px 15px;
      border: 1px solid #777;
      border-radius: 2px;
      color: #000;
      font-size: 14px;
    }

    .checkbox_container {
      display: flex;
      align-items: flex-start;
      margin: 10px 0;

      .error {
        border: 1px solid #bf0040 !important;
      }

      .form_checkbox_input + .form_checkbox_shell_input {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        border: 2px solid #335566;
        border-radius: 2px;
        cursor: pointer;
      }

      .form_checkbox_input:checked + .form_checkbox_shell_input {
        background-color: #335566;
      }

      .form_checkbox_input:checked:disabled + .form_checkbox_shell_input {
        background-color: #c6cacc;
      }

      .form_checkbox_input:checked + .form_checkbox_shell_input::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: url('../../assets/images/checkbox.svg');
        color: #fff;
        font-size: 14px;
      }

      .form_checkbox_input:checked:disabled + .form_checkbox_shell_input::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: url('../../assets/images/checkbox_disabled.svg');
        color: #fff;
        font-size: 14px;
      }

      .form_checkbox_input {
        opacity: 0;
        margin: 0;
        z-index: 1000;
      }

      .checkbox_wrapper {
        position: relative;
        margin: 0 7px 0 3px;
      }

      .label {
        margin: 2px 0 0 0;
        line-height: 1;
      }
    }

    .checkbox_names {
      position: absolute;
      top: 40px;
      left: 0;
      width: 100%;
      background-color: #fff;

      .checkbox_names_container {
        border: 1px solid #777777;
        padding: 7px 10px;
        max-height: 250px;
        overflow-y: auto;
        overflow-x: hidden;
      }

      .checkbox_name_wrapper {
        position: relative;
      }

      .checkbox_name {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        cursor: pointer;
      }

      .form_checkbox_name_input {
        position: relative;
        border: 2 px solid #335566;
        margin: 0;
        width: 100%;
        height: 100%;

        &:focus {
          outline: none !important;
        }
      }

      .form_checkbox_name_value {
        text-align: left;
        width: 80%;
        margin-left: 10px;
      }

      .form_checkbox_name_input + .form_checkbox_name_shell_input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: inline-block;
        border-radius: 2px;
        cursor: pointer;
        margin: 0;
      }

      .form_checkbox_name_input:checked + .form_checkbox_name_shell_input {
        background-color: #335566;
      }

      .form_checkbox_name_input:checked + .form_checkbox_name_shell_input::after {
        content: url('../../assets/images/checkbox.svg');
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 14px;
      }
    }

    .load_more_btn {
      display: block;
      padding: 7px;
      color: #000;
      cursor: pointer;
      text-align: center;

      &:hover {
        background-color: #f1f1f1;
      }
    }

    .placeholder_container {
      position: relative;
      display: flex;
      border: 0.5px solid #777777;
      padding: 7px 10px;
      min-height: 35px;
      align-items: center;

      .placeholder {
        position: static;
        color: #777777;
        white-space: nowrap;
        overflow: hidden;
        width: auto;
        max-width: 80%;
        text-overflow: ellipsis;
        margin-left: 10px;

        &_active {
          margin-left: 10px;
          text-align: center;
        }
      }

      .delete_button {
        display: block;
        position: relative;
        width: 18px;
        height: 18px;
        background-color: #335566;
        border-radius: 2px;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #fff;
          border-radius: 100%;
          width: 12px;
          height: 2px;
          z-index: 2;
        }
      }

      .placeholder {
        position: static;
        color: #777777;
        white-space: nowrap;
        overflow: hidden;
        width: auto;
        max-width: 80%;
        text-overflow: ellipsis;
        margin-left: 10px;

        &_active {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          margin-left: 0;
          width: 100%;
          text-align: center;
        }
      }
    }

    .toggle_field {
      flex-direction: row;
      margin-top: 10px !important;
      .label_wrapper {
        width: 40%;
        display: flex;
        align-items: center;
        line-height: 18px;
      }
      .label {
        width: 40%;
      }
      .toggle_value {
        margin-left: 10px;
      }
    }
    .toggleWithTooltip_field {
      display: flex;
      .label_wrapper {
        width: 100%;
        margin-left: 10px !important;
        color: #102f3f;
      }
    }
    .tooltip_description {
      top: 25px;
    }
    .error {
      .form_input,
      .form_textarea {
        border: 1px solid #bf0040 !important;
      }
    }
    .divider {
      border-bottom: 1px solid #777;
    }
    .required_field {
      color: #bf0040;
    }
    .unrequired_field {
      color: #919699;
    }

    .radio_field {
      .radio_options {
        .radio {
          display: flex;
          margin-top: 13px;
          input[type='radio'] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            height: 24px;
            width: 24px;
            margin: 0;
            cursor: pointer;
            margin-right: 10px;
            vertical-align: middle;
            accent-color: #335566;
            border: 1px solid #abb0b2;
            border-radius: 50%;
            &:after {
              -webkit-appearance: radio;
              -moz-appearance: radio;
              appearance: radio;
              border: 1px solid #335566;
              color: #335566;
              accent-color: #335566;
            }
          }
          .input_disabled {
            border: 1px solid #abb0b26b !important;
          }
          label {
            padding-top: 3px;
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: #000;
          }
          input[type='radio']:checked {
            appearance: radio;
            -webkit-appearance: radio;
            display: flex;
            height: 24px;
            width: 24px;
            margin: 0;
            margin-right: 10px;
            vertical-align: middle;
            accent-color: #335566;
            border: 1px solid #abb0b2;
            border-radius: 50%;
          }
          input[type='radio'] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            height: 24px;
            width: 24px;
            margin: 0;
            margin-right: 10px;
            vertical-align: middle;
            accent-color: #335566;
            border: 1px solid #abb0b2;
            border-radius: 50%;
          }

          .radio_err {
            color: #bf0040;
            border: 1px solid #bf0040;
          }
          .radio_disabled {
            color: #919699;
          }
        }
      }
      .radio_options_in_row {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        div {
          width: 25%;
          .radio {
            width: 100%;
          }
        }

      }
    }

  }
  .form_column_double {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .form_field {
      margin: 0;
      width: 48%;
    }
  }

  .form_column {
    @media (max-width: 992px) {
      width: 50% !important;
    }
    @media (max-width: 590px) {
      width: 100% !important;
    }
  }
}

.field_link {
  color: #335566;
  margin-left: auto;
}
