.car_types_container {
  display: flex;
  justify-content: space-around;

  button {
    height: 69px !important;
  }
}

.m {
  margin-bottom: 10px;
  @media (min-width: 992px) {
    margin-bottom: 0px;
  }
}

.mg_r {
  @media (min-width: 1200px) {
    margin-right: -5px;
  }
}

.sorting_container {
  display: flex;
  flex-direction: column;
  // padding-left: 15px;
  // padding-right: 15px;

  @media (min-width: 992px) {
    flex-direction: row;
    margin-bottom: 3px;
  }

  .car_state {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 992px) {
      flex-direction: row;
      // margin-left: 6px;
      width: 50%;
    }
  }
}

.sidebar_container {
  position: relative;
  z-index: 10000;
  top: 0;
  // right: -320px;
  width: 320px;
  max-width: calc(100vw - 50px);
  height: 100%;
  display: block;
  overflow-y: auto;
  // transition: .3s;
  background: #fff;
}

.header {
  background-color: #fff;
  align-items: center;
  z-index: 1000;
  position: absolute;
  height: 50px;
  right: 0;
  top: 0;
  width: 100%;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-right: 20px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
  box-shadow: rgba(0, 0, 0, 0.75) 0px 2px 3px -2px;
}

.filter {
  font-size: 16px;
  position: absolute;
  color: #00364b;
  font-weight: 600;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.body {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 60px;
  margin-bottom: 85px;
  overflow: auto;
  height: calc(100% - 150px);
}

.footer {
  position: absolute;
  background-color: #fff;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 75px;
  box-shadow: rgba(0, 0, 0, 0.75) 0 2px 3px 2px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .btn_primary,
  .btn_outlined {
    width: 40%;
    padding: 10px 12px;
  }

  $red: #ff4c4a;
  $white: #fff;
  .btn_primary {
    display: block;
    margin: auto;
    background-color: #335566;
    color: $white;

    &:hover {
      background-color: #007c8d;
    }
  }

  .btn_outlined {
    display: block;
    margin: auto;
    background-color: $white;
    color: #335566;
    border-color: #335566;
  }
}
