.table_row {
  text-decoration: none;
  background-color: #fff;
  padding: 11px 25px;
  margin-bottom: 10px;
  display: flex;
  font-size: 14px;
  color: #22273a;
  position: relative;
  transition: 0.3s;
}

