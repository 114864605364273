.settings_modal {
  width: 500px;
  margin: 20vh auto;
}

.replacement_column {
  min-width: 300px;
}

.table_wrapper {
  height: var(--containerHeight) !important;
  margin: 0 !important;
}

.pagination_wrapper {
  margin: 10px 0 0 0;
}

.tooltip {
  position: unset;
}

.tooltip_description {
  font-size: 14px;
  color: #000;
  background-color: #fff;
  width: 300px;
  padding: 20px;
}

.tooltip_title {
  font-size: 14px;
  display: flex;
  font-family: 'Open Sans';
}

.tooltip_close_icon {
  top: 0;
}

.filters {
  margin-top: 0.25rem;
}

@media screen and (max-height: 780px) {
  .settings_modal {
    margin: 10vh auto;
  }
}
