.container {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1024px) {
  .container {
    margin: 0 auto 30px;
  }
}

.driving_license_control {
  background-color: #f5f5f5;
  padding: 30px 30px 100px;
  height: auto;
  min-height: calc(100% - 36px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .driving_license_control_wrapper {
    width: 100%;
    max-width: 1600px;
  }

  figure {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    margin-right: 20px;
    cursor: pointer;
  }

  figure:hover {
    background: #fff;
    box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
  }

  .filters_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 35px 0 25px;
    .filters {
      margin-top: 4px;
    }
    .button_wrapper {
      margin-bottom: 8px;
    }
  }
  .margin_top {
    margin-top: 23px;
  }
  .rotate {
    transform: rotate(180deg);
  }
}

.header_item {
  position: relative;
  cursor: pointer;
}

.sort_arrow {
  position: absolute;
  top: 5px;
  left: -15px;
  display: block;
  transform: rotate(180deg);
  transition: 0.3s;
}

.reverse {
  transform: rotate(0);
}

.progress_diagram_wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
}

.progress_diagram_left {
  display: flex;
  gap: 15px;
}

.big_filter_container {
  flex: 1;
}

@media screen and (max-width: 550px) {
  .press_to_right_side {
    margin-left: 0;
  }
  .big_filter_container {
    width: 100%;
  }
}
