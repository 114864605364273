.container {
  color: #2e3133;
  font-size: 20px;
  font-weight: bold;
  background-color: #fff;
  padding: 20px;

  & + .container {
    margin-top: 10px;
  }

  .bottom_value {
    font-size: 14px;
    margin-top: 3px;
    font-weight: normal;
  }
}
