.overlay_wrapper {
  position: relative;
  height: calc(100% - 60px);

  .breadscrums_wrapper {
    height: 100%;
    background-color: #f5f5f5;
    scroll-behavior: smooth;
  }
}
