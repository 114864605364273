@import '../../assets/variables';

.item {
  padding: 0;
  margin: 25px 0 10px;
  list-style: none;

  .sum {
    color: $green;
    font-size: 19px;
    span {
      display: block;
      margin-left: auto;
    }
  }

  li {
    margin: 0;
    padding: 10px 0 5px;
    display: flex;
    justify-content: space-between;

    .label {
      color: #999;
      padding-left: 4px;
      display: flex;
      align-items: center;

      img {
        //display: block;
        display: inline-block;
        height: 20px;
        margin-right: 10px;
      }
    }

    .label_main {
      font-weight: bold;
      color: #497e8a;

      img {
        //display: block;
        display: inline-block;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
}

.border_top {
  border-top: 2px solid $red;
}

.contact_btn {
  padding: 10px 12px;
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  background-color: #335566;
  letter-spacing: 0.02em;
  text-transform: none;
  border-radius: 5px !important;

  &:hover {
    background-color: #006575;
  }

  // @media (min-width:992px) and (max-width: 1199px){
  //   font-size: 14px;
  // }
}
// .contact_btn_yellow {
//   background-color: #F9A703;
//   &:hover{
//     background-color: #ffad03;
//   }
// }
.contact_btn_transparentBG {
  margin: 10px 0;
  color: #335566;
  font-weight: bold;
  background-color: #fff;
  border: 2px solid #335566;
  transition: box-shadow 0.3s;
  &:hover {
    color: #335566;
    background-color: #fff;
    border: 2px solid #335566;
    box-shadow: 2px 4px 9px rgba(0, 0, 0, 0.3);
  }
}

.product_price {
  font-size: 24px;
  color: $green;
  margin-bottom: 0;

  .price {
    font-size: 30px;
    font-weight: 600;
  }
}
.product_price_netto {
  font-size: 18px;
  color: #999;
  margin-bottom: 10px;
}
.selectWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 10px 0;

  .optionSetContainer {
    max-width: 200px;
  }
}
.requestVariantText {
  font-size: 18px !important;
  font-weight: bold;
  color: #95c11f;
}

.header {
  text-align: left;
  margin-top: 30px;
  color: #000;
  font-size: 22px;
  margin-bottom: 0;
}

.flatrate_section {
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
}
.pricebox {
  padding: 30px !important;
}
.status {
  color: #000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  &:hover {
    text-overflow: unset;
    white-space: unset;
  }
}
