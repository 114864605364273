.price_section_small {
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
}
.product_container {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 30px;
  height: auto;
  @media (min-width: 768px) {
    height: 550px;
  }
  @media (min-width: 992px) {
    height: 500px;
  }
  @media (min-width: 1200px) {
    height: 550px;
  }
  @media (min-width: 1200px) {
    height: 570px;
  }
}
.car_container {
  display: flex;
  width: 100%;

  @media (max-width: 994px) {
    flex-direction: column;
  }
}
.details_wrapper {
  background-color: #f5f5f5;
  padding: 30px 0;
  height: auto;
  min-height: calc(100% - 36px);
}
.car_number {
  font-size: 14px;
  color: #777777;
}
.main_fields_section {
  flex-basis: 50%;

  &:first-child {
    margin-right: 10px;
  }
}
.gallery_container {
  position: relative;
  min-height: 250px;
  width: 60%;

  @media (max-width: 993px) {
    width: 100%;
  }
}
.item_container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px -1px;
}
.section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  cursor: pointer;
  h5 {
    margin: 0;
    font-size: 16px;
    line-height: 18px;
    color: #335566;
  }
  .rotate {
    transform: rotate(180deg);
  }
}
.section_header_bottom_line {
  border-bottom: 2px solid #c8cacc;
  h5 {
    color: #22273a;
  }
}

.details_container {
  display: flex;
  flex-direction: column-reverse;
  margin: 0 auto;

  .main_content {
    order: 1;
    width: 100%;
    margin-right: 30px;
  }

  .widgets {
    order: 0;
    width: 100%;
    margin-bottom: 30px;
  }

  @media (min-width: 1400px) {
    flex-direction: row;
    justify-content: center;
    .main_content {
      order: 0;
      width: 70%;
    }
    .widgets {
      order: 1;
      width: 30%;
    }
  }
}

.divider {
  margin: 0 -15px;

  @media (min-width: 600px) {
    margin: 0 -30px;
  }
}

.details_wrapper_modal_view {
  background-color: transparent;
  padding: 0;
  .details_container {
    .main_content {
      width: 100%;
      margin: 0;
    }
  }
}

.panel_placeholder {
  padding: 20px 0;
}

.fields_container {
  width: 40%;

  @media (max-width: 993px) {
    width: 100%;
  }
}

.fields_section {
  display: flex;
}

.full_width {
  max-width: 1200px;
  width: 100% !important;
  margin: 0 !important;
}
