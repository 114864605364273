.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  h5 {
    font-weight: 600;
    font-size: 18px;
    color: #335566;
  }
  .inputs_block {
    display: flex;
    flex-direction: row;
    margin-top: 60px;
    margin-bottom: 60px;
    width: 206px;
    height: 48px;
    border: 1px solid #acd2e5;
    border-radius: 2px;
    padding: 10px 10px 10px 10px;
    background-color: #fff;
    justify-content: space-between;
    font-size: 16px;
    align-items: center;
  }
  input {
    &:focus::-webkit-input-placeholder {
      color: transparent;
    }
  }
}

.field_container,
.field_container_textarea {
  width: 440px;
}

.field_container_textarea {
  position: relative;
}

.placeholer {
  position: absolute;
  z-index: 95;
  top: 84px;
  right: 0;
  color: #abb0b2;
  font-size: 10px;
  margin-right: 10px;
}

.mobexo_link {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #335566;
  position: absolute;
  bottom: -70px;
  a {
    color: #335566;
    text-decoration: underline !important;
  }
}

.input_first,
.input_second,
.input_third {
  border: none;
  height: 20px;
  letter-spacing: -1px;
  width: auto;
  text-align: center;
}

.input_first {
  max-width: 44px;
}

.input_second {
  max-width: 31px;
}

.input_third {
  max-width: 71px;
}

.search_icon {
  cursor: pointer;
}

.data_fields {
  display: flex;
  flex-direction: column;
  width: 440px;
  h6 {
    font-weight: 600;
    font-size: 14px;
    color: #102f3f;
    margin-bottom: 20px;
  }
}

.form_name {
  span {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.02em;
    padding: 0;
    color: #102f3f;
    width: 100%;
    .req {
      color: #bf0040;
    }
  }
}

.input_standart,
.input_standart_block {
  width: 100%;
  max-width: 440px;
  border: 1px solid #acd2e5;
  border-radius: 2px;
  height: 38px;
  padding: 10px;
  font-size: 14px !important;
}

.input_standart_block,
.input__icons_block {
  border-color: rgba(198, 202, 204, 1) !important;
  color: rgba(224, 227, 229, 1);
  cursor: not-allowed;
  &:focus::-webkit-input-placeholder {
    color: rgba(224, 227, 229, 1) !important;
  }
}

.input_standart_block::placeholder,
.input__icons_block::placeholder {
  color: #abb0b2;
}

.double_field {
  display: flex;
  flex-direction: column;
  width: 58%;
}

.one_field {
  display: flex;
  flex-direction: column;
  width: 38%;
}

.row_columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 440px;
}

.input__icons,
.input__icons_date,
.input__icons_block {
  width: 100%;
  max-width: 440px;
  margin-top: 5px;
  height: 38px;
  border: 1px solid #acd2e5;
  border-radius: 2px;
  padding-left: 48px !important;
  background-repeat: no-repeat;
  background-position-x: 5px;
  background-position-y: 50%;
  padding-left: 0;
  font-size: 14px !important;
  margin-top: 0;
  &:focus {
    border: 1px solid #acd2e5;
  }
}

.input__icons_date,
.input__icons_err__date {
  border-radius: 2px;
  background-image: url(/assets/images/date-blue-icon.svg);
  background-repeat: no-repeat;
  background-position-x: 5px;
  background-position-y: 50%;
  padding-left: 28px !important;
}

.optional {
  font-weight: 400;
  font-size: 14px;
  color: #919699 !important;
}

.button_block {
  display: flex;
  margin-top: 30px;
  .button_active,
  .button_wait {
    width: 440px;
    height: 42px;
    border: none;
    border-radius: 2px;
    font-size: 16px;
  }
  .button_wait {
    color: #fff;
    background-color: #c6cacc;
  }
}

.button_option_false,
.button_option_true {
  margin-top: 5px;
  width: 440px;
  height: 42px;
  border-radius: 2px;
  font-size: 16px;
}

.button_option_false {
  color: #102f3f;
  background-color: #fff;
  border: 1px solid #acd2e5;
  &:hover {
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.16);
  }
}

.button_option_true {
  color: #102f3f;
  background-color: #e5f7ff;
  border: none;
}

.half_input {
  width: 47.5%;
}

.button_back {
  width: 100%;
  color: #102f3f;
  background-color: #fff;
  border: 1px solid #acd2e5;
  height: 42px;
  border-radius: 2px;
  font-size: 16px;
}

.button_active,
.button_wait {
  width: 100%;
  height: 42px;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  color: #fff;
}
.button_wait {
  background-color: #c6cacc;
}
.button_active {
  background-color: #335566;
  &:hover {
    box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.16);
  }
}

.err__row {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  max-width: 440px;
  img {
    margin-top: 3px;
    margin-right: 3px;
  }
  p {
    margin-top: 2px;
    color: #bf0040;
  }
}

.input__icons_err__tel {
  padding-left: 45px !important;
  font-size: 14px !important;
  border-color: #bf0040 !important;
  &:focus {
    padding-left: 40px;
    font-size: 14px !important;
    border: 1px solid #bf0040;
  }
}

.input__block {
  position: relative;
  .select__code {
    border: 0 solid black;
    background-image: none;
    border-right: 1px solid #abb0b2;
    border-radius: 0;
    width: 35px;
    font-size: 14px;
    -webkit-appearance: none;
    appearance: none;
    background-image: none;
  }
  .input__icons__drop {
    padding-left: 20px;
    border: 0.5px solid rgba(119, 124, 127, 0.5);
    border-radius: 2px;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(/assets/images/drop-down-blue-icon.svg);
    background-repeat: no-repeat;
    background-position-x: 5px;
    background-position-y: 50%;
    font-size: 14px;
  }
  .input__block__img {
    position: absolute;
    top: 9px;
    left: 8px;
    width: 35px;
    border-right: 1px solid #abb0b2;
  }
  .input__block__drop {
    position: absolute;
    top: 16px;
    left: 8px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  .input__icons {
    padding-left: 48px;
    font-size: 14px;
  }
  .input__icons__drop {
    padding-left: 26px;
    font-size: 14px;
  }
}

.hide__block {
  display: none;
}

.hide__block__show {
  cursor: pointer;
  opacity: 0.7;
  margin-top: 10px;
  font-size: 14px;
}

.row_columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 440px;
  width: 100%;
}

.button_block {
  display: flex;
  margin-top: 30px;
  margin-bottom: 50px;
  .button_active,
  .button_wait {
    width: 440px;
    height: 42px;
    border: none;
    border-radius: 2px;
    font-size: 16px;
  }
  .button_wait {
    color: #fff;
    background-color: #c6cacc;
  }
}

.button_option_false,
.button_option_true {
  margin-top: 5px;
  width: 440px;
  height: 42px;
  border-radius: 2px;
  font-size: 16px;
  color: #102f3f;
}

.button_option_false {
  background-color: #fff;
  border: 1px solid #acd2e5;
  &:hover {
    box-shadow: 2px 4px 5px rgba(172, 210, 229, 0.3);
  }
}

.button_option_true {
  background-color: #e5f7ff;
  border: none;
}

.half_input {
  width: 47.5%;
}

.button_back {
  width: 100%;
  color: #102f3f;
  background-color: #fff;
  border: 1px solid #acd2e5;
  height: 42px;
  border-radius: 2px;
  font-size: 16px;
  &:hover {
    box-shadow: 2px 4px 5px rgba(172, 210, 229, 0.3);
  }
}

.button_active,
.button_wait {
  width: 100%;
  height: 42px;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  color: #fff;
  margin-bottom: 50px;
}
.button_wait {
  background-color: #c6cacc;
}
.button_active {
  background-color: #335566;
  &:hover {
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.16);
  }
}

.button_half,
.button_half_choosen {
  width: 100%;
  color: #102f3f;
  background-color: #fff;
  border: 1px solid #acd2e5;
  height: 42px;
  border-radius: 2px;
  font-size: 16px;
}

.button_half {
  &:hover {
    box-shadow: 2px 4px 5px rgba(172, 210, 229, 0.3);
  }
}

.button_half_choosen {
  background-color: #e5f7ff;
  border: none;
}

.form_name,
.form_name_button,
.form_name_text,
.form_name_button,
.form_name_button_error,
.form_name_center,
.form_name_block,
.form_name_text_block,
.form_name_tooltip,
.upload_name {
  margin-right: auto;
  margin-bottom: 5px;
  span {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.02em;
    padding: 0;
    color: #102f3f;
    cursor: default;
    .req {
      color: #bf0040;
    }
  }
}

.form_name_button_tooltip,
.form_name_button_error_tooltip,
.form_name_tooltip,
.form_name_row {
  span {
    font-size: 14px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
  }
}

.form_name_row {
  display: flex;
  flex-direction: row;
}

.form_name_button_error,
.form_name_button_error_tooltip {
  span {
    color: #bf0040;
  }
}

.form_name_block,
.form_name_text_block {
  height: 18px;
  span {
    height: 18px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.02em;
    padding: 0;
    color: rgba(198, 202, 204, 1);
    cursor: not-allowed;
  }
}

.form_name_button_tooltip,
.form_name_button_error_tooltip,
.form_name_button,
.form_name_button_error {
  margin-bottom: 10px;
}

.input__icons_err__tel,
.input__icons_err__text,
.input__icons_err__date,
.input__icons_err__textarea {
  width: 100%;
  max-width: 440px;
  margin-top: 5px;
  height: 38px;
  border: 1px solid #bf0040;
  border-radius: 2px;
  padding-left: 48px !important;
  background-repeat: no-repeat;
  background-position-x: 5px;
  background-position-y: 50%;
  padding-left: 0;
  font-size: 14px !important;
  margin-top: 0;
  &:focus {
    padding-left: 40px;
    font-size: 14px !important;
    border: 1px solid #bf0040;
  }
}

.input__icons_err__text {
  padding-left: 10px !important;
}

.input__icons_err__date {
  padding-left: 28px !important;
}

.input__icons_err__textarea {
  padding-left: 5px !important;
  border: 1px solid #bf0040 !important;
}

.info_block_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 440px;
  .info_logo {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-top: 2px;
  }
  .info_text {
    color: #919699;
    font-weight: 400;
    font-size: 14px;
  }
}

.close_block {
  cursor: pointer;
  margin-left: auto;
  height: 15px;
}

.tooltip {
  margin: 1px auto -7px 5px;
}

.tooltip_name {
  display: flex;
  flex-direction: row;
}

@media (max-width: 900px) {
  .field_container,
  .field_container_textarea {
    width: 100%;
  }
  .row_columns {
    min-width: auto;
    width: 100%;
  }
  .data_fields,
  .button_option_false,
  .button_option_true {
    width: 100%;
  }
  .input__block {
    .input__block__img {
      top: 7px;
      width: 42px;
      background: #fff;
      select {
        width: 42px;
        background: #fff;
      }
    }
  }
  .input__icons,
  .input__icons_block,
  .input__icons_err__tel {
    padding-left: 55px !important;
  }
  .tooltip_name {
    p {
      max-width: 73%;
    }
  }
}

@media (min-width: 450px) and (max-width: 900px) {
  .tooltip_name {
    p {
      max-width: 90%;
    }
  }
}

@media (min-width: 350px) and (max-width: 389px) {
  .tooltip_name {
    p {
      max-width: 83%;
    }
  }
}

@media (min-width: 390px) and (max-width: 405px) {
  .tooltip_name {
    p {
      max-width: 80%;
    }
  }
}

@media (min-width: 406px) and (max-width: 420px) {
  .tooltip_name {
    p {
      max-width: 77%;
    }
  }
}
