.full_content,
.full_content_overlay {
  position: relative;
  padding-top: 45px;
  padding-bottom: 40px;
  height: calc(100% - 100px);
  background-color: #fff;
  overflow: hidden scroll;
}

.full_content_overlay {
  overflow-y: scroll;
  overflow-x: auto;
}

.form {
  display: flex;
  justify-content: center;
}

.container_style,
.container_success {
  min-height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.container_success {
  max-width: 1140px;
}

@media (max-width: 1500) {
  .full_content {
    position: relative;
    padding-top: 45px;
    padding-bottom: 40px;
    height: 100%;
    background-color: #fff;
    overflow-x: auto;
  }
}

.header {
  display: flex;
  justify-content: center;
  background-color: #002633;
  height: 80px !important;
  .container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .save_button {
    display: flex;
    align-self: center;
    cursor: pointer;
  }
  .save_button span {
    color: #fff;
    margin-right: 10px;
  }
  .logo_container {
    display: flex;
    align-items: center;
  }
  .custom_logo {
    width: 180px;
    height: 50px;
    object-fit: contain;
    object-position: left;
    margin: auto 0;
  }
  .logo_container .logo_text {
    color: #fff;
    font-size: 19px;
    text-transform: uppercase;
    letter-spacing: 0.7px;
    margin-left: 15px;
  }
  .logo_container .logo_text span {
    font-weight: 400;
  }
}

.side_block {
  position: absolute;
  right: 0;
  top: -45px;
  height: calc(100% + 90px);
}

.save_block {
  position: sticky;
  top: -45px;
}

.side_save_block_hide {
  height: 100%;
  width: 0;
  transition: all 500ms ease-in-out;
  transform: translateX(100%);
  visibility: hidden;
}

.side_save_block_show {
  position: relative;
  width: 360px;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 34px 80px rgba(143, 175, 191, 0.25), 0px 7.59435px 17.869px rgba(143, 175, 191, 0.17),
    0px 2.26103px 5.32008px rgba(51, 86, 102, 0.07);
  z-index: 7777;
  transition: all 500ms ease-in-out;
  animation-duration: 500ms;
  animation-name: show;
}

@keyframes show {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.text_block {
  width: 300px;
  height: 190px;
  display: flex;
  flex-direction: column;
  margin: 0 30px 100px;
  .close_block {
    margin: 30px 0 30px auto;
    cursor: pointer;
  }
  .info_text {
    h6 {
      color: #102f3f;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 20px;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #102f3f;
    }
  }
}

.save_buttons {
  display: flex;
  flex-direction: row;
  width: 300px;
  margin: 0 30px;
  justify-content: space-between;
  .close_button {
    width: 28%;
    color: #102f3f;
    background-color: #fff;
    border: 1px solid #acd2e5;
    height: 42px;
    border-radius: 2px;
    font-size: 16px;
    &:hover {
      box-shadow: 2px 4px 5px rgba(172, 210, 229, 0.3);
    }
  }
  .save_data {
    width: 66%;
    color: #fff;
    background-color: #335566;
    border: none;
    height: 42px;
    border-radius: 2px;
    font-size: 16px;
    &:hover {
      box-shadow: 2px 4px 5px rgba(172, 210, 229, 0.3);
    }
  }
}

.alert_buttons {
  margin-left: auto;
  margin-bottom: 0;
  .alert_button {
    color: rgba(16, 47, 63, 1);
    background: #fff;
    border: none;
    font-size: 14px;
    font-weight: 400;
  }
}

.empty {
  width: 100%;
  height: 100px;
}

@media (max-width: 900px) {
  .full_content,
  .full_content_overlay {
    padding: 100px 15px 100px;
    position: relative;
    height: 100%;
    overflow: hidden auto;
    input,
    textarea,
    select {
      font-size: 16px !important;
    }
    input::placeholder,
    textarea::placeholder,
    select::placeholder {
      font-size: 14px;
    }
  }
  .container_style,
  .container_success {
    overflow: hidden;
    display: block !important;
  }
  .header {
    position: fixed;
    height: 100px !important;
    width: 100%;
    z-index: 100002;
    max-width: 900px;
    top: 0;
    left: 0;
    .container {
      position: fixed;
      margin: auto 0 10px;
      height: auto;
      padding-top: 30px !important;
      .logo_container {
        display: initial;
        margin-top: 10px;
      }
      .save_button {
        display: initial;
        margin-top: 25px;
        align-self: baseline;
      }
    }
  }
  .side_block {
    right: -15px;
    top: -100px;
    height: calc(100% + 100px);
    z-index: 100001;
    .save_block {
      position: fixed;
      top: 0;
    }
  }
  .text_block {
    margin-top: 100px;
  }
  .side_save_block_show {
    transition: all 100ms linear;
    animation-duration: 100ms;
    animation-name: show;
  }
}

@media (max-width: 350px) {
  .side_save_block_show {
    width: 250px;
    .text_block {
      width: auto;
      height: auto;
      margin-bottom: 50px;
    }
    .save_buttons {
      flex-direction: column;
      width: auto;
      .close_button,
      .save_data {
        width: 100%;
        margin-bottom: 15px;
      }
      .save_data {
        order: 1;
      }
      .close_button {
        order: 2;
      }
    }
  }
  .header {
    .container {
      margin-right: 0;
    }
  }
}

.loading_message {
  width: 60%;
}
