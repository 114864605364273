.inspection_date_radio_form_field {
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 550px) {
    flex-direction: column !important;
  }
}

.inspection_date_form_field {
  width: 35%;
  margin-left: auto !important;

  @media (max-width: 550px) {
    width: 50%;
    margin-left: 0 !important;
  }
}
