.container {
  display: flex;
  width: 100%;
  height: 82px;
  padding: 0 25px;
  line-height: 20px;
  margin-bottom: 10px;
  background: #ffffff;
  justify-content: space-between;
  font-size: 14px;
}

.content_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: content-box;
  color: #5f6466;

  .column {
    width: 16%;
    display: flex;
    align-items: center;
    overflow: hidden;
    word-break: break-all;
    padding-right: 25px;
  }

  .icons {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 20px;
    max-height: 20px;
    margin-right: 10px;
  }
}

.button_wrapper {
  flex-basis: 32%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;

  .download_btn {
    margin-right: 16px;
  }
}

.button_img {
  margin-right: 10px;
}

.bezahit_status {
  background: #31c96f;
  border-radius: 20px;
  color: #fff;
}

.hide {
  visibility: hidden;
}

.status {
  flex: 0 0;
  display: inline;
  color: #fff;
  border-radius: 20px;
}

@media screen and (max-width: 550px) {
  .container {
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
    padding: 15px 20px;
    margin-left: auto;
    margin-right: auto;
  }
  .content_wrapper {
    align-items: flex-start;
    justify-content: center;
    align-items: center;
    .column {
      display: none;
      &:nth-child(1) {
        width: 100%;
        display: flex;
        word-break: break-all;
      }
    }
    .column + .column {
      margin-top: 3px;
    }
  }
}

.task_icon {
  width: 20px;
  margin-right: 10px;
}

.cut_text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 120px;

  @media screen and (max-width: 1200px) {
    width: 90%;
  }

  @media screen and (max-width: 900px) {
    width: 55%;
  }
}
