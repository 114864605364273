.container,
.line_container {
  display: flex;
  flex-direction: column;
  height: 260px;
  padding: 10px;

  .top {
    margin-top: -10px;
  }

  .bottom {
    display: flex;
    flex-direction: row;
    .left,
    .right {
      width: 50%;
      height: 180px;
      // margin-bottom: -10px;
      canvas {
        max-height: 180px;
      }
      .text_block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: -50px;
        text-align: center;
        span {
          color: var(--blue, #356);
          text-align: center;
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.28px;
        }
        p {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.24px;
          color: #5f6466;
        }

        .bottom_container {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          gap: 20px;
          &_item {
            display: flex;
            padding: 0px 5px;
            align-items: center;
            justify-content: center;
            gap: 8px;
            span {
              width: 6px;
              height: 6px;
              border-radius: 6px;
            }
            p {
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.24px;
              color: #777c7f;
            }
            .link {
              display: flex;
              flex-direction: row;
              align-items: center;
              span {
                display: flex;
                width: 6px;
                height: 6px;
                border-radius: 6px;
                margin-right: 5px;
                margin-top: 2px;
              }
              p {
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.24px;
                color: #777c7f;
              }
            }
          }
        }
      }
    }
  }
}

.line_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .left {
    display: flex;
    flex-direction: column;
    max-width: 210px;
    height: 100%;
    justify-content: space-between;
  }
  .right {
    display: flex;
    max-width: 325px;
    height: 100%;
    width: 100%;
    canvas {
      height: 200px;
    }
  }
}

.empty_data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 20px;
  span {
    margin-top: 20px;
    width: 60%;
    text-align: center;
    color: var(--grey-800, #2e3133);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.28px;
  }
}
