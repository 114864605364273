.progress_diagram_container {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 2px;
  padding: 3px;
  padding-right: 20px;

  &:hover {
    filter: drop-shadow(0px 7.59435px 17.869px rgba(171, 176, 178, 0.17)) drop-shadow(0px 2.26103px 5.32008px rgba(95, 100, 102, 0.17));
  }
}

.active {
  filter: drop-shadow(2px 4px 5px rgba(0, 0, 0, 0.16));
}

.progress_bar {
  display: flex;
  overflow: hidden;
  margin-right: 15px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.12));
}

.progress_bar_content {
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.side_content {
  width: 100%;
}

.side_content_bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  margin-top: 3px;
}

.lower_text {
  color: #335566;
}
