.container {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  padding: 30px;
  height: auto;
  min-height: calc(100% - 30px);
  align-items: center;
  .main__block__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    background-color: #fff;
    box-shadow: 0.5px 8px 8px rgba(0, 0, 0, 0.25);
    align-items: center;
    padding: 70px 0;
    span {
      font-size: 14px;
      font-weight: 400;
      color: #102f3f;
    }
  }
}

.header {
  color: #335566;
  font-size: 18px;
  font-weight: 600;
  span {
    font-size: 20px !important;
    font-weight: 700 !important;
  }
}

.name_block {
  display: flex;
  text-align: center;
  margin: 0 80px;
  color: #102f3f;
  font-size: 14px;
  font-weight: 400;
}

.star {
  margin: 0 7.5px;
}

.max_min_rating {
  display: flex;
  max-width: 220;
  flex-direction: row;
  justify-content: space-between;
  .max_min_name {
    color: #919699;
    font-size: 14px;
    font-weight: 400;
    opacity: 0.8 !important;
    margin: 0 7.5px;
  }
}

.radio_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  padding-left: 7.5px;
  .radio {
    display: flex;
    margin-top: 13px;
    width: 100%;
    input[type='radio'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      height: 18px;
      width: 18px;
      margin: 0;
      margin-right: 10px;
      vertical-align: middle;
      accent-color: #335566;
      border: 1px solid #abb0b2;
      border-radius: 50%;
      margin-top: 3px;
      &:after {
        -webkit-appearance: radio;
        -moz-appearance: radio;
        appearance: radio;
        border: 1px solid #335566;
        color: #335566;
        accent-color: #335566;
      }
    }
    label {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #102f3f;
      width: 85%;
    }
    input[type='radio']:checked {
      appearance: radio;
      -webkit-appearance: radio;
      display: flex;
      height: 18px;
      width: 18px;
      margin: 0;
      margin-right: 10px;
      vertical-align: middle;
      accent-color: #335566;
      border: 1px solid #abb0b2;
      border-radius: 50%;
      margin-top: 3px;
    }
  }
}

.short_label {
  margin-top: 3px;
}

.textarea_block {
  resize: none;
  width: 75%;
  margin-top: 10px;
  padding: 5px;
  border: 1px solid #acd2e5;
  border-radius: 3px;
  height: 80px;
  font-size: 12px;
  font-weight: 400;
}

.rating_tooltip {
  pointer-events: none;
}

.main_container {
  position: relative;
  background-color: #f5f5f5;
  height: 100%;
  padding-top: 45px;
  padding-bottom: 40px;
  overflow: scroll;
}
