.form_name {
  span {
    color: var(--Dark-Blue, #102f3f);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.28px;
    .req {
      color: #bf0040;
    }
  }
}
.input__block {
  position: relative;
  font-size: 14px;
  .input__icons {
    font-size: 14px;
    &:default {
      color: #777c7f;
    }
    option {
      font-size: 12px;
      &:disabled {
        color: #777c7f;
      }
    }
  }
  .input__block__img {
    position: absolute;
    top: 16px;
    left: 8px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  .input__icons {
    padding-left: 5px;
  }
  .radio_err {
    color: #bf0040;
    border: 1px solid #bf0040;
  }
  input {
    &:focus::-webkit-input-placeholder {
      color: transparent;
    }
  }
}
.err__row {
  display: flex;
  color: #bf0040;
  margin-top: 5px;
}
.dropdown {
  width: 100%;
  min-width: 200px;
  height: 100%;
  max-height: 250px;
  overflow-x: hidden;
  border: 1px solid #acd2e5;
  background-color: #fff;
  visibility: hidden;
  z-index: 10;
  cursor: default;
  &.v {
    visibility: visible;
  }
}

.dropdown_li,
.dropdown_li_always {
  font-size: 14px;
  margin: 0;
  cursor: default;
  display: flex;
  height: 48px;
  &:hover {
    background-color: #e5f7ff;
    cursor: pointer;
  }
}

.dropdown_li_always {
  z-index: 12;
}

.item_text {
  padding: 5px;
  padding-left: 16px;
  align-self: center;
  color: #102f3f;
}

.always_item_text {
  padding: 5px;
  padding-left: 16px;
  align-self: center;
  font-weight: 600;
  color: #102f3f;
}

.input__icons__drop {
  display: flex;
  align-items: center;
  color: #777c7f;
  font-size: 12px;
}

.input__icons__drop__selected {
  color: #000 !important;
}

.input__icons__drop,
.input__icons__drop_err {
  width: 100%;
  height: 38px;
  border: 1px solid #acd2e5;
  border-radius: 2px;
  -webkit-appearance: none;
  appearance: none;
  background-image: url(/assets/images/drop-down-blue-icon.svg);
  background-repeat: no-repeat;
  background-position-x: 5px;
  background-position-y: 50%;
  padding-left: 25px;
  font-size: 14px !important;
  margin-top: 5px;
  cursor: pointer;
}

.input__icons__drop_err {
  border: 0.5px solid #bf0040;
  &:focus {
    padding-left: 25px;
    border: 1px solid #bf0040;
  }
}

.input_standart {
  width: 100%;
  border: 1px solid #335566;
  border-radius: 2px;
  height: 38px;
  padding: 10px;
  margin-top: 5px;
  font-size: 14px !important;
}

.optional {
  font-weight: 400;
  font-size: 14px;
  color: #919699 !important;
}
