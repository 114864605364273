.fahrzeugdokumente_card {
  padding: 10px 0px 18px 0px;
  border-bottom: 0.5px solid #777;
  &:last-child {
    border-bottom: none;
  }
}

.fahrzeugdokumente_card__open {
  padding: 15px 30px 30px 30px;
  border-bottom: 0.5px solid #777;
}

.header {
  color: #335566;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.02em;
}

.fahrzeugdokumente_card_content {
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .item_header {
    font-size: 12px;
    color: #777777;
  }

  .item_content {
    font-size: 14px;
    color: #22273a;
    word-break: break-all;
  }

  .button_wrapper {
    display: flex;
    align-items: flex-end;    
  }

  button {
    padding: 6px 20px;
    font-size: 14px;
    width: 230px;

    .button_img {
      margin-right: 14px;
    }
  }
}
