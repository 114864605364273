.container {
  //max-width: 460px;
  box-shadow: 0.5px 3px 10px 0px #000000 25%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
  min-width: 300px;
}

.head {
  padding: 20px;
  background: #f5f5f5;
  display: flex;

  div {
    display: flex;
    flex-direction: column;

    h3 {
      line-height: 1;
      font-weight: 600;
      font-size: 18px;
      color: #335566;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      font-weight: normal;
      font-size: 14px;
      color: #777777;
      max-width: 260px;
    }
  }
}

.buttons_wrapper {
  align-self: flex-end;
  margin: 30px 30px 0;

  button {
    padding: 0 7px;
    box-sizing: border-box;
    background: white;
    border: 0.5px solid #777777;
  }

  button:first-child {
    margin-right: 10px;
  }

  .button_disable {
    background: #f5f5f5;
    pointer-events: none;
  }
}

@media screen and (max-width: 900px) {
  .buttons_wrapper {
    margin: 10px 10px 0;
  }
}

@media (max-width: 1480px) {
  .container {
    max-width: 100%;
    margin-right: 20px;
  }
}

@mixin font {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
}

.header {
  @include font;
  color: #ffffff;
  padding: 6px 10px;
  background: #335566;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;

  img {
    display: block;
    cursor: pointer;
  }
}

.content {
  padding: 10px;
}

.widget_header {
  display: flex;
  margin: 0 15px;
  margin-top: -35px;
  .widget_header_icon {
    margin-top: 5px;
    height: 16px;
    width: 16px;
  }
  .widget_header_column {
    flex-direction: column;
    margin: 0 0 0 10px;
    color: #335566;
    h5 {
      font-size: 20px;
      margin: 0;
    }
  }
  .widget_plus_icon_coontainer {
    height: 17px;
    cursor: pointer;
    margin-right: 15px;
  }
}

.hr_widget {
  opacity: 80;
  margin: 0;
  margin-top: 30px;
}

.widget_header_description {
  color: #777c7f;
}
