.details_block {
  // overflow: hidden;
  width: 980px;
}

.container {
  display: flex;
  width: 980px;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  .full_header {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 40px;
    font-size: 20px;
    color: rgba(16, 47, 63, 1);
    font-weight: 600;
    line-height: 27.24px;
  }
  .half_block {
    width: 480px;
    padding: 40px 40px 20px;
    flex-direction: column;
    border: 1px solid #acd2e6;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  .block_name {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    h6 {
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-align: left;
      margin: 0;
    }
    .edit {
      cursor: pointer;
    }
  }
  .information_block {
    width: 100%;
    margin-bottom: 20px;
    span {
      display: flex;
      flex-direction: row;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.02em;
      text-align: left;
      color: rgba(23, 24, 25, 1);
      margin: 0;
      margin-bottom: 5px;
      word-break: break-word;
    }
    .first_p {
      margin-right: 5px;
      max-width: 59%;
      word-break: break-word;
    }
    .last_p {
      margin-left: 10px;
      word-break: break-word;
    }
  }
}

.damagetype_block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    color: rgba(23, 24, 25, 1);
    margin: 0;
  }
  .edit {
    cursor: pointer;
  }
}

.small_header {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(16, 47, 63, 1);
  margin-bottom: 20px;
  margin-top: 30px;
}

.buttons_block {
  width: 100%;
}

.mobile_column {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 440px;
}

@media (max-width: 900px) {
  .details_block,
  .container {
    width: 100%;
    .half_block {
      width: 100%;
    }
  }
  .container {
    justify-content: center;
  }
  .full_header {
    margin: 40px 20% 30px;
  }
  .information_block {
    span {
      justify-content: space-between;
      .first_p {
        margin-right: 5px;
        max-width: 59%;
      }
      .last_p {
        margin-left: 5px;
        word-break: break-word;
      }
    }
  }
  .buttons_block {
    max-width: 440px;
  }
}

@media (max-width: 900px) {
  .details_block,
  .container {
    .half_block {
      padding: 20px 20px 10px;
    }
  }
}

@media (max-width: 420px) {
  .information_block {
    span {
      p {
        word-break: normal;
      }
    }
  }
}
