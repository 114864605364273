.more {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
  position: relative;
  margin-left: 15px;

  &_active {
    visibility: hidden;
  }

  &_button {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 23px;
    left: 8px;
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
  }

  &_dot {
    padding: 0 !important;
    margin-top: 3px;
    width: 4px;
    height: 4px;
    background-color: #22273a;
    border-radius: 2px;
  }
}

.delete {
  &__button {
    align-items: center;
    width: 100%;
    outline: none;
    background: #fff;
    font-size: 13px;
    line-height: 20px;
    color: #000;
    transition: 0.3s;
    display: flex;
    justify-content: space-between;
    opacity: 1;
    pointer-events: auto;
    padding: 10px 15px;
    border: 0.5px solid #e6e6e6;

    &:hover {
      background: #f7f7f7;
    }
    &_img {
      display: block;
      width: 15px;
      height: 15px;
    }
    &_text {
      word-break: normal !important;
      text-align: left;
      font-size: 14px;
      line-height: 20px;
      color: #102f3f;
      margin: 0 10px;
    }
  }
}

.more_menu_items {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

@media (min-width: 992px) {
  .more {
    padding-bottom: 0;
  }
}
