.container {
  display: flex;
  flex-direction: column;
  margin: 56px auto 30px;
}

.page_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.headers_wrapper {
  display: flex;
  padding: 0 25px 20px;

  .headers_container {
    font-size: 14px;
    color: #abafb3;
    width: 100%;
    display: flex;
    align-items: center;
    span {
      width: 16%;
      padding-right: 25px;
    }
  }
  .button_wrapper {
    flex-basis: 32%;
  }
}

.headers_title {
  cursor: pointer;
  position: relative;
}

.aufgaben {
  background-color: #f5f5f5;
  padding: 30px;
  height: auto;
  min-height: calc(100% - 36px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .aufgaben_wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .tasks_container {
    width: 100%;
    max-width: 1200px;
  }

  .new_aufgaben {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 20px;

    figure {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      border-radius: 30px;
      margin-right: 20px;
      cursor: pointer;
    }

    figure:hover {
      background: #fff;
      box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
    }
  }

  .aufgaben_container {
    min-width: 850px;
    padding: 0 10px;
    margin-bottom: 45px;
  }
  .aufgaben_header {
    display: flex;
    font-size: 14px;
    color: #abafb3;
    padding: 10px 15px;
  }
  .aufgaben_row_body {
    background-color: #fff;
    overflow: hidden;
    box-shadow: 0.5px 8px 8px rgba(0, 0, 0, 0.25);

    .aufgaben_row_body_container {
      padding: 15px;
      .info_section {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }
      .stepper_section {
        .stepper {
          display: flex;
          margin-top: 15px;
          .step {
            padding: 0 10px;
            display: flex;
            flex-direction: column;
            border-right: 1px solid #c4c4c4;
            color: #828282;
            .step_title {
              font-size: 18px;
              margin: 0 10px 0 0;
            }
            .step_descr {
              font-size: 14px;
              margin: 15px 0 10px;
              min-height: 38px;
            }
            .expand_btn {
              margin-top: 10px;
              color: #bf0040;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              align-self: flex-end;
              cursor: pointer;
              span {
                font-size: 18px;
                text-decoration-line: underline;
                margin-right: 15px;
              }
            }
            .step_form {
              padding-top: 20px;
              overflow: hidden;
              display: flex;
              flex-direction: column;
              align-self: flex-end;
            }

            &:last-child {
              border-right: none;
            }
          }
          .step_active {
            color: #396073;
            .step_title {
              font-size: 25px;
            }
            .step_descr {
              margin: 8px 0 10px 0;
              min-height: auto;
              font-weight: bold;
            }
            .step_start {
              margin-top: 0;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }

  .filters_container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  .filters_wrapper_left {
    width: 85%;
  }
  .no_items {
    padding-top: 25px;
    h5 {
      text-align: center;
      margin-bottom: 10px;
      color: #335566;
      font-size: 16px;
    }
    p {
      text-align: center;
      color: #22273a;
      font-size: 14px;
    }
  }
  .margin_top {
    margin-top: 23px;
  }
  .rotate {
    transform: rotate(180deg);
  }
  .upload_documents_anfrage {
    width: 100%;
    border: 1px solid #c4c4c4;
    .title {
      background: #8fafbf;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      span {
        font-size: 14px;
        color: #102f3f;
      }
      svg {
        cursor: pointer;
      }
    }
    .body {
      overflow: hidden;
    }
  }
  .uploaded_files_anfrage_container {
    position: relative;
    .uploaded_files_anfrage {
      background: #f9f9f9;
      box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.2);
      width: 180px;
      min-height: 150px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      z-index: 3;

      h6 {
        margin: 25px 0;
        text-align: center;
      }
      .files_amount {
        width: 20px;
        height: 20px;
        background-color: #ffbfbf;
        color: #7f002a;
        border-radius: 50%;
        text-align: center;
      }
    }
    .multiple_files_box {
      background: #f9f9f9;
      box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.2);
      position: absolute;
      &_first {
        width: 190px;
        height: 140px;
        top: 5px;
        left: -5px;
        z-index: 2;
      }
      &_second {
        width: 200px;
        height: 130px;
        top: 10px;
        left: -10px;
        z-index: 1;
      }
    }
  }
}

.step_end,
.step_start {
  color: #828282;
  font-size: 12px;
  margin-top: 10px;
}

.step_start {
  margin-bottom: 10px;
}

.header_item {
  position: relative;
  cursor: pointer;
}

.sort_arrow {
  position: absolute;
  top: 5px;
  left: -15px;
  display: block;
  transform: rotate(0);
  transition: 0.3s;
}

.reverse {
  transform: rotate(180deg);
}

.add_new_icon {
  margin-right: 10px;
}

@media screen and (max-width: 550px) {
  .headers_wrapper {
    display: none;
  }
  .filters_wrapper_left {
    width: 100% !important;
  }
}

@media (max-width: 1024px) {
  .container {
    margin: 0 auto 30px;
  }
}
