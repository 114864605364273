$font: 'Open Sans', sans-serif;

.existing_vehicle {
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
  padding: 0 15px;
  padding-top: 30px;
  height: auto;
  min-height: calc(100% - 36px);
  overflow-x: hidden;

  .grid_order_status {
    grid-template-areas:
      'firstTopWidget firstTopWidget firstTopWidget secondTopWidget secondTopWidget thirdTopWidget thirdTopWidget thirdTopWidget fourthTopWidget fourthTopWidget'
      'carOverview carOverview carOverview carOverview carOverview carOverview carOverview carOverview carOverview carOverview'
      'carOverview carOverview carOverview carOverview carOverview carOverview carOverview carOverview carOverview carOverview'
      'leasingWidgets leasingWidgets leasingWidgets leasingWidgets leasingWidgets leasingWidgets leasingWidgets leasingWidgets leasingWidgets leasingWidgets'
      'repairPartnerWidget repairPartnerWidget repairPartnerWidget repairPartnerWidget repairPartnerWidget repairPartnerWidget repairPartnerWidget repairPartnerWidget repairPartnerWidget repairPartnerWidget'
      'mainTabs mainTabs mainTabs mainTabs mainTabs mainTabs mainTabs mainTabs mainTabs mainTabs'
      'endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget' !important;

    @media screen and (max-width: 550px) {
      grid-template-columns: minmax(100%, 1fr);
      grid-template-areas:
        'carOverview'
        'leasingWidgets'
        'repairPartnerWidget'
        'mainTabs'
        'endOfLeasingWidget' !important;
    }
  }

  .grid_driver {
    grid-template-areas:
      'firstTopWidget firstTopWidget firstTopWidget thirdTopWidget thirdTopWidget thirdTopWidget thirdTopWidget carOverviewTopWidget carOverviewTopWidget carOverviewTopWidget'
      'carOverview carOverview carOverview carOverview carOverview carOverview carOverview carOverview carOverview carOverview'
      'carOverview carOverview carOverview carOverview carOverview carOverview carOverview carOverview carOverview carOverview'
      'leasingWidgets leasingWidgets leasingWidgets leasingWidgets leasingWidgets leasingWidgets leasingWidgets leasingWidgets leasingWidgets leasingWidgets'
      'repairPartnerWidget repairPartnerWidget repairPartnerWidget repairPartnerWidget repairPartnerWidget repairPartnerWidget repairPartnerWidget repairPartnerWidget repairPartnerWidget repairPartnerWidget'
      'mainTabs mainTabs mainTabs mainTabs mainTabs mainTabs mainTabs mainTabs mainTabs mainTabs'
      'endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget' !important;

    @media screen and (max-width: 550px) {
      grid-template-columns: minmax(100%, 1fr);
      grid-template-areas:
        'carOverview'
        'leasingWidgets'
        'repairPartnerWidget'
        'firstTopWidget'
        'thirdTopWidget'
        'carOverviewTopWidget'
        'carOverviewTopWidget'
        'carOverviewBottomWidget'
        'mainTabs'
        'endOfLeasingWidget';
    }
  }

  .grid {
    display: grid;
    width: 100%;
    gap: 10px;
    grid-template-areas:
      'firstTopWidget firstTopWidget firstTopWidget secondTopWidget secondTopWidget thirdTopWidget thirdTopWidget thirdTopWidget fourthTopWidget fourthTopWidget'
      'carOverview carOverview carOverview carOverview carOverview carOverview carOverview carOverview carOverviewTopWidget carOverviewTopWidget'
      'carOverview carOverview carOverview carOverview carOverview carOverview carOverview carOverview carOverviewBottomWidget carOverviewBottomWidget'
      'leasingWidgets leasingWidgets leasingWidgets leasingWidgets leasingWidgets leasingWidgets leasingWidgets leasingWidgets leasingWidgets leasingWidgets'
      'repairPartnerWidget repairPartnerWidget repairPartnerWidget repairPartnerWidget repairPartnerWidget repairPartnerWidget repairPartnerWidget repairPartnerWidget repairPartnerWidget repairPartnerWidget'
      'mainTabs mainTabs mainTabs mainTabs mainTabs mainTabs mainTabs mainTabs mainTabs mainTabs'
      'endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget endOfLeasingWidget';
    grid-template-columns: repeat(10, 1fr);

    @media screen and (max-width: 550px) {
      grid-template-columns: minmax(100%, 1fr);
      grid-template-areas:
        'carOverview'
        'leasingWidgets'
        'repairPartnerWidget'
        'firstTopWidget'
        'secondTopWidget'
        'thirdTopWidget'
        'fourthTopWidget'
        'carOverviewTopWidget'
        'carOverviewBottomWidget'
        'mainTabs'
        'endOfLeasingWidget';
    }
  }

  @media (max-width: 990px) {
    padding: 0;
  }

  @media screen and (max-width: 550px) {
    width: auto;
    margin: 20px 15px 0 15px;
    padding: 0 0 130px;
  }

  &_wrapper {
    grid-area: endOfLeasingWidget;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  h3 {
    font-size: 18px;
  }
}

.editable_field_container {
  display: flex;
}

.section_header_container {
  margin-bottom: 20px;
}

.container {
  width: 100%;
  margin-bottom: 30px;
}

.item_title {
  font-family: $font;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #777777;
  padding-bottom: 32px;
}

.item_value {
  font-family: $font;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  padding-bottom: 32px;
}

.header_container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.section_header {
  font-family: $font;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #22273a;
}
.car_number {
  font-size: 14px;
  color: #777777;
}

.main_fields_section {
  flex-basis: 50%;
  &:first-child {
    margin-right: 10px;
  }
}

.divider {
  border-top: 1px solid #777c7f;
}

.expand_section_divider {
  border-top: 1px solid #c8cacc;
  margin: 0 -30px;
}

.car_options_container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -1px;
  .equipment {
    p {
      font-size: 14px !important;
    }
  }
}

.vehicle_container {
  min-width: 300px;
  margin-right: 0;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .expand_section_divider {
    margin: 0;
  }
}

@media (max-width: 990px) {
  .form_container {
    width: 100%;
    max-width: 100%;
  }
  .main_fields_section {
    flex-basis: 100% !important;
    &:first-child {
      margin-right: 0px;
    }
  }
  .editable_field_container {
    flex-direction: column;
  }
  .section_header_container {
    margin-bottom: 0;
  }
}

@media (max-width: 600px) {
  .header_container {
    flex-direction: column;
    align-items: flex-start;
  }

  .section_header {
    margin-bottom: 10px;
  }
}

.widget {
  max-width: 460px;
  padding-bottom: 0 !important;
}

.no_label {
  margin-left: 0 !important;
}

.button_wrapper {
  display: flex;
  justify-content: center;
  padding: 10px;

  .button_content {
    display: flex;
    align-items: center;
    padding: 0 5px;

    .upload_icon {
      margin-right: 10px;
    }
  }
}

.gallery_wrapper {
  min-height: 250px !important;
  max-width: 600px !important;
  padding: 0 5px !important;

  @media screen and (max-width: 991px) {
    margin: 0 auto;
    margin-bottom: 15px;
  }
}
