.container {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 5;
  z-index: 98;
  box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
  width: 400px;
  height: 90%;
  display: block;
  background-color: #fff;
  transform: translateX(460px);
  transition: transform 0.3s ease-out;
  overflow: auto;
  border-radius: 8px;
  margin: 0 30px 20px 0;

  @media screen and (max-width: 556px) {
    width: calc(100% - 30px);
    transform: translateX(calc(100% + 30px));
    margin: 0 15px 10px 0;
  }

  .header {
    h3 {
      color: #fff;
      font-size: 22px;
      font-weight: normal;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    padding: 10px;
    background: #102f3f;

    .header_wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .bot_header {
        display: flex;
        align-items: center;
        .back_icon {
          cursor: pointer;
        }
        .bot_avatar {
          border-radius: 50%;
          width: 40px;
          height: 40px;
          margin: 0 10px 0 25px;
        }
        .bot_name {
          color: #fff;
          font-size: 14px;
        }
      }

      .close_button_wrapper {
        cursor: pointer;
      }
    }
  }
}

.open {
  transform: translateX(0);
}
