.page {
  padding: 0 50px;
}

.title {
  color: #335566;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
}

.panel_placeholder {
  padding-top: 40px;
  padding-bottom: 20px;
}

.license_plate {
  &_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  &_no_car_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    text-align: center;

    .license_plate_title {
      display: block;
    }

    .license_plate_input_form {
      margin-top: 60px;
    }
  }

  &_title_container {
    margin-right: 10px;
  }

  &_title {
    color: #335566;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &_subtitle {
    color: #000;
    font-size: 14px;
    font-weight: 400;
  }
}

.back_to_website_link {
  display: block;
  color: #335566;
  text-align: center;
  width: 100%;
}

@media (max-width: 550px) {
  .page {
    padding: 0;
  }
  .title {
    margin-bottom: 16px;
  }
  .license_plate {
    &_wrapper {
      flex-direction: column;
      margin-bottom: 16px;
    }
    &_title_container {
      display: none;
    }
    &_input {
      margin-bottom: 0;
      width: 100%;
    }
    &_input {
      width: 100%;

      &_form {
        width: 100%;
      }
    }
  }
}

@media (max-width: 910px) {
  .page {
    padding: 0;
  }
}
