.container {
  min-width: 315px;
  box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  z-index: 2;
}

@mixin font {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
}

.header {
  @include font;
  color: #ffffff;
  padding: 10px;
  background: #335566;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;

  img {
    display: block;
    cursor: pointer;
  }
}

.content {
  padding: 10px;
}
