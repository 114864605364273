.sidebar {
  width: 320px;
  max-width: calc(100vw - 50px);
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 1001;
  transition: transform .3s ease-out;
}

.open {
  transform: translateX(0);
}

.close {
  transform: translateX(-100%);
}

