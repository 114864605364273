.btn {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #00364b;
  // border-color: rgba(0, 0, 0, 0.75);
  border-radius: 3px !important;
  font-weight: 600;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  display: inline-block;
  margin-bottom: 0;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  width: 45%;
  max-width: 60px;
  text-align: left;
  padding-left: 0;
  background-color: transparent;
  border: none;
}

.filter_count {
  display: inline-block;
  margin: 0 0 0 5px;
  border-radius: 12px;
  padding: 0 4px;
  min-width: 16px;
  height: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;
  background-color: #FF4C4A;
  color: #fff;
}
