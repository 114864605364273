.card_wrapper {
  flex-basis: 50%;
  display: flex;
  padding: 10px 10px 0;
  justify-content: center;

  .card {
    width: 100%;
    max-width: 200px;
    position: relative;

    img {
      position: absolute;
      right: -10px;
      top: -10px;
      cursor: pointer;
    }
    .card_image {
      height: 110px;
      border-radius: 5px;
      background-size: cover;
      background-position: center center;
    }
    p {
      font-weight: 600;
      text-align: center;
      margin: 5px 0;
    }
  }
  .empty_card {
    width: 100%;
    max-width: 200px;
    height: 110px;
    border-radius: 5px;
    border: 1px dashed black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 800px) {
  .card_wrapper {
    flex-basis: 25%;
  }
}

