.form__block {
  margin-bottom: 58px;
  .form__block__header {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.02em;
    margin-bottom: 20px;
    color: #335566;
  }
}

.form__block__short {
  margin-bottom: 0;
}

.hr {
  margin: 0;
}
.p__second {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  width: 55%;
  word-wrap: break-word;
}

.form__block__row {
  display: flex;
  flex-direction: row;
  .form__block__left {
    margin-right: 2%;
  }
  .form__block__right {
    margin-left: 2%;
  }
  .form__block__right,
  .form__block__left {
    width: 100%;
    span {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      line-height: 18px;
      letter-spacing: 0.02em;
      color: #5f6466;
      padding: 20px 0;
    }
  }

  .form__block__left__no_margin {
    width: 100%;
    span {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      line-height: 18px;
      letter-spacing: 0.02em;
      color: #5f6466;
      padding: 20px 0;
    }
  }
}

.form__block__left__names {
  display: flex;
  align-items: center;
  padding: 0 5px;
  :first-child {
    width: 200px;
    align-self: center;
    margin-right: 10px;
    letter-spacing: 0.02em;
  }
}

.p__second {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  word-wrap: break-word;
}

.p__first {
  margin: 0;
  margin-bottom: 15px;
  padding: 0 5px;
  color: #000;
  font-size: 14px;
}

.margin_bottom {
  margin-bottom: 30px;
}

.info_header {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5f6466;
  padding: 0;
  margin: 20px 0;
}

.files {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  width: 100%;
  cursor: pointer;
  .file {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    border: 1px solid #e0e3e5;
    margin-bottom: 10px;
    word-break: break-word;

    .icon_container {
      display: flex;
      align-items: center;
      .description {
        display: flex;
        flex-direction: column;
        .file_name {
          padding: 0;
          font-size: 14px;
          color: #102f3f;
        }
        .file_size {
          padding: 0px;
          font-size: 10px;
          color: #777c7f;
        }
      }
    }
    .icon {
      min-width: 40px;
      display: flex;
      align-items: center;
      margin-left: 5px;
    }
    i {
      cursor: pointer;
      margin-left: 30px;
    }
  }
}
