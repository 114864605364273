.circle {
  margin-left: 1px;
  height: 28px;
  width: 25px;
}

.step_box {
  display: flex;
  flex-direction: row;
}

.step_content {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.stepper {
  position: fixed;
  width: 45px;
  max-height: 620px;
  height: fit-content;
  left: 200px;
}

.mobile_completed7,
.mobile_active7,
.mobile_uncompleted7,
.mobile_completed6,
.mobile_active6,
.mobile_uncompleted6,
.mobile_completed5,
.mobile_active5,
.mobile_uncompleted5,
.mobile_completed4,
.mobile_active4,
.mobile_uncompleted4 {
  width: 30px;
  height: 8px;
  border-radius: 3px;
  background: #335566;
}

.mobile_uncompleted7,
.mobile_uncompleted6,
.mobile_uncompleted5,
.mobile_uncompleted4 {
  background: #acd2e6;
}

.mobile_completed7,
.mobile_uncompleted7 {
  width: 35px;
}
.mobile_active7 {
  width: 75px;
}

.mobile_completed6,
.mobile_uncompleted6 {
  width: 40px;
}
.mobile_active6 {
  width: 90px;
}

.mobile_completed5,
.mobile_uncompleted5 {
  width: 48px;
}
.mobile_active5 {
  width: 110px;
}

.mobile_active4 {
  width: 130px;
}
.mobile_completed4,
.mobile_uncompleted4 {
  width: 60px;
}

.empty_connector {
  width: 6px;
}

@media (max-width: 900px) {
  .step_content {
    margin: 60px 0 0;
  }
  .step_box {
    flex-direction: column;
    position: relative;
    width: 100%;
  }
  .stepper {
    position: fixed;
    top: 100px;
    left: 0;
    width: 100%;
    height: 60px;
    max-height: 60px;
    z-index: 100000;
    background: linear-gradient(to bottom, white 60%, transparent 100%);
  }
}

@media (min-width: 415px) and (max-width: 474px) {
  .mobile_active4 {
    width: 150px;
  }
  .mobile_completed4,
  .mobile_uncompleted4 {
    width: 70px;
  }

  .mobile_completed6,
  .mobile_uncompleted6 {
    width: 40px;
  }
  .mobile_active6 {
    width: 130px;
  }

  .mobile_completed7,
  .mobile_uncompleted7 {
    width: 40px;
  }
  .mobile_active7 {
    width: 115px;
  }

  .mobile_completed5,
  .mobile_uncompleted5 {
    width: 55px;
  }
  .mobile_active5 {
    width: 130px;
  }
}

@media (min-width: 475px) and (max-width: 550px) {
  .mobile_completed7,
  .mobile_uncompleted7 {
    width: 45px;
  }
  .mobile_active7 {
    width: 130px;
  }

  .mobile_completed6,
  .mobile_uncompleted6 {
    width: 50px;
  }
  .mobile_active6 {
    width: 140px;
  }

  .mobile_completed5,
  .mobile_uncompleted5 {
    width: 65px;
  }
  .mobile_active5 {
    width: 160px;
  }

  .mobile_active4 {
    width: 185px;
  }
  .mobile_completed4,
  .mobile_uncompleted4 {
    width: 80px;
  }
}

@media (min-width: 550px) and (max-width: 600px) {
  .mobile_completed7,
  .mobile_uncompleted7 {
    width: 50px;
  }
  .mobile_active7 {
    width: 160px;
  }

  .mobile_completed6,
  .mobile_uncompleted6 {
    width: 60px;
  }
  .mobile_active6 {
    width: 150px;
  }

  .mobile_completed5,
  .mobile_uncompleted5 {
    width: 75px;
  }
  .mobile_active5 {
    width: 190px;
  }

  .mobile_active4 {
    width: 210px;
  }
  .mobile_completed4,
  .mobile_uncompleted4 {
    width: 90px;
  }
}

@media (min-width: 600px) and (max-width: 700px) {
  .mobile_completed7,
  .mobile_uncompleted7 {
    width: 60px;
  }
  .mobile_active7 {
    width: 180px;
  }

  .mobile_completed6,
  .mobile_uncompleted6 {
    width: 70px;
  }
  .mobile_active6 {
    width: 180px;
  }

  .mobile_completed5,
  .mobile_uncompleted5 {
    width: 82px;
  }
  .mobile_active5 {
    width: 215px;
  }

  .mobile_active4 {
    width: 230px;
  }
  .mobile_completed4,
  .mobile_uncompleted4 {
    width: 105px;
  }
}

@media (min-width: 700px) and (max-width: 800px) {
  .mobile_completed7,
  .mobile_uncompleted7 {
    width: 70px;
  }
  .mobile_active7 {
    width: 210px;
  }

  .mobile_completed6,
  .mobile_uncompleted6 {
    width: 80px;
  }
  .mobile_active6 {
    width: 220px;
  }

  .mobile_completed5,
  .mobile_uncompleted5 {
    width: 95px;
  }
  .mobile_active5 {
    width: 230px;
  }

  .mobile_active4 {
    width: 270px;
  }
  .mobile_completed4,
  .mobile_uncompleted4 {
    width: 120px;
  }
}

@media (min-width: 800px) and (max-width: 900px) {
  .mobile_completed7,
  .mobile_uncompleted7 {
    width: 80px;
  }
  .mobile_active7 {
    width: 230px;
  }

  .mobile_completed6,
  .mobile_uncompleted6 {
    width: 90px;
  }
  .mobile_active6 {
    width: 250px;
  }

  .mobile_completed5,
  .mobile_uncompleted5 {
    width: 110px;
  }
  .mobile_active5 {
    width: 260px;
  }

  .mobile_active4 {
    width: 300px;
  }
  .mobile_completed4,
  .mobile_uncompleted4 {
    width: 140px;
  }
}

@media (max-width: 350px) {
  .mobile_active7 {
    width: 50px;
  }
  .mobile_completed7,
  .mobile_uncompleted7 {
    width: 22px;
  }

  .mobile_active6 {
    width: 65px;
  }
  .mobile_completed6,
  .mobile_uncompleted6 {
    width: 28px;
  }

  .mobile_active5 {
    width: 90px;
  }
  .mobile_completed5,
  .mobile_uncompleted5 {
    width: 32px;
  }

  .mobile_active4 {
    width: 100px;
  }
  .mobile_completed4,
  .mobile_uncompleted4 {
    width: 45px;
  }
}

.step_style {
  padding-right: 0 !important;
}
