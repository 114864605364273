@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

body {
  overflow: hidden !important;
}
#app {
  background: none !important;
}

.pointer {
  cursor: pointer;
}

.nowrap-white-space {
  white-space: nowrap;
}

.not-allowed {
  cursor: not-allowed;
}

p,
h3 {
  margin: 0;
}

@media (max-width: 768px) {
  .vehicle-list-container {
    padding-right: 5px;
    padding-left: 5px;
  }

  .pagination-row {
    margin-left: -5px;
    margin-right: -5px;
  }
}

#sorting {
  display: flex;
  flex-direction: row;

  width: 100%;
  justify-content: flex-end;
  padding-right: 0;

  & > span {
    color: rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;
    padding-right: 28px;
    // paddingBottom: '10px',
    padding-left: 5px;
    font-size: 17px;
    font-weight: bold;
    white-space: nowrap;
    display: none;
    @media (min-width: 992px) {
      display: flex;
    }
  }
}

.filter-select {
  width: 100%;
  margin-bottom: 3px;

  select:disabled {
    background-color: #ccc;
  }
}

.pg-viewer-wrapper {
  overflow-y: auto !important;
  .pg-viewer {
    max-width: 100%;
    .pdf-canvas {
      max-width: 100%;
      canvas {
        margin: 0 auto;
        display: block;
        max-width: none !important;
      }
    }
    .photo-viewer-container {
      height: 100% !important;
    }
    .spreadsheet-viewer {
      height: 100%;
      .react-grid-Container {
        height: calc(100% - 25px);
        .react-grid-Main {
          height: 100%;
          .react-grid-Grid {
            min-height: 100% !important;
          }
        }
      }
    }
  }
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.hide_for_mobile {
  display: block;
}

@media screen and (max-width: 550px) {
  .hide_for_mobile {
    display: none;
  }
}

.line-through {
  text-decoration: line-through;
}
#header {
  a {
    visibility: initial;
    color: #ad1380;
  }
  img {
    max-width: none;
  }
  .dropdown-menu {
    visibility: initial;
  }
}
#footer {
  margin-top: 0;
}
// initial

$main-style-color: #335566;
$white: #ffffff;
$extraLightBlue: #e5f7ff;

.d-block {
  display: block;
}
.d-inline-flex {
  display: inline-flex;
}
.d-none {
  display: none;
}

.d-flex {
  display: flex;
}
.f-d-row {
  flex-direction: row !important;
}
.f-d-column {
  flex-direction: column;
}
.a-i-center {
  align-items: center;
}
.a-i-flex-start {
  align-items: flex-start;
}
.j-c-space-between {
  justify-content: space-between;
}
.j-c-flex-end {
  justify-content: flex-end;
}
.j-c-center {
  justify-content: center;
}
.cursor-pointer {
  cursor: pointer;
}
.w-100 {
  width: 100%;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 10px !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mr-1 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mt-20 {
  margin-top: 15px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-30 {
  margin-top: 30px !important;
}
.mb-1 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-2 {
  margin-bottom: 20px;
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-2 {
  margin-left: 20px !important;
}
.ml-auto {
  margin-left: auto;
}
.m-auto {
  margin: auto;
}
.m-0 {
  margin: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.p-0 {
  padding: 0 !important;
}
.color-1 {
  color: $main-style-color !important;
}
.no-border {
  border: none;
}
.gallery_fahrzeug_class {
  width: 100%;
  .image-gallery-image {
    min-height: 380px;
  }
  .image-gallery-thumbnails-container {
    display: flex;
    justify-content: center;
  }

  .image-gallery-icon:hover {
    color: $main-style-color;
  }
  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:hover,
  .image-gallery-thumbnail:focus {
    border-color: $main-style-color;
  }
  .image-gallery-slides {
    max-height: 400px;
  }
  .image-gallery-thumbnail-inner {
    max-height: 65px;
    overflow: hidden;
    .image-gallery-thumbnail-image {
      vertical-align: top;
    }
  }
}
.gallery_fahrzeug_class_fullscreen {
  .image-gallery-image {
    height: calc(100vh - 80px);
  }
  .image-gallery-slides {
    max-height: none;
  }
}
.button-mobexo {
  padding: 8px 15px;
  border-radius: 3px;
  transition: box-shadow 0.3s;
  text-decoration: none !important;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &__main {
    color: #fff;
    background-color: $main-style-color;
    border: none;
    &:hover {
      color: #fff;
      text-decoration: none;
    }
    &:disabled {
      color: #fff;
      background-color: #c6cacc;
    }
  }
  &__secondary {
    color: $main-style-color;
    border: 2px solid $main-style-color;
    background-color: #fff;
    padding: 6px 15px;
  }
  &__disable {
    cursor: default;
    opacity: 0.3;
    cursor: not-allowed !important;
    &:hover {
      box-shadow: none !important;
    }
  }
  &:hover {
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.2);
  }
  &__disabled {
    background-color: #939393;
    &:hover {
      background-color: #939393;
    }
  }
  a {
    color: #fff;
    text-decoration: none;
  }
}
.reset-button {
  display: flex;
  border: none;
  background: transparent;
  padding: 0;
}
.mobexo-divider {
  border-top: 1px solid #c6cacc;
}
.mobexo-panel {
  background-color: #fff;
  padding: 15px;
  display: flex;
  &-column {
    flex-direction: column;
  }
  @media (min-width: 600px) {
    padding: 30px;
  }
}
.mobexo-page-container {
  margin: 0 30px;
}
.mobexo-table-chip {
  background-color: #30bf30;
  border-radius: 20px;
  padding: 5px 10px;
  color: #fff;
  font-style: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.MuiPaper-root {
  .MuiPickersDay-root.Mui-selected,
  .PrivatePickersMonth-root.Mui-selected,
  .PrivatePickersYear-yearButton.Mui-selected {
    background-color: $main-style-color !important;
  }
  .MuiMenuItem-root:focus {
    background-color: $white;
  }
  .MuiMenuItem-root:hover,
  .MuiPickersDay-root:hover,
  .PrivatePickersYear-yearButton:hover {
    background-color: $extraLightBlue;
  }
  .MuiMenuItem-root.Mui-selected {
    background-color: $extraLightBlue;

    &:hover,
    &:focus {
      background-color: $extraLightBlue;
    }
  }
}
.MuiAutocomplete-popper {
  .MuiAutocomplete-listbox {
    li {
      background-color: $white !important;
    }
    li[aria-selected='true'],
    li:hover {
      background-color: $extraLightBlue !important;
    }
  }
}
.btn-success:active,
.btn-success:focus,
.btn-success:active:focus,
.btn-success:active:hover {
  border-color: $main-style-color;
  background-color: $main-style-color;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: unset;
  letter-spacing: normal;
}
html {
  overflow-x: initial;
  font-size: 14px;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-popper {
  z-index: 1001 !important;
}
.react-datepicker__day--keyboard-selected {
  background-color: #fff;
  color: #000;
  &:hover {
    background-color: #f0f0f0;
  }
}
.react-datepicker__day--disabled {
  color: #ccc !important;
}
// .mobexo-datepicker {
//   .react-datepicker__triangle {
//     left: 50% !important;
//   }
// }

.vjs-theme-forest {
  --vjs-theme-forest--primary: #335566;
}

.shadow-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.shadow-1:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
}

.shadow-2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.shadow-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.shadow-4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.shadow-5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.bg-white {
  background: white;
}

.expansion_header {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  padding: 0 0 0 24px;
  cursor: pointer;
}

//TODO: MOVE TO Breadcrumbs-view.js

#page-title {
  position: relative;
  padding: 30px 0;
  background-color: #f1f1f1;
}
#page-title.page-title-padding {
  padding: 30px 0 0 !important;
}
#page-title.single-bread {
  padding: 20px 0 10px !important;
}
#page-title .criteria {
  margin-top: 10px;
  color: #444;
  font-size: 13px;
  font-weight: 600;
}
#page-title .criteria a.label {
  color: #999;
}
#page-title .criteria a.label:focus,
#page-title .criteria a.label:hover {
  color: #999;
  border-color: #c6c6c6;
}
#page-title .btn-criteria {
  font-size: 13px;
  margin-top: 5px;
  padding: 0.3em 0.5em;
  margin-left: 3px;
  color: #ddd;
}
#page-title .btn-criteria:hover {
  color: #fff;
  border-color: #fff;
}
#page-title .btn-criteria span {
  display: inline-block;
  margin-top: 0;
  margin-left: 3px;
  font-size: 15px;
  color: #ddd;
  vertical-align: middle;
}
#page-title .sliderbox {
  margin-top: 30px;
}
#page-title .sliderbox h1,
#page-title .sliderbox h1 span {
  font-size: 38px !important;
  color: #fff !important;
  font-weight: 600;
}
#page-title .sliderbox h1 span {
  display: inline !important;
}
#page-title .sliderbox .facts {
  margin: 40px 0;
}
#page-title .sliderbox .facts li {
  font-size: 24px;
  margin-bottom: 15px;
}
#page-title .sliderbox .facts li i {
  margin-right: 5px;
}
#page-title .deal-head .excerpt-intro,
#page-title .manufacturer-head .excerpt-intro {
  color: #333;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  padding-bottom: 5px;
}
#page-title .deal-head .excerpt-intro span,
#page-title .manufacturer-head .excerpt-intro span {
  font-size: 13px !important;
  display: inline-block !important;
  font-weight: 400 !important;
  margin-top: 0 !important;
}
#page-title .deal-head .manufacturer-area,
#page-title .manufacturer-head .manufacturer-area {
  width: 120px;
  height: 80px;
  float: right;
  background: #f7f7f7;
}
#page-title .deal-head .manufacturer-area .manufacturer-logo,
#page-title .manufacturer-head .manufacturer-area .manufacturer-logo {
  display: block;
  margin: 15px 0 0 10px;
}
#page-title .container {
  position: relative;
}
#page-title .head1,
#page-title h1 {
  padding: 0;
  margin: 0;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 1px;
  color: #333;
  font-size: 24px;
  text-transform: uppercase;
}
#page-title span {
  display: block;
  margin-top: 10px;
  font-weight: 300;
  color: #fff;
  font-size: 18px;
}
grammarly-extension {
  display: none;
}
//TODO: MOVE TO Breadcrumbs-view.js

#mobexo_webchat_container {
  .webchat__send-box-text-box {
    margin: 0;
    padding: 5px !important;
  }
}

.table {
  cursor: pointer;
  box-shadow: none;
  border: none;
  transition: 0.3s;
  &:hover {
    box-shadow: 0 7.59435px 17.869px rgba(171, 176, 178, 0.17), 0 2.26103px 5.32008px rgba(95, 100, 102, 0.17);
  }
}

.table_row_container_disable {
  position: relative;
  cursor: not-allowed !important;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0.7;
    background-color: #fff;
    cursor: not-allowed !important;
  }

  &:hover {
    box-shadow: none !important;
  }
}

.pswp__img {
  object-fit: contain;
  padding: 30px;
}

.pswp__button--arrow {
  margin: -50px 70px 0;
}
