.leasing_request_component {
  padding: 0 30px 30px;
}

.leasing_value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .label {
    max-width: 50%;
    font-size: 14px;
    color: #5F6466;
  }
  .value {
    font-size: 14px;
    color: #000000;
  }
}

.leasing_input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .label {
    max-width: 50%;
    font-size: 14px;
  }
}

.input {
  height: 100%;
  padding: 8px 4px;
  width: 100%;
  font-weight: 400;
  font-size: 14px !important;
  text-align: right;
  border-top: none;
  border-left: none;
  border-bottom: none;
  border-color: #777777;
  border-right: none;
}

.text_input {
  width: 100% !important;
  max-width: 100% !important;
}

.input_block {
  text-align: right;
  height: 40px;
  border: 0.5px solid #777777;
  border-radius: 1px 0px 0px 1px;
  width: 43%;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  .input_suffix_container {
    display: flex;
    justify-content: flex-end;
  }
}

.currency_block {
  padding: 10px 8px;
  color: #777777;
  border-left: 1px solid #777;
  max-width: 40px;
  min-width: 40px;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  justify-content: center;
}
.currency_block_prefix {
  border-left: none;
}

.additional_data_panel {
  font-size: 12px;
  .header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px 0;
    .title_container {
      display: flex;
      align-items: center;
      span {
        color: #335566;
      }
    }
  }
  .body {
    .tags {
      display: flex;
      flex-wrap: wrap;
      .tag {
        margin: 0 5px 5px 0;
        padding: 3px 5px;
        border-radius: 2px;
        color: #8FAFBF;
        cursor: pointer;
      }
      .tag_active {
        color: #335566;
        background-color: #ACD2E6;
      }
    }
    .textarea_container {
      position: relative;
      textarea {
        font-size: 14px !important;
        padding: 5px 30px 5px 5px;
      }
      .clear_btn {
        height: 17px;
        position: absolute;
        right: 5px;
        top: 5px;
      }
    }
  }
}

.additional_data_panel_expanded {
  padding: 0 10px 10px;
  border-radius: 3px;
  background-color: #F0F1F2;
  .header {
    .title_container {
      span {
        color: #5F6466;
      }
    }
  }
}

.leasing_and_services {
  .label, .value {
    font-weight: bold;
    color: #335566;
  }
}
.offer_number {
  .label {
    font-weight: bold;
    color: #335566;
  }
}

.note {
  font-size: 10px;
  line-height: 18px;
  color: #777C7F;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.error {
  border: 1px solid #ff4700 !important;
  box-shadow: 0 0 3px 0 #ff4700;
}

.make_offer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 10px;
    color: #777C7F;
    cursor: pointer;
    text-decoration: underline;
    width: 40%;
  }
  .no_underline {
    text-decoration: none;
    cursor: auto;
  }
}
.no_borders {
  border: none;
  span {
    border: none;
  }
}
.clear_btn {
  width: 17px;
  cursor: pointer;
}
.comment_container {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #5F6466;

  .comment {
    margin-top: 10px;
    padding: 10px;
    border-radius: 3px;
    background-color: #F0F1F2;
    min-height: 100px;
  }
}
.dont_send_confirmed_message{
  display: flex;
  align-items: center;
  width: 200px;
  height: 46px;
  background: #BF0040;
  border-radius: 23px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  padding: 3px 10px;
  span{
    margin-left: 10px;
  }
}

.insurance_dialog_form {
  margin: 0;
  * {
    border: none;
  }
  input {
    border: none !important;
  }
}
.disabled {
  background-color: #F0F1F2;
}

