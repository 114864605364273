.panel {
  background-color: #fff;
  padding-top: 60px;
  padding-bottom: 66px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  .title {
    font-size: 20px;
    color: #102f3f;
    font-weight: bold;
    margin: 40px 0 10px;
  }
  .description {
    font-size: 14px;
    color: #102f3f;
    font-weight: bold;
    margin-bottom: 20px;
    max-width: 500px;
    &.descriptionBg {
      background-color: #e5f7ff;
      padding: 15px;
    }
  }
}
