.page_not_found {
  display: flex;
  background-color: #f5f5f5;
  padding: 30px 0;
  height: auto;
  min-height: 100%;

  .wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    .container {
      max-width: 400px;
      h5 {
        font-size: 18px;
        color: #000000;
        margin: 0;
      }
      h1 {
        font-size: 36px;
        color: #000000;
      }
      p {
        font-size: 14px;
        color: #000000;
        margin-bottom: 30px;
      }
    }
  }
}
