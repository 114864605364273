.container {
  display: flex;
  position: relative;
  flex-direction: column;
}

.img_container {
  margin-top: 22px;
  align-self: center;
  min-height: 425px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back_item {
  position: absolute;
  display: flex;
  align-items: center;
  margin-top: 130%;
  svg {
    width: 20px;
  }
}

@media (max-width: 350px) {
  .img_container {
    width: 100%;
    height: 100%;
  }
}
