li {
  margin-bottom: 10px;
}

.container {
  background: #fff;
  //box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
  margin-bottom: 30px;
}

.content_wrapper {
  padding: 30px;
}
.dealer_step {
  padding: 0 30px 30px;
}

.header {
  display: flex;
  align-items: center;
  padding: 30px;
  background: #335566;
  color: #fff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.02em;
}

.label_blue {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #335566;
}

.label_grey {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5f6466;
}

.ellipsis {
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input {
  height: 38px;
  width: 127px;
  padding: 8px;
  font-weight: 400;
  text-align: right;
  border-top: none;
  border-left: none;
  border-bottom: none;
  border-color: #777777;
  border-right: 0.5px solid #777777;
}

/*.error .input{
    height: 37px;
}*/
.input_block {
  text-align: right;
  height: 40px;
  border: 0.5px solid #777777;
  border-radius: 1px 0px 0px 1px;
}

.currency_block {
  width: 38px;
  padding: 8px;
  color: #777777;
  border-color: #777777;
}

.button_block {
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.firm_block {
  display: flex;
  flex-direction: column;
  justify-content: right;
  text-align: right;
  color: #777777;
  font-size: 14px;
}

.row {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.row_flex_start {
  justify-content: flex-start;

  .percent_value {
    width: 20%;
    text-align: end;
  }
  .money_value {
    width: 40%;
    text-align: end;
  }
}

.account_row {
  align-items: start;
}

.button_blue {
  border: none;
  margin-left: 10px;
  padding: 6px 16px;
  height: 32px;
  line-height: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  background: #335566;
  border-radius: 2px;
}

.button {
  border: 2px solid #335566;
  padding: 6px 10px;
  height: 32px;
  line-height: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #335566;
  border-radius: 2px;
  background: #fff;
}

.button:hover {
  box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
}

.button_blue:hover {
  box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
}

.error {
  border: 1px solid #ff4700 !important;
  box-shadow: 0 0 3px 0 #ff4700;
}

.show_onetime_costs_block {
  font-size: 14px;
  display: flex;
  justify-content: start;
  cursor: pointer;
  margin-bottom: 20px;

  span {
    margin-left: 10px;
  }
}

.dont_send_offer_block {
  font-size: 12px;
  color: #777c7f;
  cursor: pointer;
  text-decoration: underline;
  width: 50%;
}
.dont_send_offer_blockGRV {
  margin-top: 15px;
  font-size: 14px;
  color: #335566;
}

.dont_send_offer_block_clicked {
  cursor: text;
  font-size: 12px;
  color: #777c7f;
}

.dont_send_confirmed_message {
  display: flex;
  align-items: center;
  width: 240px;
  height: 26px;
  background: #bf0040;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  padding: 3px 10px;
  span {
    margin-left: 10px;
  }
}

.step {
  border-top: 1px solid #c6cacc;
  .step_header {
    padding: 15px 30px;
    min-height: 70px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    .title_container {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      h4 {
        color: #5f6466;
        font-size: 16px;
        margin: 0;
      }
      p {
        color: #5f6466;
        font-size: 14px;
      }
    }
    .badge {
      line-height: 30px;
      font-size: 14px;
      padding: 0 10px;
      border-radius: 15px;
      color: #fff;
      background-color: #ff9700;
    }
  }

  .active {
    .title_container {
      h4 {
        color: #335566;
      }
    }
  }
}
.divider {
  border-top: 1px solid #c6cacc;
}
.leasing_and_services {
  .label {
    font-weight: bold;
    color: #335566;
  }
}
.result_value {
  color: #000000;
  font-size: 14px;
}
.textarea_container {
  position: relative;
  margin: 10px 0 30px;
  textarea {
    resize: vertical;
    font-size: 14px !important;
    padding: 5px 30px 5px 5px;
  }
  .clear_btn {
    width: 17px;
    height: 17px;
    position: absolute;
    right: 5px;
    top: 5px;
  }
}

.hr_widget {
  opacity: 80;
}

.files_block {
  padding: 0 30px 10px;
}

.discount_percent {
  width: 100px !important;
  min-width: 80px;
  margin-left: 5px;
}
