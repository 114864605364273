$header-height: 65px;

.container {
  width: 90%;
  margin: 0 auto;
  overflow: auto;
}

.items_container {
  min-height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page {
  height: 100vh;
  background-color: #f5f5f5;
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  background-color: #002633;
  height: $header-height;

  .logo_container {
    display: flex;
    align-items: center;

    .logo_text {
      color: #fff;
      font-size: 19px;
      text-transform: uppercase;
      letter-spacing: 0.7px;
      margin-left: 15px;

      span {
        font-weight: 400;
      }
    }
  }
}

.content {
  position: relative;
  background-color: #f5f5f5;
  height: calc(100vh - #{$header-height});
  padding-top: 45px;
  padding-bottom: 40px;
  overflow: auto;
}

@media (max-width: 450px) {
  .content {
    padding-top: 16px;
  }
}

@media (max-width: 900px) {
  .page {
    height: 100vh;
  }
}
