.container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 98;
  box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
  width: 400px;
  height: 90%;
  display: block;
  background-color: #fff;
  transform: translateX(430px);
  transition: transform 0.3s ease-out;
  overflow: auto;
  border-radius: 8px;
  margin: 0 30px 20px 0;

  @media screen and (max-width: 556px) {
    width: calc(100% - 30px);
    transform: translateX(calc(100% + 30px));
    margin: 0 15px 10px 0;
  }

  .title_container {
    background: #102f3f;
    display: flex;
    flex-direction: column;
    .header {
      display: flex;
      justify-content: space-between;
      padding: 15px 15px 0 30px;

      .image_container {
        margin-top: 25px;
      }

      .close_button_wrapper {
        cursor: pointer;
      }
    }

    .help_description_container {
      color: #fff;
      padding: 30px 30px 80px 30px;

      .greeting {
        font-size: 22px;
      }

      .description {
        font-size: 14px;
        margin-top: 15px;
      }
    }
  }

  .content_container {
    position: relative;

    .card {
      border-radius: 10px;
      border: 1px solid #eee;
      padding: 10px 25px;
      max-width: 320px;
      margin: 0 auto;
      background: #fff;
      margin-bottom: 20px;
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 7.59435px 17.869px rgba(171, 176, 178, 0.17), 0px 2.26103px 5.32008px rgba(95, 100, 102, 0.17);

      @media screen and (max-width: 556px) {
        width: calc(100% - 30px);
      }

      .card_description {
        color: #102f3f;
        text-align: left;
      }

      .image_container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 10px;
        img {
          border-radius: 50%;
          width: 40px;
          height: 40px;
        }
        p {
          margin-left: 10px;
          color: #5f6466;
          font-size: 14px;
        }
      }

      .button_container {
        display: flex;
        justify-content: center;
        .action_button {
          background: #335566;
          border-radius: 2px;
          outline: none;
          border: none;
          color: #fff;
          width: 200px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 15px;

          .arrow_icon {
            margin-left: 5px;
            font-size: 10px;
            margin-top: 2px;
          }
        }
      }
    }
  }
}

.open {
  transform: translateX(0);
}

.content_container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 50px;
  background: #102f3f;
  z-index: 0;
}
