.padding_lg {
  @media (min-width: 1200px) {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

.wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
