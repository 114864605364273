.select_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 10px 0;
}

.option_set_container {
  max-width: 175px;
}
