.links_container {
  background-color: #f5f5f5;

  .links {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    a,
    div {
      font-size: 12px;
      line-height: 36px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #919699;
      cursor: pointer;
    }
    img {
      margin: 0 10px;
    }
  }
}
