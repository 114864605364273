.filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.location_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  cursor: pointer;

  .location_name {
    font-size: 14px;
    color: #5f6466;
  }

  .location_info {
    font-size: 10px;
    color: #777c7f;
  }
}
