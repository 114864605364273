.thumbnails_image {
  width: 75px !important;
  min-width: 75px !important;
  max-width: 75px !important;
  height: 75px !important;
}

.thumbnails_image_container {
  width: 300px !important;
}

.gallery_item {
  width: 95% !important;
}
