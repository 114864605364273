.filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;

  @media screen and (max-width: 550px) {
    width: 100%;
    margin-bottom: 10px;
  }
}

.remove_for_mobile {
  @media screen and (max-width: 550px) {
    display: none;
  }
}

.manufacturer_filter {
  @media screen and (max-width: 550px) {
    order: 3;
  }
}

.status_filter {
  @media screen and (max-width: 550px) {
    order: 2;
  }
}
