.enter {
  opacity: 0;
  transition: all 400ms ease-in-out;
}
.enterActive {
  transition: all 400ms ease-in-out;
  animation-duration: 400ms;
  animation-name: slidein-backward;
}
.exit {
  opacity: 1;
  transition: all 400ms linear;
}
.exitActive {
  opacity: 0;
  transition: all 400ms linear;
  animation-duration: 400ms;
  animation-name: slideout-backward;
}

@keyframes slidein-backward {
  from {
    opacity: 0;
    height: 100%;
    width: 100%;
    max-width: 440px;
    margin: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    height: 100%;
    width: 100%;
    max-width: 440px;
    margin: 0;
    transform: translateY(0px);
  }
}

@keyframes slideout-backward {
  from {
    opacity: 1;
    margin: 0%;
    height: 100%;
    width: 100%;
    max-width: 440px;
  }

  to {
    opacity: 0;
    z-index: 1231;
    margin-top: -100%;
    height: 0px;
    width: 100%;
    max-width: 440px;
  }
}
