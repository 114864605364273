.container {
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media screen and (max-width: 1600px) {
    flex-direction: column;
  }

  .widget_container {
    flex: 1;
    gap: 20px;

    @media screen and (max-width: 1600px) {
      display: flex;
    }
  }
}
