@mixin font {
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-align: left;
}

.filter_container {
  margin-bottom: 10px;
  .filter_input_wrapper {
    display: flex;
    align-items: center;
    position: relative;

    input {
      border: 1px solid #335566;
      height: 32px;
      border-right: none;
      border-radius: 3px 0 0 3px;
    }

    .input_without_search {
      border: 0.5px solid #5f6466;
      border-radius: 0;
      height: 40px;
      padding-left: 38px;
      width: 100%;

      &::placeholder {
        font-size: 14px;
        color: #5f6466;
      }
    }

    .search_icon {
      position: absolute;
      left: 12px;
      top: calc(50% - 10px);
      border-radius: 50%;
      display: block;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #335566;
      border: none;
      border-radius: 0 3px 3px 0;
      height: 32px;
      width: 32px;
    }
  }
}

.filter_button {
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 3px 10px 3px 10px;
  color: #335566;
  background: #ffffff;
  cursor: pointer;
  white-space: nowrap;

  border: 1px solid #335566;
  box-sizing: border-box;
  border-radius: 20px;
  img {
    margin-left: 10px;
    display: inline;
  }
  &.active {
    background-color: #335566;
    color: #ffffff;
  }
}

.item {
  background: #ffffff;
  border: 1px solid #335566;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 6px 15px 6px 20px;
}

.form_input {
  @include font;
  width: 100%;
  padding: 10px 10px 10px 30px;
  border: 1px solid #777;
  color: #000;
  font-size: 14px !important;
  border-radius: 3px;
  &::placeholder {
    color: #777;
  }
  &::-moz-placeholder {
    color: #000;
    opacity: 1;
  }
  &::-ms-input-placeholder {
    color: #000;
  }
}

.digital_input {
  padding: 10px;
}

.field_prefix {
  padding: 8px 11px 7px 11px;
  position: relative;
  background-color: #e0e3e5;
  border: 1px solid #5f6466;
  border-right: none;
}

.input_container {
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid #777c7f;
  border-radius: 2px;
}

.input_container_number_picker {
  border: none;
}

.drop_list_container {
  display: flex;
  flex-direction: column;

  .drop_list_item {
    display: flex;
    min-height: 30px;
    align-items: center;
    padding-left: 5px;
    font-size: 14px;
    color: #5f6466;
    max-width: 600px;
    word-break: break-word;

    &:hover {
      cursor: pointer;
      background-color: #e5f7ff;
    }
  }

  .drop_list_wrapper {
    &:hover {
      cursor: pointer;
      background-color: #e5f7ff;
    }
  }
}

@mixin font {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.02em;
}

.filter {
  @include font;
  position: relative;
  display: flex;
  align-items: center;

  &.active {
    background-color: #335566;
    color: #ffffff;
  }

  margin-right: 10px;
}

.toggle_container {
  display: flex;
  padding: 10px 0 5px 0;
  align-items: center;

  .text {
    margin: 0;
    cursor: pointer;
    font-size: 14px;
    margin-right: 15px;
    color: #335566;
    font-weight: normal;
  }

  .toggle_button {
    position: relative;
    width: 35px;
    height: 18px;
    margin: 0;
    vertical-align: top;
    background: #ffffff;
    border: 1px solid #777777;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }

  .toggle_button::after {
    content: '';
    position: absolute;
    left: 3px;
    top: 1px;
    width: 14px;
    height: 14px;
    background-color: #335566;
    border-radius: 50%;
    transform: translateX(0);
    transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }

  .toggle_button:checked::after {
    transform: translateX(100%);
    background-color: #fff;
  }

  .toggle_button:checked {
    background-color: #335566;
  }
}

.save_button {
  margin-left: auto;
  margin-top: 10px;
}
