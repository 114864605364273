$main-style-color: #335566;
$main-font-color: #102f3f;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

.wrapper {
  display: flex;
  justify-content: center;

  .stepper_container {
    box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
    width: 70%;
    padding: 20px;
    background: #fff;

    .steps_container {
      display: flex;
      align-items: center;

      .step_button {
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        min-height: 24px;
        padding: 0;

        .active_step {
          color: $main-font-color;
          font-weight: bold;
          font-size: 14px;

          @media only screen and (max-width: $lg) {
            font-size: 12px;
          }

          @media only screen and (max-width: $sm) {
            font-size: 10px;
          }
        }

        .step {
          color: $main-style-color;
          font-size: 14px;

          @media only screen and (max-width: $lg) {
            font-size: 12px;
          }

          @media only screen and (max-width: $sm) {
            font-size: 10px;
          }
        }

        .completed_step {
          color: #30bf30;
        }

        .completed_step_logo {
          display: flex;
          align-items: center;
          margin-right: 10px;
        }

        @media only screen and (max-width: $sm) {
          padding: 0;
        }
      }

    }

    .form {
      display: flex;
      flex-direction: column;

      .content_container {
        padding: 20px;

        .title {
          font-weight: bolder;
          color: $main-style-color;
          font-size: 16px;
        }

        .from_wrapper {
          display: flex;
          justify-content: center;

          .form_container {
            width: 70%;

            .title_container {
              padding: 20px 0;

              .form_title {
                color: $main-style-color;
                font-weight: bold;
              }
            }

            .field_lable {
              color: $main-font-color;

              .required_field {
                color: #bf0040;
              }
            }

            .form_input {
              width: 100%;
              border: 1px solid #b8b3b3;
              border-radius: 4px;
              height: 40px;
              background-color: transparent;
              margin-bottom: 20px;
              margin-top: 5px;
              padding: 0 15px;
              color: #868282;
              .select_input {
                width: 100%;
                border: none;
                height: 38px;
              }
            }

            select.form_input {
              -moz-appearance: none;
              -webkit-appearance: none;
              appearance: none;
              background-image: url('/assets/images/dashboard-expand.svg');
              background-repeat: no-repeat;
              background-position-x: 5px;
              background-position-y: 50%;
              padding-left: 25px;
            }

            .input_error {
              border-color: #bf0040;
              color: #bf0040;
            }

            @media screen and (max-width: $sm) {
              width: 100%;
            }
          }
        }

        .select_car_container {
          .cars_container {
            margin: 10px 0;

            .chosen_car {
              background-color: #f0f1f2;
            }

            .card {
              box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
              border-radius: 4px;
              margin: 15px 0;
              cursor: pointer;
              display: flex;
              padding: 30px 30px 100px 30px;

              &:hover {
                background-color: #f0f1f2;
              }

              @media only screen and (max-width: $lg), screen and (max-width: $md), screen and (max-width: $sm) {
                flex-direction: column;
                padding: 5px;
              }
              .tech_description_container {
                height: 250px;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                width: 55%;

                .title_container {
                  padding: 0;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;

                  .car_title {
                    color: $main-font-color;
                    font-weight: bold;
                    font-size: 20px;

                    @media only screen and (max-width: $sm) {
                      font-size: 16px;
                    }
                  }
                }

                @media only screen and (max-width: $lg) {
                  width: 100%;
                  padding: 15px;
                }

                .car_param {
                  display: flex;
                  justify-content: space-between;

                  .car_param_title {
                    color: $main-style-color;
                    font-size: 14px;

                    @media only screen and (max-width: $lg) {
                      font-size: 16px;
                    }

                    @media only screen and (max-width: $sm) {
                      font-size: 12px;
                    }
                  }

                  .param_value {
                    width: 50%;
                    text-align: left;
                    font-weight: bold;
                    color: $main-font-color;
                    font-size: 14px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    @media only screen and (max-width: $lg) {
                      text-align: right;
                      font-size: 16px;
                    }

                    @media only screen and (max-width: $sm) {
                      font-size: 12px;
                    }
                  }
                }

                @media only screen and (max-width: $lg) {
                  width: 100%;
                  padding: 15px;
                }

                @media only screen and (min-width: $lg) {
                  width: 50%;
                }
              }
            }
          }
        }

        .booking_container {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 5px 0;

          .wrapper {
            display: flex;
            width: 70%;
            border: 1px solid #acd2e6;
            border-left: 8px solid #acd2e6;
            border-radius: 2px;
            flex-direction: column;
            padding: 25px;
            margin-bottom: 10px;

            @media only screen and (max-width: $sm) {
              width: 100%;
              padding: 10px;
            }

            .order_info_container {
              display: flex;
              flex-direction: column;

              .booking_subtitle {
                color: $main-font-color;
                margin: 15px 0 5px 0;
                font-weight: bold;

                @media only screen and (max-width: $sm) {
                  font-size: 14px;
                }
              }

              .field_lable {
                @media only screen and (max-width: $sm) {
                  font-size: 14px;
                }
              }
            }
          }
        }

        .success_container {
          display: flex;
          align-items: center;
          padding: 20px;
          flex-direction: column;
          text-align: center;

          .description_container {
            background: #e6ffe6;
            border: 1px solid #208020;
            display: flex;
            border-radius: 2px;
            margin-top: 15px;
            text-align: left;

            .description_container_item {
              display: flex;
              padding: 8px;
              flex-direction: column;
            }
          }

          .navigation_container {
            display: flex;
            justify-content: space-between;
            width: 50%;
            margin-top: 50px;

            @media only screen and (max-width: $sm), screen and (max-width: $md), screen and (max-width: $lg) {
              width: 60%;
            }

            @media only screen and (max-width: $sm) {
              width: 90%;
            }

            .navigation_button {
              @media only screen and (max-width: $sm) {
                font-size: 12px;
                height: 44px;
              }
            }
          }

          @media only screen and (max-width: $sm) {
            padding: 5px;
          }
        }
      }

      .buttons_container {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
      }

      .single_button_container {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
      }

      .back_button {
        background: #fff;
        color: $main-style-color;
        border: 1px solid $main-style-color;
        border-radius: 2px;
        width: 175px;
        height: 32px;

        @media only screen and (max-width: $sm) {
          width: 135px;
        }
      }

      .submit_button {
        background: $main-style-color;
        color: #fff;
        border: none;
        border-radius: 2px;
        width: 175px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: $sm) {
          width: 135px;
        }
      }

      .disabled_button {
        opacity: 0.6;
      }

      .arrow_icon {
        margin-left: 5px;
        font-size: 10px;
        margin-top: 2px;
      }
    }

    @media only screen and (max-width: $xl) {
      width: 100%;
      padding: 10px;
    }

    @media only screen and (max-width: $lg) {
      width: 90%;
    }
  }
}

.loader_container {
  display: flex;
  justify-content: center;
  padding: 25px 0;
  position: relative;
}

.table_wrapper {
  padding: 0 20px;
  margin: 0 auto 200px;

  .table_container {
    min-width: $xl;
    @media only screen and (max-width: $xl) {
      min-width: unset;
    }
    .new_booking_button_container {
      margin-bottom: 35px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .buttons_wrapper {
        display: flex;
        order: 1;
        @media only screen and (max-width: $xl) {
          width: 100%;
          order: 0;
          margin-bottom: 20px;
        }
      }
      .new_booking_button {
        background: $main-style-color;
        color: #fff;
        border: none;
        border-radius: 2px;
        width: 175px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .calendar_view_container {
        display: flex;
        align-items: center;
        margin-right: 40px;
        cursor: pointer;
      }

      @media only screen and (max-width: $xl) {
        flex-direction: column;
      }
    }
  }
}

.empty_state_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;

  .image_container {
    display: flex;
    justify-content: center;
  }

  .empty_state_description_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;

    .empty_state_description {
      color: $main-font-color;
      font-weight: 500;
      margin-top: 10px;
    }

    @media only screen and (max-width: $md) {
      text-align: center;
    }
  }
}

.placeholder_new_booking_button_container {
  max-width: 1170px;
  margin: 0 auto;
}

.placeholder_cutom_filters_container {
  max-width: 1170px;
  margin: 0 auto;
}

.image_container {
  display: flex;
  width: 100%;

  .image_loader_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    min-height: 60px;
  }

  .car_image {
    border: 1px solid #b8b3b3;
    border-radius: 4px;

    @media only screen and (max-width: $lg) {
      width: 100%;
    }
  }

  .car_photo {
    max-height: 90px;
  }

  @media only screen and (max-width: $lg) {
    justify-content: center;
    padding: 15px;
    width: 100%;
    margin: 0;
  }
}
