.full_container,
.container,
.container_mobile,
.full_container_mobile {
  border-radius: 2px;
  min-width: 630px;
  max-width: 977px;
  width: 630px;
  height: 350px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  border-radius: 2px;
  span,
  p {
    cursor: default;
  }
  .main_block {
    max-width: 630px;
  }
  .main_block,
  .additional_block {
    transition: transform 0.3s ease-in-out;
    padding: 20px 30px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .additional_block {
    transition: transform 0.3s ease-in-out;
    padding: 30px 20px 14px 10px;
    max-width: 337px;
    min-width: 337px;
    height: 350px;
    background-color: #fff;
    width: 337px;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;

    .additional_header {
      display: flex;
      flex-direction: row;
      margin: 0;

      h6 {
        margin: 0 0 20px 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #335566;
      }
    }
  }

  .car_name {
    display: flex;
    flex-direction: row;
    align-items: center;
    a {
      display: flex;
      flex-direction: row;
      align-items: center;

      h6 {
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        color: #335566;
        margin: 0;
      }
    }
  }

  .car_title {
    margin-top: 20px;
    margin-bottom: 10px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      width: 24px;
      height: 24px;
    }
    svg::hover {
      fill: #ffffff;
    }
  }

  .car_model {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #335566;
    margin-right: 5px;
  }

  .car_number {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #777c7f;
  }

  .block_name {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #335566;
    margin-left: 10px;
  }

  .block_status {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: auto;
  }
}
.full_container,
.full_container_mobile {
  width: 977px;
  .main_block {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    background-color: #e6f7ff;
    position: relative;
    box-shadow: 0px 2.2610347270965576px 5.32008171081543px 0px rgba(95, 100, 102, 0.17),
      0px 7.5943450927734375px 17.869047164916992px 0px rgba(171, 176, 178, 0.17);
    max-height: 352px !important;
  }
}

.full_container_mobile {
  width: 100%;
  .main_block {
    min-width: auto;
    max-width: 404px;
    width: 404px;
  }
  .additional_block {
    max-width: 100%;
  }
}

.service_block {
  display: flex;
  flex-direction: row;

  .car_img {
    margin-right: 20px;
    cursor: default;
    border-radius: 4px;
    height: 230px;
    width: 350px;
    img {
      object-fit: cover;
    }
  }
  .service_info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 26px 0;
    gap: 20px;
    height: 100%;
    .service_info_item {
      height: 45px;
      span,
      .tooltip_string {
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #335566;
      }
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #919699;
      }
    }
  }
}

.show_carinfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  span {
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #335566;
  }
}

.additional_item {
  display: flex;
  flex-direction: column;
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #171819;
    margin-bottom: 5px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #171819;
  }
  .additional_item_line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 24px;
    p {
      max-width: 85%;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #171819;
    }
    img {
      width: 24px;
      cursor: pointer;
    }
  }
  .line {
    margin: 15px 0;
    width: 100%;
    height: 0px;
    border-bottom: 1px solid #e0e3e5;
    opacity: 0.6;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
}

.expand_right,
.expand_left {
  margin-left: 10px;
  height: 14px;
  width: 14px;
}

.expand_right {
  transform: rotate(270deg);
}

.expand_left {
  transform: rotate(90deg);
}

@media (max-width: 768px) {
  .full_container,
  .container,
  .container_mobile,
  .full_container_mobile {
    width: 100%;
    flex-direction: column;
    min-width: auto;
    max-width: none;
    height: auto;
    border-radius: 2px;
    margin-top: 10px;
    .main_block,
    .additional_block {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    }
    .additional_block {
      border-radius: 2px;
      padding: 16px;
    }
    .service_block {
      display: flex;
      flex-direction: column;
      .car_img {
        max-height: 230px;
        object-fit: cover;
        margin-top: auto;
        height: auto;
        width: 100%;
        cursor: default;
      }
      .service_info {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }
  .expand_right {
    transform: rotate(0deg);
  }

  .expand_left {
    transform: rotate(180deg);
  }
  .block_status {
    margin-top: 5px;
    margin-bottom: 20px;
  }
  .car_name {
    margin-bottom: 10px;
    .service_info_item {
      margin-left: auto;
      span {
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #335566;
      }
    }
  }
  .show_carinfo {
    margin-left: auto;
  }
}
.additional_block {
  /* ... */
  transition: transform 0.3s ease-in-out;
}

.additional_block.show {
  transform: translateX(100%);
}

.container.showAdditionalFields .additional_block {
  transform: translateX(0%);
}

.tooltip_string {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
  max-width: 120px;
}

.tooltip_string_long {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.spinner_block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container_mobile {
  max-width: 404px;
  min-width: auto;
  width: 100%;
}

.container_mobile,
.full_container_mobile {
  .car_name {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 10px;
    .service_info_item {
      margin-left: 35px;
      span {
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #335566;
      }
    }
  }
  .block_status {
    margin-top: 5px;
    margin-bottom: 20px;
  }
}

.spinner_block {
  width: 100%;
  border-radius: 2px;
  min-height: 200px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 550px) {
  .full_container,
  .container,
  .container_mobile,
  .full_container_mobile {
    .block_status {
      margin-left: 0;
    }
    .car_model {
      margin-right: 0;
    }
  }
}
