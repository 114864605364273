.container {
  position: relative;
  min-width: 277px;

  .input_form {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    color: #464a4c;
    font-size: 16px;
    text-transform: uppercase;
    background-color: #fff;
    border: 2px solid #c6cacc;
    border-radius: 2px;
    padding: 14px 20px !important;
    transition: box-shadow 0.3s;

    &:hover {
      box-shadow: 0px 34px 80px rgba(51, 86, 102, 0.07), 0px 7.59435px 17.869px rgba(51, 86, 102, 0.07),
        0px 2.26103px 5.32008px rgba(51, 86, 102, 0.07);
    }

    input {
      text-transform: uppercase;
      border: none;
      padding: 0;
      margin: 0;
    }

    &:focus-within {
      border: 2px solid #acd2e6 !important;
    }

    .region {
      width: 45px;
      text-align: right;
      margin-right: 10px;
    }

    .letters {
      width: 30px;
      margin: 0 10px;
    }

    .numbers {
      width: 74px;
    }

    .icon {
      margin-left: 30px;
    }
  }

  .error_container {
    position: absolute;
    bottom: -5px;
    left: 0;
    display: flex;
    align-items: center;
    color: #bf0040;
    font-size: 14px;

    .error_message {
      margin-left: 3px;
    }
  }
}
