.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  max-width: 1090px;
  flex-wrap: wrap;
  .header_small,
  .header_small_center {
    color: #102f3f;
    font-size: 14px;
    font-weight: 400;
    width: 98%;
    display: flex;
    .header_center {
      justify-self: center;
    }
    .back_item {
      flex-grow: 0.47;
      font-size: 14px;
      font-weight: 400;
      color: #5f6466;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    .back_text {
      margin-left: 5px;
    }
  }
  .header_small_center {
    justify-content: center;
  }
  .damage_row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  input {
    &:focus::-webkit-input-placeholder {
      color: transparent;
    }
  }
}

.damage_part,
.damage_part_choosen {
  display: flex;
  flex-direction: column;
  height: 150px;
  width: 250px;
  border: 1px solid #acd2e5;
  border-radius: 10px;
  margin: 0 10px 30px;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  p {
    margin-top: auto;
    color: #335566;
    font-size: 18px;
    font-weight: 600;
  }
  &:hover {
    box-shadow: 2px 4px 5px 0px rgba(172, 210, 229, 0.3);
  }
}

.damage_part_choosen {
  background-color: #e5f7ff;
  border: none;
}

.more_options {
  display: flex;
  flex-direction: row;
  height: 150px;
  width: 250px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #5f6466;
  margin: 0 10px 30px;
  cursor: pointer;
  p {
    margin-right: 5px;
  }
}

@media (max-width: 900px) {
  .container {
    margin-top: 35px;
  }
  .header_small,
  .header_small_center {
    display: flex;
    flex-direction: column;
    .back_item {
      margin-bottom: 15px;
    }
    .header_center {
      text-align: center;
    }
  }
  .damage_part,
  .damage_part_choosen {
    width: 43%;
    max-height: 140px;
    margin-bottom: 20px;
    p {
      font-size: 14px;
    }
  }
  .more_options {
    width: 43%;
    padding: 20px;
    p {
      text-align: center;
    }
  }
  .damage_row {
    justify-content: center;
  }
}

@media (max-width: 300px) {
  .damage_part,
  .damage_part_choosen {
    width: 90%;
  }
  .more_options {
    width: 90%;
    height: 50px;
  }
}
