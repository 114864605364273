.container {
  display: flex;
  justify-content: center;
  background-color: #fff;
  flex: 2;
  height: fit-content;
  padding: 50px;

  .content {
    color: #000;
    width: 700px;

    .header {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 40px;
    }

    .benefit_container {
      width: fit-content;
      margin: 15px auto 40px;

      .benefit_item_container {
        display: flex;
        align-items: center;
        margin-top: 12px;
        span {
          margin-left: 10px;
        }
      }
    }

    .link {
      color: #335566;
      font-weight: 600;
      text-decoration: underline;
    }
  }
}
